import React from "react";
import styled from "styled-components";
import muiTheme from "../../config/Theme";

interface TealInfoBoxProps {
  text: string;
  fontSize?: number;
  fontWeight?: string;
  lineHeight?: number;
  textAlign?: string;
}

const StyledTealInfoBoxSpan = styled.span<
  Omit<TealInfoBoxProps, "text"> & React.HTMLAttributes<HTMLSpanElement>
>`
  padding: 4px 8px;
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize}px;
  line-height: ${(props) => props.lineHeight}px;
  text-align: ${(props) => props.textAlign};
  background-color: ${muiTheme.palette.willfulLightTeal.main};
`;

export const TealInfoBox = ({
  text,
  fontSize = 14,
  fontWeight = "700",
  lineHeight = 21,
  textAlign = "center",
}: TealInfoBoxProps) => {
  return (
    <StyledTealInfoBoxSpan
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      textAlign={textAlign}
    >
      {text}
    </StyledTealInfoBoxSpan>
  );
};
