import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";

import { useSelector } from "react-redux";
import { WILLFUL_BILL_21_URL } from "../../../constants/routes";
import { H1, H3, Body, SmallExternalLink } from "../../ui/Typography";
import PaymentSummary from "../PaymentSummary/PaymentSummary";
import Bill21Disclaimer from "../../documents/Bill21Disclaimer";
import PlanSummary from "../../plans/PlanSummary";
import DigitalExecutionWrapper from "../../addons/digital-execution/DigitalExecutionWrapper";
import { isAddonReceipt } from "../../../selectors/receipt";
import ReceiptInfoLeftGridContent from "./ReceiptInfoLeftGridContent";

const ReceiptInfo = ({
  translations,
  isCompleteAllSections,
  bundlePlanSelected,
  referralProgramProps,
  documentsTranslations,
  isUserFromBC,
  isNotarialWill,
  analyticsClickExternalLink,
  analyticsDownloadNotarizationGuide,
  hasPlan,
  planSummaryProps,
  ...paymentSummary
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isProductAddon = useSelector(isAddonReceipt);
  const pageWarningContent = isNotarialWill ? (
    documentsTranslations.notarialPageWarning
  ) : (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: documentsTranslations.legalDocumentsWarning,
        }}
      />
      {isUserFromBC && (
        <>
          {" "}
          <SmallExternalLink
            href={WILLFUL_BILL_21_URL}
            text={documentsTranslations.button.learnMoreExternal}
            onClick={() => analyticsClickExternalLink(WILLFUL_BILL_21_URL)}
          />
        </>
      )}
    </>
  );

  return (
    <Box pb={6}>
      <Grid container justify="center">
        <Grid item sm={12} md={12} lg={12}>
          <Box mt={isDesktop ? 7.5 : 2.5} mb={isDesktop ? 2.5 : 0}>
            <H1 align="left">{translations.pageTitle}</H1>
          </Box>
        </Grid>
      </Grid>
      <Grid container justifyContent={!isDesktop && "center"} spacing={2}>
        {/* Left hand column */}
        <Grid item sm={12} md={12} lg={7}>
          <Box mt={isDesktop ? 2 : 0}>
            <H3 align="left">{translations.yourPlanCopy}</H3>
            <Body>
              {isProductAddon
                ? translations.thankYouAddon
                : translations.thankYou}
            </Body>
          </Box>
          <Grid>
            {!isProductAddon ? (
              <ReceiptInfoLeftGridContent
                isCompleteAllSections={isCompleteAllSections}
                translations={translations}
                isNotarialWill={isNotarialWill}
                isDesktop={isDesktop}
                pageWarningContent={pageWarningContent}
                bundlePlanSelected={bundlePlanSelected}
              />
            ) : (
              <DigitalExecutionWrapper />
            )}
          </Grid>
        </Grid>

        {/* Left hand column close */}
        {/* Right hand column */}
        <Grid item justify="flex-end" sm={12} md={12} lg={5}>
          <Box mt={isDesktop ? 2 : 0}>
            {!isProductAddon && hasPlan && (
              <PlanSummary
                {...planSummaryProps}
                analyticsClickExternalLink={analyticsClickExternalLink}
                analyticsDownloadNotarizationGuide={
                  analyticsDownloadNotarizationGuide
                }
                showPlanStatus={false}
                showCta={false}
              />
            )}
            <Box p={2} bgcolor="gray.main" borderRadius={4}>
              <PaymentSummary
                isReceiptPage
                translations={translations}
                planSummary={planSummaryProps}
                {...paymentSummary}
              />
            </Box>
          </Box>
        </Grid>
        {/* Right hand column close */}
        {isUserFromBC && (
          <Grid item>
            <Bill21Disclaimer
              onExternalLinkClick={analyticsClickExternalLink}
              translations={documentsTranslations}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default ReceiptInfo;
