import R from "ramda";
import { createSelector, createStructuredSelector } from "reselect";

import { selectTranslations } from "./translations";
import { MODAL_REFER_A_FRIEND } from "../constants/modal";
import { formatCurrency, formatPercentage } from "../utilities/helpers";
import { selectLanguageCode } from "./language";
import { REFERRAL_DISCOUNT_TYPE_PERCENTAGE } from "../constants/growsurf";

export const selectReferralDiscountAmount = () => {
  return window.env?.GROWSURF_DISCOUNT || 20.0;
};

export const selectReferralDiscountType = () => {
  return (
    window.env?.GROWSURF_DISCOUNT_TYPE || REFERRAL_DISCOUNT_TYPE_PERCENTAGE
  );
};

export const selectReferralGiftCardAmount = () => {
  return window.env?.GROWSURF_GIFT_CARD_AMOUNT || 30.0;
};

export const selectTranslatedReferralDiscountAmount = createSelector(
  selectReferralDiscountAmount,
  selectReferralDiscountType,
  selectLanguageCode,
  (discountAmount, discountType, language) => {
    return discountType === REFERRAL_DISCOUNT_TYPE_PERCENTAGE
      ? formatPercentage(discountAmount / 100, 0, language)
      : formatCurrency(discountAmount, language, null);
  },
);

export const selectTranslatedReferralGiftCardAmount = createSelector(
  selectReferralGiftCardAmount,
  selectLanguageCode,
  (giftCardAmount, language) => {
    return formatCurrency(giftCardAmount, language, null);
  },
);

export const selectReferralProgramModalTranslations = createSelector(
  selectTranslations(["global", "notice"]),
  selectTranslatedReferralDiscountAmount,
  selectTranslatedReferralGiftCardAmount,
  (translations, discountAmountTranslation, giftCardAmountTranslation) => {
    const modalTranslations = translations[MODAL_REFER_A_FRIEND];

    const newBody = modalTranslations.body
      .replace("{DISCOUNT}", discountAmountTranslation)
      .replace("{GIFT_CARD_AMOUNT}", giftCardAmountTranslation);

    return {
      ...modalTranslations,
      body: newBody,
    };
  },
);

export const selectReferralProgramBoxTranslations = createSelector(
  selectTranslations(["global", "referralProgram"]),
  selectTranslatedReferralDiscountAmount,
  (translations, discountAmountTranslation) => {
    const newBody = translations.body.replace(
      "{DISCOUNT}",
      discountAmountTranslation,
    );

    return {
      ...translations,
      body: newBody,
    };
  },
);

export const referralProgramProps = createStructuredSelector({
  translations: selectReferralProgramBoxTranslations,
  email: R.path(["auth", "uid"]),
});
