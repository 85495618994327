import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ESSENTIALS_PLAN_GUIDANCE_PATH,
  FAMILY_PLAN_GUIDANCE_PATH,
  PREMIUM_PLAN_GUIDANCE_PATH,
} from "../../../constants/routes";
import { getFormData } from "../../../actions/forms";
import { PEOPLE_QUANTITY_INTENT_FIELD_ID } from "../../../constants/forms";
import { PageWrapper } from "../../Layout";
import {
  Box,
  CircularProgress,
  Grid,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  PreviousLink,
  PrimaryButton,
  TextButton,
} from "../../../components/ui/Button";
import { selectTranslations } from "../../../selectors/translations";

import { Body, H2, SmallBody } from "../../../components/ui/Typography";
import UmbrellaIcon from "../../../components/ui/icons/UmbrellaIcon";
import CoupleOnLaptop from "../../../components/ui/icons/CoupleOnLaptops";
import { selectSpouse, selectHasSpouse } from "../../../selectors/spouse";
import { fetchPlans, updatePlans } from "../../../actions/plans";
import { ESSENTIALS_PLAN, PREMIUM_PLAN } from "../../../constants/plans";
import { selectIntendedUsePreviousPath } from "../../../selectors/about-you";
import { history } from "../../../config/configureStore";
import { TealInfoBox } from "../../../components/ui/TealInfoBox";
import { colorPalette } from "../../../config/Theme";
import BodyWithUnderlineLink from "../../../components/ui/BodyWithUnderlineLink";
import { displayTooltipModal } from "../../../actions/tooltip-modal";

const IntendedUsePageReposition = () => {
  const theme = useTheme();
  const spouseExist: boolean = useSelector(selectHasSpouse());
  const [hasSpouse, setHasSpouse] = useState(spouseExist);
  const spouseInfo: YourFamilyReduxType["spouse"] = useSelector(selectSpouse());
  const previousLink = useSelector(selectIntendedUsePreviousPath);
  const dispatch = useDispatch();
  const formId = "products";

  useEffect(() => {
    setHasSpouse(spouseExist);
    dispatch(getFormData(PEOPLE_QUANTITY_INTENT_FIELD_ID));
    dispatch(fetchPlans());
  }, [dispatch, getFormData, spouseExist]);

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const translations: TranslationsType["intendedUse"] = useSelector(
    selectTranslations([hasSpouse ? "intendedUseMarried" : "intendedUse"]),
  );

  const pageTitle = translations.pageTitle?.replace(
    "{SPOUSE}",
    spouseInfo.firstName || "",
  );

  const cardText = translations.card?.text?.replace(
    "{SPOUSE}",
    spouseInfo.firstName || "",
  );

  const handleClick = useCallback(() => {
    if (hasSpouse) {
      //BE: add premium*2 to cart
      dispatch(
        updatePlans({
          symbol: PREMIUM_PLAN,
          quantity: 2,
          formId,
        }),
      );
      history.push(FAMILY_PLAN_GUIDANCE_PATH);
    } else {
      //BE: add premium to cart
      dispatch(
        updatePlans({
          symbol: PREMIUM_PLAN,
          quantity: 1,
          formId,
        }),
      );
      history.push(PREMIUM_PLAN_GUIDANCE_PATH);
    }
  }, [dispatch, hasSpouse, history]);

  const handleSkipClick = useCallback(() => {
    if (hasSpouse) {
      setHasSpouse(false);
    } else {
      //BE: add essentials to cart
      dispatch(
        updatePlans({
          symbol: ESSENTIALS_PLAN,
          quantity: 1,
          formId,
        }),
      );
      history.push(ESSENTIALS_PLAN_GUIDANCE_PATH);
    }
  }, [dispatch, hasSpouse, history]);

  const handlePreviousClick = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault();
      if (hasSpouse !== spouseExist) setHasSpouse(spouseExist);
      else history.push(previousLink);
    },
    [spouseExist, hasSpouse, history],
  );

  return (
    <PageWrapper flexGrow={1}>
      <Box mt={isDesktop ? 4.5 : 1.5} width={"100%"}>
        <PreviousLink to="" displayArrowLeft onClick={handlePreviousClick} />
      </Box>

      <Box mt={1} mb={1}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          {typeof hasSpouse === "undefined" ? (
            <CircularProgress />
          ) : (
            <>
              <Box mb={1}>
                <H2 align="center">{pageTitle}</H2>
              </Box>
              <Body align="center">{translations.body}</Body>
              {/* main card start */}

              <Box
                p={1.5}
                width="100%"
                maxWidth="592px"
                mt={isDesktop ? 3.5 : 0.5}
                bgcolor={"white"}
                borderRadius={8}
                border={1}
                borderColor="border.main"
              >
                <Box
                  mb={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <TealInfoBox text={translations.card.infoText} />
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  mb={2}
                >
                  {hasSpouse ? <CoupleOnLaptop /> : <UmbrellaIcon />}
                </Box>
                <PrimaryButton
                  text={translations.card.cta}
                  fullWidth
                  onClick={handleClick}
                  id={"submit-button-" + PREMIUM_PLAN}
                />
                <SmallBody margin="24px 0px" align="center">
                  {hasSpouse ? (
                    cardText
                  ) : (
                    <BodyWithUnderlineLink
                      text={cardText}
                      underlineText={translations.card.underlineText}
                      onClick={() =>
                        dispatch(
                          displayTooltipModal(translations.card.modalKey),
                        )
                      }
                    />
                  )}
                </SmallBody>
              </Box>

              {/* main card end */}

              <TextButton
                text={translations.cta}
                displayArrowRight
                color={colorPalette.willfulBlue}
                margin="24px"
                onClick={handleSkipClick}
                id={hasSpouse ? "skip-btn" : "submit-button-" + ESSENTIALS_PLAN}
              />
            </>
          )}
        </Grid>
      </Box>
    </PageWrapper>
  );
};

export default IntendedUsePageReposition;
