import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import styled from "styled-components";
import {
  Body,
  H2,
  LargeBody,
  SectionTitle,
  SmallBody,
  TextLink,
} from "../../components/ui/Typography";
import { PageLayoutWithPrevious } from "../../components/ui/PageLayout";
import WhiteCard from "../../components/checkout/receipt/WhiteCard";
import { ButtonLink } from "../../components/ui/Button";
import { useStickyBottomBoxStyle } from "../../components/ui/utils/sticky-bottom-box";
import { selectTranslations } from "../../selectors/translations";
import { analyticsTriggeredWilla } from "../../actions/analytics";
import { INTENDED_USE_PATH } from "../../constants/routes";

const StyledEmojiPointList = styled.ul`
  list-style-type: none;
  padding-left: 32px;

  li {
    position: relative;
  }

  li::before {
    content: attr(data-emoji);
    display: block;
    font-size: 20px;
    position: absolute;
    left: -32px;
    top: 4px;
  }
`;

const ComparisonCard = ({
  translations,
  marginRight = 0,
  marginLeft = 0,
  marginTop = 0,
}: {
  translations: any;
  marginRight?: boolean | number;
  marginLeft?: boolean | number;
  marginTop?: boolean | number;
}) => {
  const theme: any = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <WhiteCard
      mt={marginTop}
      ml={marginLeft}
      mr={marginRight && isDesktop && 0.5}
      includeBottomMargin={false}
      fullHeight
      displayFlex={isDesktop}
      spaceVerticalContentEvenly={isDesktop}
    >
      <Box>
        <Box mb={1}>
          <H2 align="left">{translations.title}</H2>
        </Box>
        <StyledEmojiPointList>
          {translations.points.map((point: any) => {
            return (
              <li data-emoji={point.emoji}>
                <Body>{point.text}</Body>
              </li>
            );
          })}
        </StyledEmojiPointList>
      </Box>
      <Box mt={isDesktop && 2}>
        <Box>
          <SmallBody>{translations.bylineHeading}</SmallBody>
          <LargeBody as="span">
            <strong>{translations.bylineEmphasis}</strong>
          </LargeBody>
          <SmallBody inline as="span">
            {translations.bylineEmphasisTail}
          </SmallBody>
        </Box>
        <SmallBody>{translations.bylineSignOff}</SmallBody>
      </Box>
    </WhiteCard>
  );
};

const VsLawyerPage = () => {
  const translations = useSelector(selectTranslations(["vsLawyer"]));
  const { button } = useSelector(selectTranslations(["global", "button"]));
  const willfulCardTranslations = translations.components.cards.willful;
  const lawyerCardTranslations = translations.components.cards.lawyers;
  const dispatch = useDispatch();
  const theme: any = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const stickyButtonStyle = useStickyBottomBoxStyle();

  const handleQuestionClick = () => {
    const userWindow: any = window;
    userWindow.adaEmbed.toggle();
    dispatch(analyticsTriggeredWilla());
  };

  return (
    <PageLayoutWithPrevious>
      <Box display="flex" justifyContent="center">
        <Grid lg={8} md={12}>
          <Box mb={1}>
            <SectionTitle>{translations.sectionTitle}</SectionTitle>
          </Box>
          <Box mb={1}>
            <H2>{translations.heading}</H2>
          </Box>
          <Body align="center">{translations.body}</Body>
        </Grid>
      </Box>
      <Box
        display="flex"
        flexDirection={isDesktop ? "row" : "column"}
        justifyContent="center"
        mt={isDesktop ? 4.5 : 1.5}
        mb={!isDesktop && 3.5}
        margin="0 auto"
      >
        <Grid container lg={8} md={12}>
          <Grid item lg={6} sm={12}>
            <ComparisonCard
              translations={willfulCardTranslations}
              marginRight={isDesktop && 1}
            />
          </Grid>
          <Grid item lg={6} sm={12}>
            <ComparisonCard
              translations={lawyerCardTranslations}
              marginTop={!isDesktop && 2}
              marginLeft={isDesktop && 1}
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={isDesktop ? 4.5 : 1.5} textAlign="center">
        <TextLink
          text={translations.components.haveQuestions}
          onClick={handleQuestionClick}
        />
      </Box>
      <Box
        className={stickyButtonStyle.stickyBottomBox}
        mt={isDesktop && 4.5}
        display="flex"
        justifyContent="center"
      >
        <ButtonLink
          fullWidth={!isDesktop}
          displayArrowRight
          text={button.submit}
          to={INTENDED_USE_PATH}
        />
      </Box>
    </PageLayoutWithPrevious>
  );
};

export default VsLawyerPage;
