import React from "react";
import Box from "@material-ui/core/Box";
import styled from "styled-components";

import InfoIcon from "./icons/InfoIcon";
import { SmallBody } from "./Typography";

// TODO: Expand FlashContainer for different types of messages
const FlashContainer = styled(Box)`
  background-color: ${(props) =>
    props.fill ? props.fill : rgba(255, 34, 34, 0.1)};
  border: 1px solid
    ${(props) => (props.borderColor ? props.borderColor : "#FF2222")};
  width: 100%;
  border-radius: 5px;
`;

// types: "info", "error", "warning"
const FlashMessage = ({ type = "info", text, children }) => {
  const getColorsByType = (type) => {
    switch (type) {
      case "error":
        return {
          iconColor: "#FF2222",
          borderColor: "#FF2222",
          backgroundColor: "rgba(255, 34, 34, 0.1)",
        };
      case "info":
        // Placeholder values untill designs are given
        return {
          iconColor: "#0064DC",
          borderColor: "#0064DC",
          backgroundColor: "rgba(100, 149, 237, 0.1)",
        };
      case "warning":
        return {
          iconColor: "#E39F3A",
          borderColor: "#E39F3A",
          backgroundColor: "rgba(255, 248, 239, 1)",
        };
      case "disabled":
        return {
          iconColor: "black",
          borderColor: "#CACDD4",
          backgroundColor: "transparent",
        };
      // default error styles
      default:
        return {
          iconColor: "#FF2222",
          borderColor: "#FF2222",
          backgroundColor: "rgba(255, 34, 34, 0.1)",
        };
    }
  };

  const { borderColor, backgroundColor, iconColor } = getColorsByType(type);
  return (
    <FlashContainer
      borderColor={borderColor}
      // React really doesn't like the prop having the word "background" in it
      fill={backgroundColor}
      display="flex"
    >
      <Box py={1} display="flex" ml={1} alignItems="flex-start">
        <Box component="span" mr={0.5} marginTop="2px">
          <InfoIcon iconColor={iconColor} width="16px" height="16px" />
        </Box>
        <Box mr={1} display="flex" alignItems="center" alignSelf="stretch">
          {children ||
            (typeof text === "string" ? (
              <SmallBody dangerouslySetInnerHTML={{ __html: text }} />
            ) : (
              <SmallBody>{text}</SmallBody>
            ))}
        </Box>
      </Box>
    </FlashContainer>
  );
};

export default FlashMessage;
