import React from "react";

const PremiumPreviewImage = ({ isDesktop }) => {
  const maxWidth = isDesktop ? "566px" : "none";
  return (
    <img
      width="100%"
      style={{ maxWidth }}
      src="/images/doc_preview_premium.png"
      alt="document preview"
    />
  );
};

export default PremiumPreviewImage;
