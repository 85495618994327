import React from "react";

const UmbrellaIcon = () => (
  <svg
    width="140"
    height="136"
    viewBox="0 0 140 136"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100.696 98.8963C79.7105 102.962 63.2686 109.539 63.9861 113.664C64.285 115.458 67.9321 116.474 73.7316 116.713L38.5759 130.764C36.9018 131.481 37.8585 133.932 39.5326 133.394C39.5923 133.394 80.2486 119.523 91.429 115.697C95.1957 115.219 99.1417 114.561 103.267 113.784C124.253 109.718 140.695 103.141 139.977 99.0158C139.26 94.8904 121.682 94.8306 100.696 98.8963Z"
      fill="#2F455F"
    />
    <path
      d="M132.671 85.5721C143.552 54.482 122.746 18.8481 86.0955 5.99353C102.836 11.8528 105.108 49.2206 91.596 90.5345C101.102 79.653 124.54 80.6096 132.671 85.5721Z"
      fill="#004EB6"
    />
    <path
      d="M86.0956 5.99529C49.4452 -6.85927 10.9413 7.90853 0 38.9986C11.4794 41.0912 26.4266 53.7066 28.9377 68.5342C44.1838 27.8779 69.295 0.136 86.0956 5.99529Z"
      fill="#004EB6"
    />
    <path
      d="M86.0959 5.9947C69.3551 0.135421 44.2439 27.8773 28.9978 68.5335C49.9836 61.4785 84.661 76.1267 91.5964 90.5357C105.109 49.2218 102.837 11.854 86.0959 5.9947Z"
      fill="#2F455F"
    />
    <path
      d="M81.6778 14.224L40.9414 126.707L44.1456 127.867L84.882 15.3845L81.6778 14.224Z"
      fill="#FFDC1E"
    />
    <path
      d="M78.0244 31.5847C77.7852 31.5847 77.5461 31.5847 77.2471 31.4651C76.3503 31.1662 75.9318 30.1498 76.2307 29.3127L86.2752 1.21209C86.5741 0.315262 87.5905 -0.103265 88.4276 0.195678C89.3244 0.494621 89.7429 1.51105 89.444 2.34809L79.3995 30.4487C79.2201 31.1064 78.682 31.5249 78.0244 31.5847Z"
      fill="#E39F3A"
    />
    <path
      d="M132.671 85.5721C143.552 54.4821 122.746 18.8481 86.0955 5.99353C97.6944 10.0592 102.776 28.7132 101.342 54.7212C129.323 57.1725 135.361 77.7996 132.671 85.5721Z"
      fill="#3989E5"
    />
    <path
      d="M86.0956 5.99528C49.4452 -6.85926 10.9413 7.90852 0 38.9986C2.75028 31.2261 24.7525 18.6705 43.7055 34.5144C58.8918 13.3493 74.4966 1.92966 86.0956 5.99528Z"
      fill="#3989E5"
    />
    <path
      d="M86.0956 5.99305C74.4966 1.92743 54.5871 11.0751 43.7056 34.5122C63.376 28.5931 90.7591 37.6212 101.342 54.7207C107.859 23.2122 97.6348 10.0587 86.0956 5.99305Z"
      fill="#71ADEE"
    />
    <path
      d="M53.1527 105.601C53.1527 105.601 53.2125 105.601 53.1527 105.601C53.3321 105.123 52.3754 104.345 51.0003 103.867C49.6251 103.389 48.4294 103.329 48.25 103.807H48.1902L39.9992 126.348C39.4013 127.902 38.2653 129.158 36.7706 129.875C35.2759 130.593 33.542 130.652 31.9875 130.114C30.433 129.516 29.1774 128.38 28.46 126.886C27.7425 125.391 27.6827 123.657 28.2208 122.103L30.0742 117.08H30.0145C30.1938 116.602 29.2372 115.885 27.8621 115.346C26.487 114.868 25.2912 114.808 25.1118 115.287H25.052L23.2584 120.309C21.106 126.348 24.215 132.984 30.1938 135.136C31.8679 135.734 33.6018 135.974 35.3357 135.794C36.5912 135.675 37.8468 135.316 39.0425 134.778C41.8526 133.462 43.9452 131.131 45.0214 128.201L53.1527 105.601Z"
      fill="#E39F3A"
    />
  </svg>
);

export default UmbrellaIcon;
