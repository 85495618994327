import { createSelector } from "reselect";

const selectWillFinalizationState = (state) => state.willFinalizationStep;

export const selectWillFinalizationStep = createSelector(
  [selectWillFinalizationState],
  (willFinalizationStep) => willFinalizationStep,
);

export const selectWillFinalizationUserId = createSelector(
  [selectWillFinalizationState],
  (willFinalizationStep) => willFinalizationStep.userId,
);
