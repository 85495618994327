import React, { useRef, useState } from "react";
import { Box, Grid, debounce } from "@material-ui/core";
import styled from "styled-components";

import { colorPalette } from "../../config/Theme";

import { SmallBody } from "./Typography";
import { SmallTextButton } from "./Button";
import CloseGreyIcon from "./icons/CloseGreyIcon";
import { useKeyPress } from "../../utilities/hooks";

const StyledBanner = styled(Box)`
  padding: 8px 32px;
  width: 100%;
  color: ${colorPalette.willfulGrey};
  background-color: ${colorPalette.willfulYellow};
`;

const CloseBox = styled(Box)`
  display: flex;
  align-content: center;
  color: ${colorPalette.willfulGrey};
  cursor: pointer;
`;

export default function Banner({
  translations,
  onButtonClick = () => null,
  onMouseOver = () => null,
}) {
  const closeRef = useRef(null);

  const [show, setShow] = useState(true);

  const debouncedMouseOver = debounce(onMouseOver, 1000);

  if (useKeyPress("Escape")) {
    closeRef.current.click();
  }

  if (!show) return null;

  return (
    <StyledBanner role="banner" onMouseOver={debouncedMouseOver}>
      <Grid container>
        <Grid item xs="11">
          <Box role="note" display="inline-block">
            <SmallBody
              inline
              align="left"
              component="span"
              color="willfulGrey"
              role="note"
              tabIndex="0"
              style={{ marginRight: "8px" }}
            >
              {translations.body}
            </SmallBody>
            {translations.button && (
              <SmallTextButton
                display="inline-block"
                text={translations.button}
                onClick={onButtonClick}
                displayArrowRight
              />
            )}
          </Box>
        </Grid>
        <Grid item xs="1">
          <Box
            justifyContent="flex-end"
            display="flex"
            height="100%"
            alignItems="center"
          >
            <CloseBox
              textAlign="center"
              onClick={() => {
                setShow(false);
              }}
              role="button"
              aria-label="Close"
              tabIndex="0"
              ref={closeRef}
            >
              <CloseGreyIcon />
            </CloseBox>
          </Box>
        </Grid>
      </Grid>
    </StyledBanner>
  );
}
