import React, { AnchorHTMLAttributes } from "react";
import { useSelector } from "react-redux";
import { selectTranslations } from "../../selectors/translations";
import { selectUserEmail, selectRailsUserId } from "../../selectors/auth";
import styled from "styled-components";
import { colorPalette } from "../../config/Theme";
import { encodedExecutionURI } from "../../utilities/helpers";
import { selectLanguageCode } from "../../selectors/language";

interface ExecutionTrackerProps {
  provinceCode: string;
}

const ExecutionTrackerSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ExecutionTrackerContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.p`
  font-size: 14px;
  font-weight: 400;
`;

const Hyperlink = styled.a<AnchorHTMLAttributes<HTMLAnchorElement>>`
  font-size: 14px;
  font-weight: 700;
  color: ${colorPalette.willfulBlue};
  text-decoration: none;
`;

const Separator = styled.p`
  margin-left: 8px;
  margin-right: 8px;
`;

const ExecutionTracker = ({ provinceCode }: ExecutionTrackerProps) => {
  const translations = useSelector(
    selectTranslations(["documents", "provinces"]),
  );

  const email: string = useSelector(selectUserEmail);
  const userId = useSelector(selectRailsUserId) as number;
  const province = translations[provinceCode];
  const languageCode = useSelector(selectLanguageCode) as string;

  return (
    <ExecutionTrackerSection>
      <Title>{translations.tracking.title}</Title>
      <ExecutionTrackerContent>
        <Hyperlink
          href={encodedExecutionURI(
            true,
            email,
            userId,
            province,
            languageCode,
          )}
          id="ExecutionTrackerYes"
          target="_blank"
        >
          {translations.tracking.yes}
        </Hyperlink>
        <Separator>|</Separator>
        <Hyperlink
          href={encodedExecutionURI(
            false,
            email,
            userId,
            province,
            languageCode,
          )}
          id="ExecutionTrackerNo"
          target="_blank"
        >
          {translations.tracking.no}
        </Hyperlink>
      </ExecutionTrackerContent>
    </ExecutionTrackerSection>
  );
};

export default ExecutionTracker;
