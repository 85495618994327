import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";

import { selectTranslations } from "./translations";
import { selectIsFieldComplete } from "./utils/progress";
import { yourEstateForms } from "../constants/navigation";
import { selectIsDonationsComplete } from "./donations";
import { selectIsGiftsComplete } from "./gifts";
import { selectHasSpouse } from "./spouse";
import {
  PRIMARY_BENEFICIARY_FIELD_ID,
  ALLOCATIONS_FIELD_ID,
  PREDECEASE_FIELD_ID,
  PRIMARY_BENEFICIARY_FORM_ID,
  GIFTS_FORM_ID,
  DONATIONS_FORM_ID,
  ALLOCATIONS_FORM_ID,
  PREDECEASE_FORM_ID,
  BACKUPS_FORM_ID,
  INHERITANCE_FORM_ID,
  AGE_MILESTONE_FIELD_ID,
} from "../constants/forms";
import {
  selectIsSectionComplete,
  selectSectionCurrentPageNumber,
  selectSectionPercentComplete,
  selectSectionCurrentPath,
  selectSectionTranslations,
  translateInput,
} from "./utils/section-summaries";
import { selectIsYourFamilyComplete } from "./your-family";
import { selectIsAboutYouComplete } from "./about-you";
import { selectIsBackupComplete } from "./backup";
import { GIFTS_PATH } from "../constants/routes";
import { selectAgeOfMajority } from "./provinces";
import { selectUserHasOnlyCharityAllocations } from "./allocations";

const selectYourEstateData = R.pathOr({}, ["yourEstate"]);

const selectYourEstateFieldsComplete = createSelector(
  selectHasSpouse,
  selectIsGiftsComplete,
  selectIsDonationsComplete,
  selectIsFieldComplete(selectYourEstateData, PRIMARY_BENEFICIARY_FIELD_ID),
  selectIsFieldComplete(selectYourEstateData, ALLOCATIONS_FIELD_ID),
  selectIsFieldComplete(selectYourEstateData, PREDECEASE_FIELD_ID),
  selectIsBackupComplete,
  selectIsFieldComplete(selectYourEstateData, AGE_MILESTONE_FIELD_ID),
  (
    hasSpouse,
    isGiftsComplete,
    isDonationsComplete,
    isPrimaryBeneficiaryComplete,
    ...restOfFieldsComplete
  ) => {
    return hasSpouse // if user has spouse, then Primary Beneficiary page included
      ? [
          isGiftsComplete,
          isDonationsComplete,
          isPrimaryBeneficiaryComplete,
          ...restOfFieldsComplete,
        ]
      : [isGiftsComplete, isDonationsComplete, ...restOfFieldsComplete];
  },
);

const selectYourEstateIsCompleteMap = createSelector(
  selectIsGiftsComplete,
  selectIsDonationsComplete,
  selectIsFieldComplete(selectYourEstateData, PRIMARY_BENEFICIARY_FIELD_ID),
  selectIsFieldComplete(selectYourEstateData, ALLOCATIONS_FIELD_ID),
  selectIsFieldComplete(selectYourEstateData, PREDECEASE_FIELD_ID),
  selectIsBackupComplete,
  selectIsFieldComplete(selectYourEstateData, AGE_MILESTONE_FIELD_ID),
  (
    isGiftsComplete,
    isDonationsComplete,
    isPrimaryBeneficiaryComplete,
    isAllocationsComplete,
    isPredeceaseComplete,
    isBackupComplete,
    isInheritanceComplete,
  ) => ({
    [GIFTS_FORM_ID]: isGiftsComplete,
    [DONATIONS_FORM_ID]: isDonationsComplete,
    [PRIMARY_BENEFICIARY_FORM_ID]: isPrimaryBeneficiaryComplete,
    [ALLOCATIONS_FORM_ID]: isAllocationsComplete,
    [PREDECEASE_FORM_ID]: isPredeceaseComplete,
    [BACKUPS_FORM_ID]: isBackupComplete,
    [INHERITANCE_FORM_ID]: isInheritanceComplete,
  }),
);

export const selectIsYourEstateComplete = selectIsSectionComplete(
  selectYourEstateFieldsComplete,
);

export const selectYourEstateCurrentPageNumber = selectSectionCurrentPageNumber(
  selectYourEstateFieldsComplete,
);

export const selectYourEstateTotalPages = createSelector(
  selectHasSpouse,
  (hasSpouse) =>
    // if user has spouse, extra form Primary Beneficiary is shown
    hasSpouse ? 7 : 6,
);

export const selectYourEstatePercentComplete = selectSectionPercentComplete(
  selectYourEstateCurrentPageNumber,
  selectYourEstateTotalPages,
);

export const selectYourEstateNextForm = R.path([
  "navigation",
  "nextForm",
  "yourEstate",
]);

export const selectYourEstateCurrentPath = selectSectionCurrentPath(
  selectYourEstateNextForm,
);

const selectYourEstateFormSummaries = createSelector(
  selectYourEstateData,
  selectYourEstateIsCompleteMap,
  selectHasSpouse,
  selectTranslations(["global", "navigation", "yourEstate"]),
  selectAgeOfMajority,
  selectUserHasOnlyCharityAllocations,
  (
    yourEstateData,
    isCompleteMap,
    hasSpouse,
    translations,
    ageOfMajority,
    userHasOnlyCharityAllocations,
  ) => {
    const formSummaries = yourEstateForms.reduce((summaryList, currentForm) => {
      // primary beneficiary form only present if user has spouse
      if (currentForm.id === PRIMARY_BENEFICIARY_FORM_ID && !hasSpouse) {
        return summaryList;
      }

      // if user only has charity allocations, exclude predecease summary from list
      if (
        currentForm.id === PREDECEASE_FORM_ID &&
        userHasOnlyCharityAllocations
      ) {
        return summaryList;
      }

      const fields = currentForm.fields.map(
        ({ key }) => ({
          label: translations.summaryLabel[key],
          value: translateInput(
            key,
            yourEstateData,
            translations,
            ageOfMajority,
          ),
        }),
        [],
      );

      const formSummary = {
        path: currentForm.path,
        isComplete: isCompleteMap[currentForm.id],
        fields,
      };

      return [...summaryList, formSummary];
    }, []);

    return formSummaries.filter((form) => form.isComplete);
  },
);

const selectIsYourEstateDisabled = createSelector(
  selectIsAboutYouComplete,
  selectIsYourFamilyComplete,
  (isAboutYouComplete, isYourFamilyComplete) =>
    !(isAboutYouComplete && isYourFamilyComplete),
);

const selectYourEstateTranslations = selectSectionTranslations(
  selectIsYourEstateComplete,
  selectYourEstateCurrentPageNumber,
  ["global", "yourEstate"],
  selectYourEstateTotalPages,
  selectIsYourEstateDisabled,
);

export const selectYourEstateStatus = createStructuredSelector({
  sectionKey: () => "yourEstate",
  isComplete: selectIsYourEstateComplete,
  progress: selectYourEstatePercentComplete,
  continuePath: () => GIFTS_PATH,
  formSummaries: selectYourEstateFormSummaries,
  disabled: selectIsYourEstateDisabled,
  translations: selectYourEstateTranslations,
  sectionLastPage: () => "/inheritance",
});
