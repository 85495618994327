import { createStructuredSelector } from "reselect";

import { selectTranslations } from "./translations";
import { selectHasLoadedDataById } from "./requests";
import { selectUserHasOnlyCharityAllocations } from "./allocations";

export const BackupsGuidanceProps = createStructuredSelector({
  translations: selectTranslations(["global", "backupsGuidancePage"]),
  hasLoaded: selectHasLoadedDataById("dashboard"),
  userHasOnlyCharityAllocations: selectUserHasOnlyCharityAllocations,
});
