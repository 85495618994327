// GENERIC FIELDS
export const FIRST_NAME_FIELD_ID = "firstName";
export const MIDDLE_NAME_FIELD_ID = "middleName";
export const LAST_NAME_FIELD_ID = "lastName";
export const PHONE_NUMBER_FIELD_ID = "phoneNumber";
export const SPOUSE_FIRST_NAME_FIELD_ID = "spouseFirstName";
export const SPOUSE_MIDDLE_NAME_FIELD_ID = "spouseMiddleName";
export const SPOUSE_LAST_NAME_FIELD_ID = "spouseLastName";
export const DOB_FIELD_ID = "dateOfBirth";
export const FULL_NAME_FIELD_ID = "fullName";
export const GENDER_FIELD_ID = "gender";
export const PROVINCE_FIELD_ID = "provinceId";
export const EMAIL_FIELD_ID = "email";
export const OLD_PASSWORD_FIELD_ID = "oldPassword";
export const CONFIRM_CURRENT_PASSWORD_FIELD_ID = "confirmCurrentPassword";
export const CONFIRM_EMAIL_FIELD_ID = "confirmEmail";
export const PASSWORD_FIELD_ID = "password";
export const PASSWORD_CONF_FIELD_ID = "confirmPassword";
export const CAA_FIELD_ID = "caaMembershipNumber";
export const CHECKOUT_CARD_NAME_FIELD_ID = "cardholderName";
export const CHECKOUT_ADDRESS_FIELD_ID = "addressZip";
export const TOS_FIELD_ID = "agreedToTos";
export const PLAN_QUANTITY_FIELD_ID = "quantity";
export const IS_INVITED_USER_ID = "isInvitedUser";
export const PAID_ID = "paid";
export const LANGUAGE_FIELD_ID = "lang";
export const LOCKED_PROVINCE_LEAD_FIELD_ID = "lockedProvinceLead";
export const REASONS_ID = "reasons";
export const RELATIONSHIP_FIELD_ID = "relationship";
export const DATE_FIELD_ID = "date";

// GENERIC FORMS
export const CHECKOUT_FORM_ID = "checkout";
export const MANAGE_PAYMENT_METHOD_FORM_ID = "managePaymentMethod";
export const CAA_FORM_ID = "caaForm";
export const PROMO_CODE_FORM_ID = "promoCode";
export const REFERRAL_FORM_ID = "referral";
export const PRODUCTS_FORM_ID = "products";
export const PLANS_FORM_ID = "plans";
export const UPSELL_FORM_ID = "upsell";
export const RECOMMENDATION_FORM_ID = "recommendation";

// YOUR ACCOUNT FORMS
export const UPDATE_EMAIL_FORM_ID = "updateEmail";
export const UPDATE_PASSWORD_FORM_ID = "updatePassword";
export const DELETE_ACCOUNT_FORM_ID = "deleteAccount";
export const DELETE_GOOGLE_SSO_ACCOUNT_FORM_ID = "deleteGoogleSSOAccount";
export const INVITE_USER_FORM_ID = "inviteUser";
export const PURCHASES_SUMMARY_FORM_ID = "purchasesSummary";
export const VALIDATE_INVITE_TOKEN_ID = "validateInviteToken";
export const DEACTIVATE_AUTO_RENEWAL_FORM_ID = "deactivateAutoRenewal";
export const AGREED_TO_TOS_FORM_ID = "agreedToTos";
export const UNDERSTANDS_WILL_EXECUTION_FORM_ID = "understandsWillExecution";

// AUTH FORMS
export const SIGNUP_FORM_ID = "signup";
export const LOGIN_FORM_ID = "login";
export const RESET_PASSWORD_FORM_ID = "resetPassword";
export const FORGOT_PASSWORD_FORM_ID = "forgotPassword";
export const GOOGLE_SSO_ALREADY_EXISTS_PLEASE_LOGIN = "emailAlreadyExists";

// ABOUT YOU FORMS
export const RESIDENCE_FORM_ID = "residence";
export const LANGUAGE_FORM_ID = "lang";
export const FULL_NAME_FORM_ID = "fullName";
export const DOB_FORM_ID = "dateOfBirth";
export const GENDER_FORM_ID = "gender";
export const PEOPLE_QUANTITY_INTENT_FORM_ID = "intendedUse";

// ABOUT YOU FIELDS
export const RESIDENCE_FIELD_ID = "residence";
export const PEOPLE_QUANTITY_INTENT_FIELD_ID = "peopleQuantityIntent";

// YOUR FAMILY FORMS
export const MARITAL_STATUS_FORM_ID = "maritalStatus";
export const SPOUSE_FORM_ID = "spouse";
export const SPOUSE_MODAL_FORM_ID = "spouseMoldalForm";
export const NUM_CHILDREN_FORM_ID = "numberOfChildren";
export const CHILDREN_FORM_ID = "children";
export const CHILDREN_NAME_FIELD_ID = "childrenName";
export const CHILD_MODAL_FORM_ID = "childModalForm";
export const CHILDREN_GUARDIANS_FORM_ID = "guardians";
export const CHILDREN_GUARDIANS_MODAL_FORM_ID = "guardiansModalForm";
export const NUM_PETS_FORM_ID = "numberOfPets";
export const PETS_FORM_ID = "pets";
export const PET_GUARDIANS_FORM_ID = "petGuardians";
export const PET_TRUST_FORM_ID = "petTrust";
export const PET_MODAL_FORM_ID = "petModal";
export const PET_GUARDIAN_MODAL_FORM_ID = "petGuardianModal";

// YOUR FAMILY FIELDS
export const SPOUSE_NAME_FIELD_ID = "spouseName";
export const SPOUSE_BASIC_INFO_FIELD_ID = "spouseBasicInfoField";
export const MARITAL_STATUS_FIELD_ID = "marital";
export const NUM_CHILDREN_FIELD_ID = "numChildren";
export const NUM_PETS_FIELD_ID = "numPets";
export const CHILDREN_FIELD_ID = "children";
export const FIRST_PARENT_FIELD_ID = "firstParent";
export const SECOND_PARENT_FIELD_ID = "secondParent";
export const CHILDREN_GUARDIANS_FIELD_ID = "guardians";
export const PETS_FIELD_ID = "pets";
export const SPECIES_FIELD_ID = "species";
export const PET_GUARDIANS_FIELD_ID = "petGuardians";
export const PET_TRUST_AMOUNT_FIELD_ID = "petTrustAmount";

// YOUR ESTATE FORMS
export const GIFTS_FORM_ID = "gifts";
export const DONATIONS_FORM_ID = "donations";
export const SHARE_DONATION_DETAIL_FIELD_ID = "shareDonationDetails";
export const PRIMARY_BENEFICIARY_FORM_ID = "primaryBeneficiary";
export const ALLOCATIONS_FORM_ID = "allocations";
export const PREDECEASE_FORM_ID = "predecease";
export const BACKUPS_FORM_ID = "backups";
export const INHERITANCE_FORM_ID = "inheritance";
export const ADD_BENEFICIARY_FORM_ID = "addBeneficiary";
export const REMOVE_BENEFICIARY_FORM_ID = "removeBeneficiary";
export const PERSON_ALLOCATION_FORM_ID = "personAllocation";
export const CHARITY_FORM_ID = "charity";
export const CUSTOM_CHARITY_FORM_ID = "customCharity";
export const ADD_PERSON_BACKUP_FORM_ID = "addPersonBackup";

// YOUR ESTATE FIELDS
export const GIFTS_FIELD_ID = "gifts";
export const CONTACT_ID_FIELD_ID = "contactId";
export const GIFT_DESCRIPTION_FIELD_ID = "description";
export const DONATIONS_FIELD_ID = "donations";
export const ENTRY_TYPE_FIELD_ID = "entryType";
export const REGISTERED_NAME_FIELD_ID = "registeredName";
export const BUSINESS_NUMBER_FIELD_ID = "businessNumber";
export const CHARITY_ID_FIELD_ID = "charityId";
export const CASH_AMOUNT_FIELD_ID = "cashAmount";
export const PRIMARY_BENEFICIARY_FIELD_ID = "primaryBeneficiary";
export const ALLOCATIONS_FIELD_ID = "allocations";
export const PREDECEASE_FIELD_ID = "predecease";
export const INHERITANCE_FIELD_ID = "inheritance";
export const AGE_MILESTONE_FIELD_ID = "ageMilestone";
export const INHERITANCE_AGES_FIELD_ID = "inheritanceAges";
export const BACKUPS_FIELD_ID = "backups";
export const ADD_PERSON_BENEFICIARY_FIELD_ID = "personBeneficiary";
export const ADD_CHARITY_BENEFICIARY_FIELD_ID = "charityBeneficiary";
// for onBlur validation to work we need to pass field name as "filedID[].fieldName"
export const ASYNC_BLUR_ALLOCATIONS_FIELD_NAME = `${ALLOCATIONS_FIELD_ID}[].amount`;

// YOUR ARRANGEMENTS FORMS
export const EXECUTORS_FORM_ID = "executors";
export const ADD_AND_EDIT_EXECUTORS_FORM_ID = "addAndEditExecutors";
export const RESTING_FORM_ID = "wishesResting";
export const ASHES_FORM_ID = "wishesAshes";
export const CEREMONY_FORM_ID = "wishesCeremony";
export const PERSONAL_ATTORNEYS_FORM_ID = "personalAttorneys";
export const PERSONAL_ATTORNEY_MODAL_FORM_ID = "personalAttorneyModal";
export const PROPERTY_ATTORNEYS_FORM_ID = "propertyAttorneys";
export const PROPERTY_ATTORNEY_MODAL_FORM_ID = "propertyAttorneyModal";
export const HEALTHCARE_FORM_ID = "healthcare";
export const PAIN_MANAGE_FORM_ID = "painManage";
export const LIFE_PROLONG_FORM_ID = "lifeProlong";

// YOUR ARRANGEMENTS FIELDS
export const EXECUTORS_FIELD_ID = "executors";
export const ADD_AND_EDIT_EXECUTORS_FIELD_ID = "addAndEditExecutors";
export const CO_EXECUTORS_CHECKBOX_FIELD_ID = "coExecutorsCheckbox";
export const SPOUSE_IS_EXECUTOR_FIELD_ID = "spouseIsExecutor";
export const RESTING_FIELD_ID = "resting";
export const ASHES_FIELD_ID = "ashes";
export const CEREMONY_FIELD_ID = "ceremony";
export const PERSONAL_ATTORNEYS_FIELD_ID = "personalAttorneys";
export const SPOUSE_IS_PERSONAL_ATTORNEY_FIELD_ID = "spouseIsPersonalAttorney";
export const PROPERTY_ATTORNEYS_FIELD_ID = "propertyAttorneys";
export const SPOUSE_IS_PROPERTY_ATTORNEY_FIELD_ID = "spouseIsPropertyAttorney";
export const PAIN_MANAGE_FIELD_ID = "painManage";
export const LIFE_PROLONG_FIELD_ID = "lifeProlong";

// CHECKOUT
export const PROMO_CODE_FIELD_ID = "promoCode";
export const CONFIRMATION_PAGE_ID = "receipt";
export const DOCUMENTS_PAGE_ID = "documents";
export const BNPL_INTEREST_FIELD_ID = "BNPLInterstField";
export const STRIPE_CLIENT_SECRET_FIELD_ID = "stripeClientSecret";
export const BNPL_INTEREST_FORM_ID = "BNPLInterstForm";

// INPUT VALUES
export const PARENT_USER_VALUE = "user";
export const PARENT_NOT_USER_VALUE = "not_user";
export const PARENT_SPOUSE_VALUE = "spouse";
export const PARENT_NOT_SPOUSE_VALUE = "not_spouse";
export const PARENT_NOT_APPLICABLE_VALUE = "not_applicable";
export const MARITAL_STATUS_SINGLE = "single";
export const MARITAL_STATUS_MARRIED = "married";
export const MARITAL_STATUS_COMMON_LAW = "common_law";
export const NUM_CHILDREN_ZERO = "zero_children";
export const NUM_CHILDREN_ONE = "one_child";
export const NUM_CHILDREN_MORE = "more_children";
export const NUM_PETS_ZERO = "zero_pets";
export const NUM_PETS_ONE = "one_pet";
export const NUM_PETS_MORE = "more_pets";
export const PRIMARY_BENE_TO_SPOUSE = "to_spouse";
export const DONATIONS_CUSTOM_CHARITY = "text";
export const DONATIONS_LIST_CHARITY = "select";
export const PERSON_ALLOCATION = "PersonAllocation";
export const CHARITY_ALLOCATION = "CharityAllocation";
export const PERSON_BACKUP = "PersonBackup";
export const CHARITY_BACKUP = "CharityBackup";
export const RESTING_CREMATION = "rest_cremation";
export const FRIEND_OR_FAMILY = "friendOrFamily";
export const PROPERTY = "property";
export const VEHICLE = "vehicle";
export const COLLECTIBLE = "collectible";
export const ACCOUNT = "account";
export const BUSINESS_INTEREST = "business_interest";
export const PENSION = "pension";
export const CREDIT_CARD = "credit_card";

// NOTIFICATIONS
export const NOTIFICATIONS_FORM_ID = "notifications";

// ASSET LIST
export const ASSET_LIST_FORM_ID = "assetList";
export const PHYSICAL_ASSETS_FORM_ID = "physicalAssets";
export const FINANCIAL_ASSETS_FORM_ID = "financialAssets";
export const INSURANCE_ASSETS_FORM_ID = "insuranceAssets";

// GENERIC MODALS
export const CONTACT_MODAL_FORM_ID = "contactModal";

// GENERIC FIELDS
export const ASSET_TYPE_FIELD_ID = "assetType";
export const DESCRIPTION_FIELD_ID = "description";
export const OWNERSHIP_FIELD_ID = "ownership";
export const OTHER_OWNER_FIRST_NAME_FIELD_ID = "otherOwnerFirstName";
export const OTHER_OWNER_MIDDLE_NAME_FIELD_ID = "otherOwnerMiddleName";
export const OTHER_OWNER_LAST_NAME_FIELD_ID = "otherOwnerLastName";
export const CONTACT_FIELD_ID = "contact";
export const CONTACT_DROPDOWN_FIELD_ID = "contactDropdown";

// PROPERTY FIELDS
export const PROPERTY_ADDRESS_FIELD_ID = "address";
export const PROPERTY_MORTGAGE_FIELD_ID = "mortgage";

// VEHICLE FIELDS
export const VEHICLE_YEAR_FIELD_ID = "year";
export const VEHICLE_MAKE_FIELD_ID = "make";
export const VEHICLE_MODEL_FIELD_ID = "model";

// COLLECTIBLE FIELDS
export const COLLECTIBLE_TYPE_FIELD_ID = "collectibleType";
export const COLLECTIBLE_LOCATION_FIELD_ID = "location";

// ACCOUNTS FIELDS
export const ACCOUNT_TYPE_FIELD_ID = "accountType";
export const FINANCIAL_INSTITUTION_FIELD_ID = "financialInstitution";

// BUSINESS INTEREST FIELDS
export const ISSUING_COMPANY_FIELD_ID = "issuingCompany";
export const NUMBER_OF_SHARES_FIELD_ID = "numberOfShares";

// PENSION FIELDS
// all reused

// CREDIT CARD FIELDS
export const CREDIT_CARD_TYPE_FIELD_ID = "creditCardType";
export const NAME_ON_CARD_FIELD_ID = "nameOnCard";

// INSURANCE FIELDS
export const INSURANCE_COMPANY_FIELD_ID = "insuranceCompany";
