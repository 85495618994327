import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { goBack } from "connected-react-router";

import { useTheme, withTheme } from "@material-ui/styles";
import { Box, Grid, Hidden, useMediaQuery } from "@material-ui/core";
import { TextButton } from "../../components/ui/Button";
import LoadingSpinner from "../../components/ui/LoadingSpinner";
import {
  Body,
  H1,
  SmallTextLink,
  TextLink,
} from "../../components/ui/Typography";

import { fetchDashboard } from "../../actions/dashboard";
import { FamilyPlanGuidanceProps } from "../../selectors/family-plan-guidance";

import { colorPalette } from "../../config/Theme";
import TrustIcons from "../../components/ui/icons/TrustIcons";
import UnlimitedUpdatesInfoBox from "../../components/ui/UnlimitedUpdatesInfoBox";
import { familyRecommendationPageTranslations } from "../../selectors/about-you";
import {
  selectFamilyPriceForTwoAdults,
  selectFamilyStrikeThroughPriceForTwoAdults,
  selectPlansGuidanceQuestionsTranslations,
  selectShowExclusiveOffer,
} from "../../selectors/plans";
import { BaseAccordion } from "../../components/ui/Accordion";
import FamilyPreviewImage from "../../components/ui/images/FamilyPreviewImage";
import { FamilyBundlePlanCard } from "../../components/plans/PlanCard";
import { RECOMMENDATION_FORM_ID } from "../../constants/forms";
import { fetchPlans } from "../../actions/plans";
import { PageWrapper } from "../Layout";

const FamilyPlanGuidancePage = ({
  translations,
  hasLoaded,
  nextSectionPath,
}) => {
  useEffect(() => {
    dispatch(fetchPlans(RECOMMENDATION_FORM_ID));
    if (!hasLoaded) {
      dispatch(fetchDashboard());
    }
  }, [dispatch, hasLoaded]);

  const dispatch = useDispatch();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const price = useSelector(selectFamilyPriceForTwoAdults);
  const strikeThroughPrice = useSelector(
    selectFamilyStrikeThroughPriceForTwoAdults,
  );
  const showExclusiveOffer = useSelector(selectShowExclusiveOffer);

  const { unlimitedUpdatesInfoBox } = useSelector(
    familyRecommendationPageTranslations,
  );
  const plansGuidanceQuestionsTranslations = useSelector(
    selectPlansGuidanceQuestionsTranslations,
  );
  if (!hasLoaded)
    return (
      <Box flexGrow="1">
        <LoadingSpinner />
      </Box>
    );

  return (
    <PageWrapper flexGrow={1}>
      <Box
        mx={isDesktop && 2}
        mt={isDesktop ? 4.5 : 1.5}
        mb={isDesktop ? 10 : 3.5}
      >
        <TextButton
          text={translations.button.previous}
          onClick={() => dispatch(goBack())}
          displayArrowLeft
          color={colorPalette.willfulGrey}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={!isDesktop && 1.5}
        >
          <Grid item xl={8}>
            <H1>{translations.pageTitle}</H1>
            <Body
              align="center"
              dangerouslySetInnerHTML={{
                __html: translations.description,
              }}
            />
          </Grid>
        </Box>
        <Box
          display="flex"
          flexDirection={isDesktop ? "row" : "column"}
          justifyContent="center"
        >
          {/* Left Column */}
          <Grid item lg={8} md={12}>
            <Box mt={isDesktop && 1.5} textAlign="center">
              <FamilyPreviewImage isDesktop={isDesktop} />
            </Box>
          </Grid>
          {/* End Left Column */}
          {/* Right Column */}
          <Grid item lg={4} md={12}>
            <Box mt={isDesktop ? 0 : 1}>
              <FamilyBundlePlanCard
                price={price}
                strikeThroughPrice={strikeThroughPrice}
                noMaxWidth={!isDesktop}
                showExclusiveOffer={showExclusiveOffer}
                showCheckoutButton
                noCta
              />
              <Box mt={1.5} textAlign="center">
                <Hidden mdDown>
                  {/* Desktop */}
                  <SmallTextLink
                    className="qa-continue"
                    to={nextSectionPath}
                    text={translations.skipButton}
                  />
                </Hidden>
                <Hidden lgUp>
                  {/* Tablet */}
                  <TextLink
                    className="qa-continue"
                    to={nextSectionPath}
                    text={translations.skipButton}
                  />
                </Hidden>
                <TrustIcons
                  translations={translations}
                  shouldIncludeThirtyDay
                  shouldIncludeDragonsDen
                  centerLogos
                />
              </Box>
            </Box>
          </Grid>
          {/* End Right Column */}
        </Box>

        <Box mt={isDesktop && 4} display="flex" justifyContent="center">
          <UnlimitedUpdatesInfoBox
            title={unlimitedUpdatesInfoBox.title}
            paragraph={unlimitedUpdatesInfoBox.paragraph}
            isDesktop={isDesktop}
            fullWidth
          />
        </Box>

        <Box mt={isDesktop ? 4.5 : 2} display="flex" justifyContent="center">
          <Grid>
            <BaseAccordion translations={plansGuidanceQuestionsTranslations} />
          </Grid>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default connect(FamilyPlanGuidanceProps)(
  withTheme(FamilyPlanGuidancePage),
);
