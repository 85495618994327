// Relationships
export const SPOUSE_OR_PARTNER_RELATIONSHIP = "spouse_or_partner";
export const CHILD_RELATIONSHIP = "child";

// Badges
export const MINOR_CHILD_BADGE = "minor_child";
export const ADULT_CHILD_BADGE = "adult_child";

// Contact Dropdown Extra Options
export const ADD_NEW_CONTACT = "add-new-contact";
export const MANAGE_MY_CONTACTS = "manage-my-contacts";

// Valid Contactable Models Values
export const CHILDREN_GUARDIAN_CONTACTABLE_MODEL = "Guardian";
export const PET_GUARDIAN_CONTACTABLE_MODEL = "PetGuardian";
export const GIFT_CONTACTABLE_MODEL = "Gift";
export const ALLOCATION_CONTACTABLE_MODEL = "Allocation";
export const BACKUP_CONTACTABLE_MODEL = "Backup";
export const EXECUTOR_CONTACTABLE_MODEL = "Executor";
export const PROPERTY_ATTORNEY_CONTACTABLE_MODEL = "PropertyAttorney";
export const PERSONAL_ATTORNEY_CONTACTABLE_MODEL = "PersonalAttorney";
export const WITNESS_CONTACTABLE_MODEL = "WillRegistry";
