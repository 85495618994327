import React from "react";
import SvgIcon from "./SvgIcon";

const MercerVelanLogo = ({ fill = "#fff" }) => {
  return (
    <SvgIcon
      height="72"
      viewBox="0 0 587 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M56.459 2.82h24.687L40.578 72 .278 2.82H24.7l15.878 26.926 15.88-26.927Zm69.525 64.515h-50.31V21.88L87.018 2.82h51.511l-10.81 18.261H96.226v6.132h27.223l-10.01 17.461H96.226v4.931h29.758v17.73Zm28.824-17.73h27.624l-10.408 17.73h-38.967V21.88l11.342-19.06h10.409v46.785ZM216.726.02l38.967 67.315h-50.176l10.009-17.73h6.805l-5.605-10.396-16.681 28.126h-22.552L216.726.02Zm55.781 21.86v45.455h-10.409l-11.342-18.928V2.82h31.76l16.413 27.725V2.82h21.618v64.516h-21.618L272.507 21.88Z" fill={fill}/>
    </SvgIcon>
  );
};

export default MercerVelanLogo;
