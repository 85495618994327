import { combineReducers } from "redux";

import { ADD_DOCUMENTS_TYPE } from "../actions/documents";
import { UPDATE_STATUS_TYPE } from "../actions/status";

const documentLinks = (state = {}, { type, payload }) => {
  switch (type) {
    case ADD_DOCUMENTS_TYPE:
      return payload.documents;
    default:
      return state;
  }
};

const understandsWillExecutionDate = (state = false, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.user.understandsWillExecutionDate;
    default:
      return state;
  }
};

export default combineReducers({
  documentLinks,
  understandsWillExecutionDate,
});
