import React, { useEffect } from "react";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { push } from "connected-react-router";
import { useMediaQuery, useTheme } from "@material-ui/core";

import { fetchDashboard } from "../../actions/dashboard";
import { dashboardPageProps } from "../../selectors/dashboard";
import SectionsSummary from "../SectionsSummary";
import { H1, LargeBody } from "../../components/ui/Typography";
import { ButtonLink } from "../../components/ui/Button";
import { PageLayout } from "../../components/ui/PageLayout";
import DashboardSummary from "../../components/ui/DashboardSummary";
import {
  analyticsClickExternalLink,
  analyticsDownloadNotarizationGuide,
  analyticsOpenedChat,
} from "../../actions/analytics";
import { displayChat } from "../../actions/customer-service";
import FinalizeMyWillButton from "../../components/ui/FinalizeMyWillButton";

const DashboardPage = ({
  fetchDashboard,
  hasLoaded,
  analyticsClickExternalLink,
  analyticsDownloadNotarizationGuide,
  analyticsOpenedChat,
  nextSectionPath,
  dashboardSummaryProps,
  translations,
  displayChat,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchDashboard();
  }, []);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const showFinalizeWillButton =
    dashboardSummaryProps.hasUserPaid &&
    dashboardSummaryProps.isCompleteAllSections;

  return (
    <PageLayout pb={10}>
      <Grid container justify="center">
        <Grid item sm={12} md={8} lg={6}>
          <H1 align="left" data-hj-suppress>
            {translations.pageTitle}
          </H1>
          <LargeBody align="left">{translations.pageDescription}</LargeBody>
          {showFinalizeWillButton && (
            <FinalizeMyWillButton
              text={translations.button.finalizeMyWill}
              fullWidth={isMobile}
            />
          )}
          <Box mt={1.5} mb={4.5}>
            <ButtonLink
              secondary={showFinalizeWillButton}
              fullWidth={isMobile}
              className="qa-submit"
              to={nextSectionPath}
              text={translations.button.section}
              displayArrowRight
            />
          </Box>
          <SectionsSummary nextSectionPath={nextSectionPath} />
        </Grid>
        <Grid item justify="flex-end" sm={12} md={8} lg={6}>
          <DashboardSummary
            analyticsClickExternalLink={analyticsClickExternalLink}
            analyticsDownloadNotarizationGuide={
              analyticsDownloadNotarizationGuide
            }
            analyticsOpenedChat={analyticsOpenedChat}
            displayChat={displayChat}
            {...dashboardSummaryProps}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default connect(dashboardPageProps, {
  fetchDashboard,
  analyticsClickExternalLink,
  analyticsDownloadNotarizationGuide,
  analyticsOpenedChat,
  push,
  displayChat,
})(DashboardPage);
