import React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { H2, SmallTextLink } from "../../ui/Typography";
import { RECOMMENDATION_FORM_ID } from "../../../constants/forms";
import { premiumPlan } from "../../../constants/plans";
import { PLANS_PATH } from "../../../constants/routes";
import TrustIcons from "../../ui/icons/TrustIcons";
import { BaseAccordion as Accordion } from "../../ui/Accordion";
import UnlimitedUpdatesInfoBox from "../../ui/UnlimitedUpdatesInfoBox";
import { PremiumPlanCard } from "../../plans/PlanCard";

const PremiumRecommendationForm = ({
  translations,
  updatePlans,
  analyticsClickExternalLink,
  showExclusiveOffer,
  price,
  strikeThroughPrice,
  isMobile,
}) => {
  const handlePlanUpgrade = () => {
    updatePlans({
      symbol: premiumPlan.symbol,
      formId: RECOMMENDATION_FORM_ID,
    });
  };

  const seeAllPlansDefaultMarginTop = isMobile ? 2 : 3.5;

  return (
    <Box pb={4.5}>
      <Grid container spacing={2} justifyContent="center">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Grid item sm={10} md={8}>
            <Box mt={isMobile ? 3 : 4.5}>
              <H2 component="h1">{translations.pageTitle}</H2>
            </Box>
          </Grid>

          <Grid item sm={10} md={8}>
            <Box mt={isMobile ? 2 : 3.5}>
              <PremiumPlanCard
                analyticsClickExternalLink={analyticsClickExternalLink}
                price={price}
                strikeThroughPrice={strikeThroughPrice}
                showExclusiveOffer={showExclusiveOffer}
                onClick={handlePlanUpgrade}
                recommendedForYou
                noMaxWidth
                showMobileStickyCta
              />
            </Box>

            <Box mt={2}>
              <UnlimitedUpdatesInfoBox
                title={translations.unlimitedUpdatesInfoBox.title}
                paragraph={translations.unlimitedUpdatesInfoBox.paragraph}
              />
            </Box>
          </Grid>
        </Box>

        <Grid item sm={12} md={9} lg={6}>
          <Box
            mt={seeAllPlansDefaultMarginTop}
            display="flex"
            justifyContent="center"
          >
            <SmallTextLink
              text={translations.seeAllPlansLinkText}
              to={PLANS_PATH}
              className="qa-skip"
            />
          </Box>

          <Box
            mt={isMobile ? 0 : 1.5}
            mb={isMobile ? 2 : 3.5}
            display="flex"
            justifyContent="center"
          >
            <TrustIcons
              translations={translations}
              shouldIncludeThirtyDay
              shouldIncludeDragonsDen
            />
          </Box>
          <Accordion translations={translations} isMobile={isMobile} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PremiumRecommendationForm;
