import { combineReducers } from "redux";
import { ANALYTICS_SET_SESSION_ID_TYPE } from "../actions/analytics";

import {
  AUTHENTICATED_TYPE,
  CLEAR_AUTH_TYPE,
  ADD_INVITE_TOKEN_TYPE,
  CLEAR_APP_STATE_TYPE,
  SET_AUTH_METHOD,
} from "../actions/auth";
import { UPDATE_STATUS_TYPE } from "../actions/status";

const loggedIn = (state = false, { type }) => {
  switch (type) {
    case AUTHENTICATED_TYPE:
      return true;
    case CLEAR_AUTH_TYPE:
      return false;
    default:
      return state;
  }
};

const accessToken = (state = null, { type, payload }) => {
  switch (type) {
    case AUTHENTICATED_TYPE:
      return payload.accessToken || state;
    case CLEAR_AUTH_TYPE:
      return null;
    default:
      return state;
  }
};

const uid = (state = null, { type, payload }) => {
  switch (type) {
    case AUTHENTICATED_TYPE:
      return payload.uid || state;
    case CLEAR_AUTH_TYPE:
      return null;
    default:
      return state;
  }
};

const client = (state = null, { type, payload }) => {
  switch (type) {
    case AUTHENTICATED_TYPE:
      return payload.client || state;
    case CLEAR_AUTH_TYPE:
      return null;
    default:
      return state;
  }
};

const inviteToken = (state = null, { type, payload }) => {
  switch (type) {
    case ADD_INVITE_TOKEN_TYPE:
      return payload.inviteToken;
    default:
      return state;
  }
};

const id = (state = null, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.user.id;
    default:
      return state;
  }
};

const analyticsSessionId = (state = null, { type, payload }) => {
  switch (type) {
    case ANALYTICS_SET_SESSION_ID_TYPE:
      return payload.sessionId;
    default:
      return state;
  }
};

const tokenUpdatedAt = (state = 0, { type }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return Math.floor(new Date().getTime() / 1000);
    default:
      return state;
  }
};

const redirectTo = (state = null, { type, payload }) => {
  switch (type) {
    case CLEAR_APP_STATE_TYPE:
      return payload.redirectTo || state;
    case AUTHENTICATED_TYPE:
      return null;
    default:
      return state;
  }
};

const authMethod = (state = null, { type, payload }) => {
  switch (type) {
    case SET_AUTH_METHOD:
      return {
        mustAuthViaPassword: payload.mustAuthViaPassword,
        email: payload.email,
      };
    default:
      return state;
  }
};

const agreedToTos = (state = null, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.user.agreedToTos;
    default:
      return state;
  }
};

const utmSource = (state = null, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.user.utmSource;
    default:
      return state;
  }
};

const initialUtmSource = (state = null, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.user.initialUtmSource;
    default:
      return state;
  }
};

const isGoogleSso = (state = null, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.user.isGoogleSso;
    default:
      return state;
  }
};

const isPasswordSet = (state = null, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.user.isPasswordSet;
    default:
      return state;
  }
};

const isReturningUser = (state = null, { type, payload }) => {
  switch (type) {
    case UPDATE_STATUS_TYPE:
      return payload.isReturningUser || false;
    default:
      return state;
  }
};

export default combineReducers({
  loggedIn,
  accessToken,
  uid,
  client,
  inviteToken,
  id,
  analyticsSessionId,
  tokenUpdatedAt,
  redirectTo,
  authMethod,
  agreedToTos,
  isGoogleSso,
  isPasswordSet,
  utmSource,
  initialUtmSource,
  isReturningUser,
});
