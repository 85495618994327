import React, { useCallback, AnchorHTMLAttributes, useEffect } from "react";
import FinalizeWillStepAccordion from "../ui/FinalizeWillStepAccordion";
import { Body } from "../ui/Typography";
import { Box } from "@material-ui/core";
import { SecondaryButton } from "../ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { MODAL_SIGN_AND_WITNESS } from "../../constants/modal";
import { displayModal } from "../../actions/modal";
import {
  doesUnderstandWillExecution,
  selectDocumentCardCopy,
} from "../../selectors/documents";
import { downloadDocuments, fetchDocuments } from "../../actions/documents";
import { selectRailsUserId } from "../../selectors/auth";
import { analyticsFinalizeWillStepCtaClicked } from "../../actions/analytics";

const DownloadAndReview = ({
  translation,
  completedAt,
  onCheck,
}: DownloadAndReviewPropTypes) => {
  const { body, title, cta } = translation;
  const stepName = "download_document";
  const userId = useSelector(selectRailsUserId) as number;

  const dispatch = useDispatch();
  const documentCopy = useSelector(selectDocumentCardCopy);
  const isSpouse = documentCopy.spouseDocuments || false;

  const doesUserUnderstandWillExecution = useSelector(
    doesUnderstandWillExecution,
  );

  useEffect(() => {
    dispatch(fetchDocuments());
  }, []);

  const handleClick = useCallback(() => {
    dispatch(
      analyticsFinalizeWillStepCtaClicked({
        user_id: userId,
        step_name: stepName,
        key: "download",
      }),
    );
    if (doesUserUnderstandWillExecution) {
      dispatch(downloadDocuments("userWill", false, isSpouse));
    } else {
      dispatch(displayModal(MODAL_SIGN_AND_WITNESS));
    }
  }, [doesUserUnderstandWillExecution, dispatch, isSpouse]);

  return (
    <FinalizeWillStepAccordion
      label={title}
      onCheck={onCheck}
      defaultChecked={!!completedAt}
      userId={userId}
      stepName={stepName}
    >
      <Body color="textSecondary" dangerouslySetInnerHTML={{ __html: body }} />
      <Box mt={1} mb={1}>
        <SecondaryButton
          text={cta}
          onClick={handleClick}
          fullWidth
          showDownloadIconLeft
        />
      </Box>
    </FinalizeWillStepAccordion>
  );
};

export default DownloadAndReview;
