import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { Collapse, Divider, styled } from "@material-ui/core";
import { ArrowBlueIcon } from "./icons/ArrowIcons";
import { animationSpeed } from "../../config/Theme";
import { H4 } from "./Typography";
import CheckboxNoLabel from "./inputs/CheckboxNoLabel";
import { isValidSelectKey } from "../../selectors/utils/validate";
import { useDispatch } from "react-redux";
import { analyticsFinalizeWillStepInteracted } from "../../actions/analytics";

const AccordionContainer = styled(Box)({
  position: "relative",
  bottom: "7px",
  cursor: "pointer",

  "& svg": {
    position: "relative",
    top: "2px",
  },
});

const AccordionRow = ({
  label,
  collapsed,
  onClick,
  onFocus,
  onMouseDown,
}: AccordionContainerProps) => (
  <AccordionContainer
    tabIndex={0}
    flexGrow={1}
    onClick={onClick}
    onFocus={onFocus}
    onMouseDown={onMouseDown}
  >
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <H4 bold>{label}</H4>
      <Box ml={0.5}>
        <ArrowBlueIcon up={collapsed} />
      </Box>
    </Box>
  </AccordionContainer>
);

const FinalizeWillStepAccordion = ({
  id,
  children,
  label = "",
  defaultChecked = false,
  onCheck,
  onToggle,
  noBottomDivider = false,
  userId = 0,
  stepName = "",
}: FinalizeWillStepAccordionProps) => {
  const [checked, setChecked] = useState(defaultChecked);
  const [collapsed, setCollapsed] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    setChecked(defaultChecked);
  }, [defaultChecked]);

  const handleCollapse = (isFocusing: boolean = false) => {
    // make it more accessible by not collapsing if a user is
    // focusing on the accordion and it is already exapanded
    let interaction = "close_accordion";
    if (collapsed) {
      interaction = "open_accordion";
    }
    dispatch(
      analyticsFinalizeWillStepInteracted({
        user_id: userId,
        step_name: stepName,
        interaction_action: interaction,
      }),
    );
    if (isFocusing && !collapsed) {
      return;
    }
    setCollapsed(!collapsed);
    onToggle?.();
  };

  const handleCheckboxChange = () => {
    const newValue = !checked;
    setChecked(newValue);
    onCheck?.(newValue);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (isValidSelectKey(e.key)) {
      handleCheckboxChange();
    }
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
  };

  const handleClick = () => {
    handleCollapse();
  };

  const handleFocus = () => {
    handleCollapse(true);
  };

  return (
    <Box mb={1} width="100%">
      <Box display="flex" alignItems="flex-start">
        <Box display="flex" alignItems="center" width="100%">
          <Box
            pr={1.5}
            pt={0.5}
            pb={1}
            onMouseDown={handleCheckboxChange}
            onKeyDown={(e) => onKeyDownHandler(e)}
            style={{ cursor: "pointer" }}
          >
            <CheckboxNoLabel
              id={id || label || "finalize-will-step-checkbox"}
              checked={checked}
            />
          </Box>
          <AccordionRow
            label={label}
            collapsed={collapsed}
            onMouseDown={handleMouseDown}
            onClick={handleClick}
            onFocus={handleFocus}
          />
        </Box>
      </Box>
      <Collapse in={!collapsed} timeout={animationSpeed.fast}>
        <Box pl={3}>{children}</Box>
      </Collapse>
      <Box>{!noBottomDivider && <Divider />}</Box>
    </Box>
  );
};

export default FinalizeWillStepAccordion;
