import React from "react";
import styled from "styled-components";
import { Box } from "@material-ui/core";
import { ButtonLink } from "./Button";
import { FINALIZE_WILL_PATH } from "../../constants/routes";

const FinalizeMyWillWrapper = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const FinalizeMyWillButton = ({
  text,
  hasMargin,
  className = "",
  fullWidth,
}) => {
  const finalizeWillClass = className || "qa-finalize-my-will";

  return (
    <FinalizeMyWillWrapper mr={hasMargin && 1.5} mb={1.5}>
      <ButtonLink
        text={text}
        displayArrowRight
        className={finalizeWillClass}
        fullWidth={fullWidth}
        to={FINALIZE_WILL_PATH}
      />
    </FinalizeMyWillWrapper>
  );
};

export default FinalizeMyWillButton;
