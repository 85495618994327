import React from "react";

import { Box } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { H4, SmallBody } from "../Typography";
import WelcomeBackProductIcon from "../icons/WelcomeBackProductIcon";
import NextSectionButton from "./NextSectionButton";
import DigitalExecutionWrapper from "../../addons/digital-execution/DigitalExecutionWrapper";
import FinalizeMyWillButton from "../FinalizeMyWillButton";

const PremiumProductCard = ({
  translations,
  isPlanComplete,
  nextOverviewPath,
  hasPaid,
}) => {
  const theme = useTheme();
  const isLargeDesktop = useMediaQuery(theme.breakpoints.only("xl"));
  const isDesktop = useMediaQuery(theme.breakpoints.only("lg"));
  const iconWidth = isLargeDesktop || isDesktop ? "100px" : "72px";
  const showFinalizeWillButton = isPlanComplete && hasPaid;

  return (
    <Box
      borderRadius="4px"
      borderColor="border.main"
      border={1}
      bgcolor="white"
      p={1.5}
    >
      <Box display="flex">
        <Box flexGrow={1}>
          <Box mb={1}>
            <H4 align="left">{translations.title}</H4>
          </Box>
          <Box mb={2}>
            <SmallBody>{translations.smallBody}</SmallBody>
          </Box>
          {showFinalizeWillButton && (
            <FinalizeMyWillButton text={translations.button.finalizeMyWill} />
          )}
          <Box>
            <NextSectionButton
              isPlanComplete={isPlanComplete}
              nextOverviewPath={nextOverviewPath}
              translations={translations}
              hasPaid={hasPaid}
            />
          </Box>

          <DigitalExecutionWrapper fullWidth={false} />
        </Box>
        <Box flexShrink flexBasis={iconWidth} align="right">
          <Box ml={1} align="right">
            <WelcomeBackProductIcon width={iconWidth} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PremiumProductCard;
