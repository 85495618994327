import React, { useState } from "react";
import R from "ramda";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import PlusIcon from "./icons/PlusIcon";

import { H4, Body, SectionTitle } from "./Typography";
import { ArrowBlueUpIcon } from "./icons/ArrowIcons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    alignItems: "end",
  },
  accordion: {
    marginBottom: (props) => (props.isMobile ? "8px" : "16px"),
    borderRadius: "4px",
    position: "initial",
    border: `1px solid ${theme.palette.border.main}`,
    boxShadow: "none",
    "&:hover": {
      border: `1px solid ${theme.palette.willfulBlue.main}`,
    },
    "&$expanded": {
      border: `2px solid ${theme.palette.willfulBlue.main}`,
    },
  },
  accordionSummary: {
    padding: "12px 24px",
  },
  accordionDetails: {
    paddingTop: "0",
  },
  accordionDetailsRoot: {
    padding: "0px 24px 24px",
  },
  expandIcon: {
    padding: "12px",
    alignSelf: "baseline",
    marginTop: "6px",
    "&$expanded": {
      transform: "rotate(45deg)",
    },
  },
  expandArrowIcon: {
    padding: "12px",
    alignSelf: "baseline",
    marginTop: "6px",
    "&$expanded": {
      transform: "rotate(180deg)",
    },
  },
  expanded: {},
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
}));

export const BaseAccordion = ({
  translations,
  scrollToId,
  isMobile = false,
}) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles({ isMobile });
  const questions = R.pathOr([], ["questions"])(translations);
  const finalizeFaqs = translations;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      {translations.accordionTitle && (
        <Box mb={2}>
          <SectionTitle id={scrollToId} component="h2">
            {translations.accordionTitle}
          </SectionTitle>
        </Box>
      )}
      {questions.map(({ listItemHeader, listItemDescription }, index) => {
        return (
          <Accordion
            key={listItemDescription}
            square={false}
            classes={{ root: classes.accordion, expanded: classes.expanded }}
            expanded={expanded === `panel${index + 1}`}
            onChange={handleChange(`panel${index + 1}`)}
            mb={isMobile ? "8px" : "16px"}
          >
            <AccordionSummary
              expandIcon={<PlusIcon />}
              aria-controls={`panel${index + 1}a-content`}
              id={`panel${index + 1}a-header`}
              classes={{
                content: classes.content,
                expandIcon: classes.expandIcon,
                expanded: classes.expanded,
                root: classes.accordionSummary,
              }}
              className={classes.accordionSummary}
            >
              <H4 align="left">{listItemHeader}</H4>
            </AccordionSummary>
            <AccordionDetails
              classes={{
                root: classes.accordionDetailsRoot,
              }}
            >
              <Body>{listItemDescription}</Body>
            </AccordionDetails>
          </Accordion>
        );
      })}
      {/* FAQ to the Finalization Page */}
      {finalizeFaqs &&
        finalizeFaqs.length > 0 &&
        finalizeFaqs.map(({ title, body }, index) => {
          return (
            <Accordion
              key={title}
              square={false}
              classes={{ root: classes.accordion, expanded: classes.expanded }}
              expanded={expanded === `faqPanel${index + 1}`}
              onChange={handleChange(`faqPanel${index + 1}`)}
              mb={isMobile ? "8px" : "16px"}
            >
              <AccordionSummary
                expandIcon={<ArrowBlueUpIcon />}
                aria-controls={`faqPanel${index + 1}a-content`}
                id={`faqPanel${index + 1}a-header`}
                classes={{
                  content: classes.content,
                  expandIcon: classes.expandArrowIcon,
                  expanded: classes.expanded,
                  root: classes.accordionSummary,
                }}
                className={classes.accordionSummary}
              >
                <H4 align="left">{title}</H4>
              </AccordionSummary>
              <AccordionDetails
                classes={{
                  root: classes.accordionDetailsRoot,
                }}
              >
                <Body dangerouslySetInnerHTML={{ __html: body }} />
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
};
