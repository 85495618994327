import R from "ramda";
import { select, takeEvery, call, put, all } from "redux-saga/effects";
import { stopSubmit, change, untouch } from "redux-form";
import {
  USER_AGREED_TO_TOS_TYPE,
  UPDATE_USER_TYPE,
  USER_UNDERSTANDS_WILL_EXECUTION_TYPE,
} from "../actions/user";
import { submitFormRequest } from "./forms";
import {
  submitAgreedToTosRequest,
  submitUserRequest,
  submitUnderstandsWillRequest,
} from "../api/user";
import { selectIsNewEmailBeingEntered } from "../selectors/your-account";
import { selectTranslations } from "../selectors/translations";
import {
  UPDATE_EMAIL_FORM_ID,
  EMAIL_FIELD_ID,
  CONFIRM_EMAIL_FIELD_ID,
  CONFIRM_CURRENT_PASSWORD_FIELD_ID,
  AGREED_TO_TOS_FORM_ID,
  UNDERSTANDS_WILL_EXECUTION_FORM_ID,
} from "../constants/forms";
import { downloadDocuments } from "../actions/documents";
import { USER_WILL } from "../constants/documents";
import { closeModal } from "../actions/modal";
import { analyticsUnderstandsExecutionRequirements } from "../actions/analytics";

function* handleUpdateEmailFormError() {
  const errorTranslations = yield select(
    selectTranslations(["global", "errors"]),
  );
  const errorObject = {
    [EMAIL_FIELD_ID]: errorTranslations["updateUser.sameEmail"],
  };
  yield put(stopSubmit(UPDATE_EMAIL_FORM_ID, errorObject));
}

function* updateUser({ payload }) {
  const { formId, values } = payload;
  const isEmailSame = yield select(selectIsNewEmailBeingEntered);

  if (formId === UPDATE_EMAIL_FORM_ID && !isEmailSame) {
    return yield call(handleUpdateEmailFormError);
  }

  const response = yield call(submitFormRequest, {
    apiCall: submitUserRequest,
    formId,
    values,
  });

  if (formId === UPDATE_EMAIL_FORM_ID) {
    if (!R.pathOr(null, ["errors", "confirmCurrentPassword"])(response)) {
      yield put(change(formId, CONFIRM_CURRENT_PASSWORD_FIELD_ID, ""));
      yield put(untouch(formId, CONFIRM_CURRENT_PASSWORD_FIELD_ID, ""));
      yield put(change(formId, CONFIRM_EMAIL_FIELD_ID, ""));
      yield put(untouch(formId, CONFIRM_EMAIL_FIELD_ID, ""));
    }
  }

  return null;
}

function* handleAgreedToTos({ payload }) {
  const { continueLink } = payload;
  yield call(submitFormRequest, {
    apiCall: submitAgreedToTosRequest,
    formId: AGREED_TO_TOS_FORM_ID,
    values: {
      continueLink,
    },
  });
}

function* handleUnderstandsWill() {
  yield call(submitFormRequest, {
    apiCall: submitUnderstandsWillRequest,
    formId: UNDERSTANDS_WILL_EXECUTION_FORM_ID,
  });
  yield put(
    analyticsUnderstandsExecutionRequirements({
      document: "Will",
    }),
  );
  yield put(closeModal());
  yield put(downloadDocuments(USER_WILL, false, false));
}

export function* watchUpdateUser() {
  yield takeEvery(UPDATE_USER_TYPE, updateUser);
}

export function* watchUpdateAgreedToTos() {
  yield takeEvery(USER_AGREED_TO_TOS_TYPE, handleAgreedToTos);
}

export function* watchUpdateUnderstandsWill() {
  yield takeEvery(USER_UNDERSTANDS_WILL_EXECUTION_TYPE, handleUnderstandsWill);
}

export default function* watchUserSagas() {
  yield all([
    watchUpdateUser(),
    watchUpdateAgreedToTos(),
    watchUpdateUnderstandsWill(),
  ]);
}
