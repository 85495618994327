export const getWillFinalizationStepAction = () => ({
  type: GET_WILL_FINALIZATION_STEP,
});

export const getWillFinalizationStepSuccessAction = (
  payload: WillFinalizationStep,
) => ({
  type: GET_WILL_FINALIZATION_STEP_SUCCESS,
  payload,
});

export const updateWillFinalizationStepAction = (
  stepKey: string,
  isChecked: boolean,
) => ({
  type: UPDATE_WILL_FINALIZATION_STEP,
  payload: { [stepKey]: isChecked },
});

export const GET_WILL_FINALIZATION_STEP = "GET_WILL_FINALIZATION_STEP";
export const GET_WILL_FINALIZATION_STEP_SUCCESS =
  "GET_WILL_FINALIZATION_STEP_SUCCESS";
export const UPDATE_WILL_FINALIZATION_STEP = "UPDATE_WILL_FINALIZATION_STEP";
