import { UPDATE_STATUS_TYPE } from "../actions/status";
import {
  GET_WILL_FINALIZATION_STEP,
  GET_WILL_FINALIZATION_STEP_SUCCESS,
  UPDATE_WILL_FINALIZATION_STEP,
} from "../actions/will-finalization-step";

const initialState = {};

const willFinalizationStep = (state = initialState, action) => {
  switch (action.type) {
    case GET_WILL_FINALIZATION_STEP:
    case UPDATE_WILL_FINALIZATION_STEP:
      return {
        ...state,
      };
    case GET_WILL_FINALIZATION_STEP_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case UPDATE_STATUS_TYPE:
      return {
        ...state,
        userId: action.payload.user.id,
      };
    default:
      return state;
  }
};

export default willFinalizationStep;
