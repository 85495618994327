import React, { useContext, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import { selectTranslations } from "../../selectors/translations";
import { MODAL_SIGN_AND_WITNESS } from "../../constants/modal";
import { Body } from "../ui/Typography";
import ModalContext from "../../contexts/ModalContext";
import { PrimaryButton } from "../ui/Button";
import { understandsWillExecution } from "../../actions/user";

const ListBody = styled(Body)`
  ul {
    padding-inline-start: 30px;
  }
  ol {
    padding-inline-start: 30px;
  }
`;

export const SignAndWitnessModal = () => {
  const { setMobileDrawer } = useContext(ModalContext);
  const translations = useSelector(selectTranslations(["global", "notice"]))[
    MODAL_SIGN_AND_WITNESS
  ];
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setMobileDrawer(true);
  }, [setMobileDrawer]);

  const clickAction = useCallback(() => {
    dispatch(understandsWillExecution());
  }, [dispatch]);

  return (
    <div id="modal--sign-and-witness">
      <ListBody mt={2}>
        <p>
          <strong>{translations.boldText}</strong>{" "}
          <span>{translations.attachedText}</span>
        </p>
        <ol>
          <li key={translations.stepOne}>{translations.stepOne}</li>
          <li key={translations.stepTwo}>{translations.stepTwo}</li>
        </ol>
        <p>{translations.description}</p>
        <ul>
          <li key={translations.bulletOne}>{translations.bulletOne}</li>
          <li key={translations.bulletTwo}>{translations.bulletTwo}</li>
        </ul>
        <p>{translations.endText}</p>
      </ListBody>
      <Box mt={isTabletDown ? 8 : 1}>
        <PrimaryButton
          fullWidth
          displayArrowRight
          text={translations.button}
          onClick={clickAction}
        />
      </Box>
    </div>
  );
};
