import { all, takeEvery, call, put } from "redux-saga/effects";

import { submitPromoCode } from "../api/checkout";

import { PROMO_CODE_FORM_ID } from "../constants/forms";
import { PROMOTIONAL_BANNER_CHECKOUT_TYPE } from "../actions/banners";

import { submitFormRequest } from "./forms";
import { updateCart } from "../actions/cart";
import { fetchStripeClientSecret } from "./checkout";
import { fetchCheckout } from "../actions/checkout";

function* handlePromotionalBannerCheckout({ payload }) {
  const { promoCode } = payload;

  const { order, errors } = yield call(submitFormRequest, {
    apiCall: submitPromoCode,
    formId: PROMO_CODE_FORM_ID,
    values: { promoCode },
  });

  yield put(updateCart(order));

  if (!errors) {
    yield call(fetchStripeClientSecret);
    yield call(fetchCheckout, {});
  }

  return yield;
}

function* watchPromotionalBannerSaga() {
  yield takeEvery(
    PROMOTIONAL_BANNER_CHECKOUT_TYPE,
    handlePromotionalBannerCheckout,
  );
}

export default function* watchBannersSaga() {
  yield all([watchPromotionalBannerSaga()]);
}
