import React from "react";
import Box from "@material-ui/core/Box";

const WhiteCard = ({
  children,
  isDesktop,
  includeBottomMargin = true,
  fullHeight = false,
  displayFlex = false,
  spaceVerticalContentEvenly = false,
  ...restOfProps
}: WhiteCardProps) => (
  <Box
    bgcolor="willfulWhite.main"
    border={1}
    borderRadius={4}
    borderColor="border.main"
    p={isDesktop ? 2 : 1.5}
    mb={includeBottomMargin && 2}
    height={fullHeight ? "100%" : "auto"}
    display={displayFlex ? "flex" : "block"}
    flexDirection={spaceVerticalContentEvenly ? "column" : "row"}
    justifyContent={spaceVerticalContentEvenly && "space-between"}
    {...restOfProps}
  >
    {children}
  </Box>
);

export default WhiteCard;
