export const fetchPlans = (formId) => ({
  type: FETCH_PLANS_TYPE,
  payload: { formId },
});
export const updatePlans = ({ symbol, quantity, formId }) => ({
  type: UPDATE_PLANS_TYPE,
  payload: {
    symbol,
    quantity: quantity || 1,
    formId,
  },
});

export const upgradePlan = ({ quantity, previousQuantity }) => ({
  type: UPGRADE_PLAN_TYPE,
  payload: {
    quantity,
    previousQuantity,
  },
});

export const addPlans = (plans) => ({
  type: ADD_PLANS_TYPE,
  payload: { plans },
});

export const setLoadingPlan = (plan) => ({
  type: SET_LOADING_PLAN_TYPE,
  payload: {
    plan,
  },
});

export const expandCollapseTableRow = ({ key, expanded }) => ({
  type: EXPAND_COLLAPSE_TABLE_ROW_TYPE,
  payload: {
    key,
    expanded,
  },
});

export const FETCH_PLANS_TYPE = "FETCH_PLANS";
export const UPDATE_PLANS_TYPE = "UPDATE_PLANS";
export const UPGRADE_PLAN_TYPE = "UPGRADE_PLAN";
export const ADD_PLANS_TYPE = "ADD_PLANS";
export const SET_LOADING_PLAN_TYPE = "SET_LOADING_PLAN";
export const EXPAND_COLLAPSE_TABLE_ROW_TYPE = "EXPAND_COLLAPSE_TABLE_ROW";
