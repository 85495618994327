import React, { useEffect } from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import { H1, LargeBody, H3 } from "../../components/ui/Typography";
import { TextButton } from "../../components/ui/Button";
import { goBack } from "connected-react-router";

import { useDispatch, useSelector } from "react-redux";
import { selectTranslations } from "../../selectors/translations";
import FinalizePageIcon from "../../components/ui/icons/FinalizePageIcon";
import { BaseAccordion } from "../../components/ui/Accordion";
import DownloadAndReview from "../../components/finalize-will/DownloadAndReview";
import Print from "../../components/finalize-will/Print";
import IdentifyWitness from "../../components/finalize-will/IdentifyWitness";
import SignAndWitness from "../../components/finalize-will/SignAndWitness";
import { PageWrapper } from "../Layout";
import { selectUserProvinceByCode } from "../../selectors/provinces";
import {
  getWillFinalizationStepAction,
  updateWillFinalizationStepAction,
} from "../../actions/will-finalization-step";
import {
  selectWillFinalizationStep,
  selectWillFinalizationUserId,
} from "../../selectors/will-finalization-step";
import { analyticsFinalizeWillCheckbox } from "../../actions/analytics";

const FinalizeWillPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const provinceCode = useSelector(selectUserProvinceByCode);
  const willFinalizationStep = useSelector(selectWillFinalizationStep);
  const userId = useSelector(selectWillFinalizationUserId);
  const updatedAt = new Date().toISOString();

  const handleStepCheck = (stepKey: string) => (isChecked: boolean) => {
    dispatch(updateWillFinalizationStepAction(stepKey, isChecked));
    dispatch(
      analyticsFinalizeWillCheckbox({
        userId,
        stepName: stepKey,
        stepStatus: isChecked,
        updatedAt,
      }),
    );
  };

  useEffect(() => {
    dispatch(getWillFinalizationStepAction());
  }, [dispatch]);

  const translations: TranslationsType["finalize"] = useSelector(
    selectTranslations(["finalize", "button"]),
  );
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <PageWrapper flexGrow={1}>
      <Box mt={2} width={"100%"}>
        <TextButton
          text={translations.previousButton}
          onClick={() => dispatch(goBack())}
          displayArrowLeft
          isLoading={false}
          loadingLabel="Previous"
          color="primary"
        />
      </Box>

      <Box mt={isDesktop && 2} mb={2}>
        <Grid container>
          {/* Left grid */}
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <Box>
              <H3 align="center" style={styles.h3}>
                {translations.sectionTitle.toUpperCase()}
              </H3>
              <H1 align="center" data-hj-suppress fontSize="48px">
                {translations.heading}
              </H1>
              <LargeBody align="center"> {translations.body}</LargeBody>
            </Box>
            {/* Finalize Steps Accordions start */}
            <Box mt={2}>
              <DownloadAndReview
                translation={translations.steps.downloadAndReview}
                completedAt={willFinalizationStep.downloadedAndReviewedAt}
                onCheck={handleStepCheck("downloadedAndReviewedAt")}
              />
              <Print
                translation={translations.steps.print}
                completedAt={willFinalizationStep.printedDocumentAt}
                onCheck={handleStepCheck("printedDocumentAt")}
              />
              <IdentifyWitness
                translation={translations.steps.identifyWitnesses}
                completedAt={willFinalizationStep.identifiedWitnessesAt}
                onCheck={handleStepCheck("identifiedWitnessesAt")}
              />
              <SignAndWitness
                translation={translations.steps.signAndWitness}
                provinceCode={provinceCode}
                completedAt={willFinalizationStep.signedAndWitnessedAt}
                onCheck={handleStepCheck("signedAndWitnessedAt")}
              />
            </Box>
            {/* Finalize Steps Accordions ends */}
            {/* FAQ start */}
            <Box>
              <H3 align="center" style={styles.h3}>
                {translations.faqTitle.toUpperCase()}
              </H3>
              <BaseAccordion
                scrollToId="faq"
                translations={translations.faqSections}
              />
            </Box>
            {/* FAQ END */}
          </Grid>
          {/* Right grid */}
          {isDesktop && (
            <Grid item xl={4} lg={4} md={4} container justifyContent="center">
              <FinalizePageIcon />
            </Grid>
          )}
        </Grid>
      </Box>
    </PageWrapper>
  );
};

const styles = {
  h3: {
    fontSize: 16,
    marginBottom: 32,
    marginTop: 32,
    letterSpacing: 2.8,
  },
};

export default FinalizeWillPage;
