import React from "react";

import SvgIcon from "./SvgIcon";

const ArrowDownloadIcon = () => {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.1667 10C19.1667 15.0625 15.0625 19.1667 10 19.1667C4.93751 19.1667 0.833355 15.0625 0.833356 10C0.833356 4.93749 4.93751 0.833338 10 0.833339C15.0625 0.833339 19.1667 4.9375 19.1667 10ZM10 17.5C14.1421 17.5 17.5 14.142 17.5 10C17.5 5.85797 14.1421 2.50001 10 2.50001C5.85799 2.50001 2.50002 5.85797 2.50002 10C2.50002 14.142 5.85799 17.5 10 17.5ZM10 14.9664L5.62289 10.5893L6.8014 9.41075L9.16669 11.776L9.16669 5.45455L10.8334 5.45455L10.8334 11.776L13.1986 9.41075L14.3772 10.5893L10 14.9664Z"
        fill="#0064DC"
      />
    </SvgIcon>
  );
};

export default ArrowDownloadIcon;
