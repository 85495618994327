import React from "react";

import { PopupModal } from "react-calendly";
import { useDispatch, useSelector } from "react-redux";
import { toggleCalendlyModalAction } from "../../actions/calendly";
import { selectCalendlyModalData } from "../../selectors/calendly";

export default function CalendlyModal() {
  const dispatch = useDispatch();
  const data = useSelector(selectCalendlyModalData);

  function handleModalClose() {
    dispatch(toggleCalendlyModalAction({ open: false, url: data.url }));
  }

  return (
    <PopupModal
      onModalClose={handleModalClose}
      open={data.open}
      rootElement={document.getElementById("root")}
      url={data.url}
    />
  );
}
