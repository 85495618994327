export const togglePartnerBanner = (visible) => ({
  type: TOGGLE_PARTNER_BANNER_TYPE,
  payload: {
    visible,
  },
});

export const promotionalBannerCheckoutAction = (promoCode) => ({
  type: PROMOTIONAL_BANNER_CHECKOUT_TYPE,
  payload: { promoCode },
});

export const TOGGLE_PARTNER_BANNER_TYPE = "TOGGLE_PARTNER_BANNER";
export const PROMOTIONAL_BANNER_CHECKOUT_TYPE = "PROMOTIONAL_BANNER_CHECKOUT";
