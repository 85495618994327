import React from "react";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { Body } from "./Typography";
import {
  selectContactBadgesTranslations,
  selectContactRolesTranslations,
} from "../../selectors/contacts";
import { SecondaryButton, SmallTextButton } from "./Button";
import { selectGlobalNavigationTranslations } from "../../selectors/navigation";
import { colorPalette } from "../../config/Theme";
import { getContactBadgeKey } from "./utils/contacts";
import {
  notifyContact,
  openModalContact,
  updateLastSelectedContact,
} from "../../actions/contacts";
import { buildFullName } from "../../utilities/name";
import { MODAL_ADD_CONTACT } from "../../constants/modal";
import { WITNESS_CONTACTABLE_MODEL } from "../../constants/contacts";

import { featureContactsEmailEnabled } from "../../utilities/featureFlags";

const FamilyBadge = ({ person }: { person: ContactPayload }) => {
  const badges = useSelector(selectContactBadgesTranslations);
  const badgeStyles = {
    backgroundColor: colorPalette.willfulxxxLightBlue,
    color: colorPalette.willfulBlue,
    padding: "4px 16px",
    marginLeft: "8px",
    minWidth: "fit-content",
    maxHeight: "fit-content",
    borderRadius: "4px",
  };
  const badgeKey = getContactBadgeKey(person);

  return <div style={badgeStyles}>{badges[badgeKey]}</div>;
};

const ContactCardRoles = ({ roles }: { roles: string[] }) => {
  const rolesTranslations = useSelector(selectContactRolesTranslations);
  const roleStyles = {
    color: colorPalette.willfulGrey1,
  };

  return roles.map((role: string, index: number) => {
    const isNotFirst = index !== 0;
    return (
      <span style={roleStyles} key={role}>
        {isNotFirst ? ` | ${rolesTranslations[role]}` : rolesTranslations[role]}{" "}
      </span>
    );
  });
};

interface EmailContactButtonProps {
  person: ContactPayload;
  hasRoles: boolean;
  navigation: {
    notify: string;
    addEmailNotify: string;
  };
  openContactsModal: () => {
    payload: { modalKey: string; contact: ContactModalData };
    type: string;
  };
}

const EmailContactButton = ({
  person,
  hasRoles,
  navigation,
  openContactsModal,
}: EmailContactButtonProps) => {
  const dispatch = useDispatch();

  const isWitness =
    person.roles.length === 1 &&
    person.roles.includes(WITNESS_CONTACTABLE_MODEL);

  if (person.isMinorChild || !hasRoles || isWitness) return null;

  const { firstName, lastName, email, id } = person.contact;

  const hasEmail = !!email;
  const shouldDisplayNotify = hasRoles && hasEmail;

  return shouldDisplayNotify ? (
    <Box>
      <SecondaryButton
        onClick={() => {
          dispatch(updateLastSelectedContact(person.contact));
          dispatch(
            notifyContact({
              email,
              id,
              firstName,
              lastName,
              roles: person.roles,
            }),
          );
        }}
        text={navigation.notify}
      />
    </Box>
  ) : (
    <Box>
      <SecondaryButton
        onClick={openContactsModal}
        text={navigation.addEmailNotify}
        className="qa-add-email-button"
      />
    </Box>
  );
};

interface ContactCardType {
  person: ContactPayload;
  showBadge?: boolean;
}

export const ContactCard = ({ person, showBadge = false }: ContactCardType) => {
  const dispatch = useDispatch();
  const navigation = useSelector(selectGlobalNavigationTranslations);
  const { firstName, middleName, lastName } = person.contact;
  const fullName = buildFullName(firstName, middleName, lastName);
  const hasRoles = !!person.roles.length;
  const contactInfoForModal = {
    ...person.contact,
    hasRoles,
    isMinorChild: person.isMinorChild,
  };

  const openContactsModal = () =>
    dispatch(openModalContact(contactInfoForModal, MODAL_ADD_CONTACT));

  return (
    <Box
      bgcolor="willfulWhite.main"
      border={1}
      borderRadius={4}
      borderColor="border.main"
      padding={1.5}
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <Box>
        <Box display="flex" justifyContent="space-between">
          <Body component="span" bold>
            {fullName}
          </Body>
          {showBadge && <FamilyBadge person={person} />}
        </Box>
        <Box pt={1.5}>
          {hasRoles && <ContactCardRoles roles={person.roles} />}
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between" pt={1.5}>
        <Box display="flex">
          <SmallTextButton
            onClick={openContactsModal}
            text={navigation.edit}
            includeHorizontalSpacing
            className="qa-edit-contact"
          />
          <SmallTextButton
            onClick={() =>
              dispatch(
                openModalContact(
                  { ...contactInfoForModal, isDestroy: true },
                  MODAL_ADD_CONTACT,
                ),
              )
            }
            text={navigation.remove}
            includeHorizontalSpacing
            className="qa-remove-contact"
          />
        </Box>

        {featureContactsEmailEnabled() && (
          <EmailContactButton
            person={person}
            navigation={navigation}
            hasRoles={hasRoles}
            openContactsModal={openContactsModal}
          />
        )}
      </Box>
    </Box>
  );
};
