import React from "react";
import { reduxForm, Field } from "redux-form";
import Box from "@material-ui/core/Box";

import { Form } from "../../ui/Form";
import TextInput from "../../ui/inputs/TextInput";
import DropdownSelect from "../../ui/inputs/DropdownSelect";
import PhoneNumberInputField from "../../ui/inputs/PhoneNumberInputField";
import { InfoBox } from "../../ui/InfoBox";
import {
  useValidateProvinceMemo,
  useValidateCityMemo,
  useValidateLockedProvinceLead,
} from "../../ui/inputs/validations";
import {
  RESIDENCE_FORM_ID,
  PROVINCE_FIELD_ID,
  RESIDENCE_FIELD_ID,
  LOCKED_PROVINCE_LEAD_FIELD_ID,
} from "../../../constants/forms";
import { Body } from "../../ui/Typography";
import RadioListField from "../../ui/inputs/RadioListField";
import { useMediaQuery, useTheme } from "@material-ui/core";

const transformProvincesList = (provinces, translations) =>
  provinces.map((province) => {
    return {
      ...province,
      label: translations[province.code],
      value: province.id,
    };
  });

const ResidenceForm = ({
  handleSubmit,
  provinces,
  userEmail,
  isProvinceLocked,
  isNewBrunswickSelected,
  isLoading,
  translations,
  showFormTitle = false,
  showFormButtons = true,
  showSectionHeading = true,
  isMiniForm = false,
}) => {
  const provincesList = transformProvincesList(
    provinces,
    translations.constants.provinces,
  );

  const validateCity = useValidateCityMemo(translations);
  const validateLockedProvince = useValidateLockedProvinceLead(translations);

  const theme = useTheme();
  const isTabletDown = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Form
      isLoading={isLoading}
      onSubmit={handleSubmit}
      translations={translations}
      showFormButtons={showFormButtons}
      showSectionHeading={showSectionHeading}
      reverseWrap={isTabletDown}
      isMiniForm={isMiniForm}
    >
      {showFormTitle && <Body bold>{translations.formTitle}</Body>}
      <Box>
        <Field
          component={DropdownSelect}
          validate={useValidateProvinceMemo(translations)}
          label={translations.label.province}
          name={PROVINCE_FIELD_ID}
          optionList={provincesList}
          placeholder={translations.label.provincePlaceholder}
        />
      </Box>
      <Box>
        {isNewBrunswickSelected && (
          <Box mb={2}>
            <InfoBox
              paragraph={translations.powerOfAttorneyInfo.paragraph}
              linkText={translations.powerOfAttorneyInfo.linkText}
              externalLinkUrl={translations.powerOfAttorneyInfo.externalLinkUrl}
            />
          </Box>
        )}
        {isProvinceLocked && (
          <>
            <Box mb={2}>
              <InfoBox
                title={translations.lockedInfoBox.title}
                paragraph={translations.lockedInfoBox.paragraph}
              />
            </Box>
            <Box>
              <Body>
                {translations.lockedProvinceInfoPt1}
                <Body bold component="span">
                  {userEmail}
                </Body>
                {translations.lockedProvinceInfoPt2}
              </Body>
            </Box>
            <RadioListField
              name={LOCKED_PROVINCE_LEAD_FIELD_ID}
              validate={validateLockedProvince}
              optionList={translations.lockedOptionList}
            />
          </>
        )}
        {!isProvinceLocked && (
          <>
            <Field
              component={TextInput}
              validate={validateCity}
              label={translations.label.city}
              placeholder={translations.label.cityPlaceholder}
              name={RESIDENCE_FIELD_ID}
            />
            <PhoneNumberInputField translations={translations} />
          </>
        )}
      </Box>
    </Form>
  );
};

export default reduxForm({
  form: RESIDENCE_FORM_ID,
})(ResidenceForm);
