import React from "react";
import styled from "styled-components";
import CheckedBox from "../icons/checkbox-fill.svg";
import UncheckedBox from "../icons/checkbox-empty.svg";
import CheckboxError from "../icons/checkbox-error.svg";
import { colorPalette } from "../../../config/Theme";

const StyledCheckboxNoLabel = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;

  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  input {
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
`;

const CustomCheckbox = styled.div`
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &::before {
    content: url(${(props) => (props.hasError ? CheckboxError : UncheckedBox)});
    display: block;
  }

  input:checked + &::before {
    content: url(${CheckedBox});
  }

  input:focus-visible + & {
    outline: 1px solid ${colorPalette.willfulBlue};
    outline-offset: 2px;
    border-radius: 4px;
  }
`;

const CheckboxNoLabel = ({
  id,
  hasError = false,
  checked = false,
  disabled = false,
  onChange = () => {},
}) => {
  return (
    <StyledCheckboxNoLabel>
      <label htmlFor={id}>
        <input
          id={id}
          aria-label={id}
          type="checkbox"
          checked={checked}
          disabled={disabled}
          onChange={onChange}
        />
        <CustomCheckbox hasError={hasError} disabled={disabled} />
      </label>
    </StyledCheckboxNoLabel>
  );
};

export default CheckboxNoLabel;
