import R from "ramda";
import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { goBack, push } from "connected-react-router";
import { ElementsConsumer } from "@stripe/react-stripe-js";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

import { loadStripe } from "@stripe/stripe-js";
import {
  fetchCheckout,
  fetchStripeClientSecret,
  submitCheckout,
  updatePromoCode,
  validateCaaMembership,
  removeReferral,
  submitPartnerDiscountCheckout,
  applyPartnerDiscount,
  getStripeSessionStatus,
} from "../../../actions/checkout";
import { displayTooltipModal } from "../../../actions/tooltip-modal";
import { checkoutPageProps } from "../../../selectors/checkout";
import LoadingSpinner from "../../../components/ui/LoadingSpinner";
import { PROMO_CODE_FIELD_ID, CAA_FIELD_ID } from "../../../constants/forms";
import { TextButton } from "../../../components/ui/Button";
import { H1, LargeBody, MiceType } from "../../../components/ui/Typography";
import VolumePartnerDiscountReminderCard from "../../../components/ui/VolumePartnerDiscountReminderCard";
import RevsharePartnerDiscountReminderCard from "../../../components/ui/RevsharePartnerDiscountReminderCard";
import { colorPalette } from "../../../config/Theme";
import ThirtyDayGuarantee from "../../../components/ui/icons/ThirtyDayGuarantee";
import PaymentSummary from "../../../components/checkout/PaymentSummary/PaymentSummary";
import CheckoutForm from "../../../components/forms/checkout/CheckoutForm";
import { DASHBOARD_PATH } from "../../../constants/routes";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  item1: {
    order: 1,
    [theme.breakpoints.down("md")]: {
      order: 2,
    },
  },
  item2: {
    order: 2,
    [theme.breakpoints.down("md")]: {
      order: 1,
    },
  },
}));

const ThirtyDayGuaranteeWrapper = ({
  translations,
  isFrenchSelected,
  isMobile,
}) => {
  return (
    <>
      <Box ml={2} mt={2} id="trust-icons-container" display="flex">
        <Box pr={1}>
          <ThirtyDayGuarantee
            isFrench={isFrenchSelected}
            width={isFrenchSelected ? "180px" : "160px"}
          />
        </Box>
        <Box pr={1} pt={0.25} maxWidth={isMobile ? "400px" : "100%"}>
          <MiceType color="willfulGrey1">
            {translations.thirtyDayGuarantee}
          </MiceType>
        </Box>
      </Box>
    </>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.
const stripePromise = loadStripe(window.env.STRIPE_PUBLISHABLE_KEY);
const CheckoutPage = ({
  goBack,
  push,
  submitCheckout,
  updatePromoCode,
  validateCaaMembership,
  fetchCheckout,
  fetchStripeClientSecret,
  removeReferral,
  displayTooltipModal,
  submitPartnerDiscountCheckout,
  applyPartnerDiscount,
  getStripeSessionStatus,
  stripeClientSecret,
  stripe,
  elements,
  translations,
  checkoutInfo,
  checkoutForm,
  promoForm,
  caaForm,
  shouldDisplayCaaField,
  shouldDisplayPromoCodeField,
  hasLoaded,
  isFrenchSelected,
  isBundlePlanSelected,
  shouldDisplayPartnerDiscountConfirmCheckoutButton,
}) => {
  const [previousButtonLink, setPreviousButtonLink] = useState(() => goBack);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const gridStyling = useStyles(theme);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get("session_id");
    if (sessionId) {
      getStripeSessionStatus(sessionId);
      const backToDashboard = () => push(DASHBOARD_PATH);
      setPreviousButtonLink(() => backToDashboard);
    }
    fetchCheckout(sessionId);
    fetchStripeClientSecret();
  }, [fetchCheckout, fetchStripeClientSecret, getStripeSessionStatus, push]);

  if (!hasLoaded) return <LoadingSpinner />;

  const handleRemoveReferral = () => removeReferral();
  const handleCheckoutSubmit = () => submitCheckout({ stripe, elements });
  const handlePromoCodeSubmit = (value) => {
    const promoCode = R.propOr("", PROMO_CODE_FIELD_ID, value);
    if (promoCode.length <= 0) {
      throw new SubmissionError({
        _error: translations.validationErrors.noPromoCodeEntered,
      });
    }
    return updatePromoCode();
  };

  const handleCaaFormSubmit = (value) => {
    const caaCode = R.propOr("", CAA_FIELD_ID, value);
    if (caaCode.length <= 0) {
      throw new SubmissionError({
        _error: translations.validationErrors.noCaaCodeEntered,
      });
    }
    return validateCaaMembership();
  };

  const {
    hasVolumePartnerDiscount,
    hasRevsharePartnerDiscount,
    volumePartnerDiscountTranslations,
    revsharePartnerDiscountTranslations,
    isPartnerDiscountAppliedToCart,
  } = checkoutInfo;

  const subTitle = isBundlePlanSelected
    ? translations.subTitleBundle
    : translations.subTitle;

  const desktopMargin = isDesktop ? 2 : 0;

  return (
    <Box id="payment-page-main-container" pb={isDesktop ? 10 : 4}>
      {/* Page title + subtitle */}
      <Grid container>
        <Grid item sm={12} md={12} lg={12} xl={11}>
          <Box tabIndex={0} mt={isDesktop ? 5 : 1.25} mb={isDesktop ? 2.5 : 1}>
            {/* Title */}
            <H1 marginBottom="0px" align="left">
              {translations.pageTitle}
            </H1>
          </Box>
        </Grid>
        <Grid item sm={12} md={12} lg={6}>
          {/* Subtitle */}
          <LargeBody tabIndex={0} component="span" margin="0px" align="left">
            {subTitle}
          </LargeBody>
        </Grid>
      </Grid>

      <Grid container spacing={isDesktop ? 2 : 1}>
        {/* Left hand column */}
        <Grid item sm={12} md={12} lg={7} xs={12} className={gridStyling.item1}>
          {/* Promo banners DESKTOP */}
          {isDesktop && (
            <Box id="partner-discount-reminder-card- desktop">
              {hasVolumePartnerDiscount && (
                <Box mt={1} mb={desktopMargin}>
                  <VolumePartnerDiscountReminderCard
                    translations={volumePartnerDiscountTranslations}
                  />
                </Box>
              )}
              {hasRevsharePartnerDiscount && (
                <Box mt={1} mb={desktopMargin}>
                  <RevsharePartnerDiscountReminderCard
                    translations={revsharePartnerDiscountTranslations}
                    isPartnerDiscountAppliedToCart={
                      isPartnerDiscountAppliedToCart
                    }
                    handleApplyPartnerDiscount={applyPartnerDiscount}
                  />
                </Box>
              )}
            </Box>
          )}

          {/* Checkout Form for DESKTOP */}
          <Box mt={isDesktop ? 1 : 0}>
            <CheckoutForm
              onSubmit={handleCheckoutSubmit}
              shouldDisplayPartnerDiscountConfirmCheckoutButton={
                shouldDisplayPartnerDiscountConfirmCheckoutButton
              }
              submitPartnerDiscountCheckout={submitPartnerDiscountCheckout}
              stripeClientSecret={stripeClientSecret}
              stripePromise={stripePromise}
              {...checkoutForm}
            />
          </Box>

          {!isDesktop && (
            <>
              <ThirtyDayGuaranteeWrapper
                translations={translations}
                isFrenchSelected={isFrenchSelected}
                isMobile={isMobile}
              />
              <Box mt={1}>
                <TextButton
                  text={translations.button.previous}
                  color="primary"
                  onClick={previousButtonLink}
                  displayArrowLeft
                />
              </Box>
            </>
          )}

          <Hidden mdDown>
            <Box mt={2}>
              <TextButton
                text={translations.button.previous}
                color="primary"
                onClick={previousButtonLink}
                displayArrowLeft
              />
            </Box>
          </Hidden>
        </Grid>

        {/* Right hand column */}
        <Grid item xl={4} lg={5} sm={12} className={gridStyling.item2}>
          <Box borderRadius={4}>
            {/* Promo banners MOBILE */}
            {!isDesktop && (
              <Box id="partner-discount-reminder-card-mobile">
                {hasVolumePartnerDiscount && (
                  <Box mt={2} mb={desktopMargin}>
                    <VolumePartnerDiscountReminderCard
                      translations={volumePartnerDiscountTranslations}
                    />
                  </Box>
                )}
                {hasRevsharePartnerDiscount && (
                  <Box mt={2} mb={desktopMargin}>
                    <RevsharePartnerDiscountReminderCard
                      translations={revsharePartnerDiscountTranslations}
                      isPartnerDiscountAppliedToCart={
                        isPartnerDiscountAppliedToCart
                      }
                      handleApplyPartnerDiscount={applyPartnerDiscount}
                    />
                  </Box>
                )}
              </Box>
            )}

            {/* Checkout Summary */}
            <Box
              id="summary-container"
              mt={1}
              p={2}
              bgcolor={colorPalette.white}
              borderRadius={4}
              border={`1px solid ${colorPalette.willfulGrey2}`}
            >
              <PaymentSummary
                handleRemoveReferral={handleRemoveReferral}
                shouldDisplayPromoCodeField={shouldDisplayPromoCodeField}
                handlePromoCodeSubmit={handlePromoCodeSubmit}
                promoForm={promoForm}
                shouldDisplayCaaField={shouldDisplayCaaField}
                handleCaaFormSubmit={handleCaaFormSubmit}
                caaForm={caaForm}
                displayTooltipModal={displayTooltipModal}
                isDesktop={isDesktop}
                {...checkoutInfo}
              />
            </Box>

            {isDesktop && (
              <ThirtyDayGuaranteeWrapper
                translations={translations}
                isFrenchSelected={isFrenchSelected}
                isMobile={isMobile}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const ConnectedCheckoutPage = connect(checkoutPageProps, {
  getStripeSessionStatus,
  submitCheckout,
  fetchCheckout,
  fetchStripeClientSecret,
  updatePromoCode,
  removeReferral,
  validateCaaMembership,
  displayTooltipModal,
  submitPartnerDiscountCheckout,
  applyPartnerDiscount,
  goBack,
  push,
})(CheckoutPage);

// InjectStripe needs to be wrapped within an <Elements> component hierarchy
// Initially tried InjectStripe HOC + Redux-Form HOC but couldn't manage to pass
// stripe object back through the submit method
const CheckoutWrapperUpdate = () => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <ConnectedCheckoutPage stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

export default CheckoutWrapperUpdate;
