import { createSelector } from "reselect";
import R from "ramda";
import { formatCurrency } from "../../utilities/helpers";
import { selectTranslations } from "../translations";
import { selectLanguageCode } from "../language";
import {
  REFERRAL_DISCOUNT,
  PROMO_DISCOUNT,
  CAA_DISCOUNT,
  UPGRADE_DISCOUNT,
  PARTNER_DISCOUNT,
} from "../../constants/plans";
import { selectPartnerDiscountPartnerName } from "../partner-discount";
import { selectTranslatedReferralDiscountAmount } from "../referral-program";

export const selectOrderDiscounts = (reducerKey) =>
  R.pathOr([], [reducerKey, "discounts"]);

export const selectOrderHasDiscount = (reducerKey) =>
  createSelector(
    selectOrderDiscounts(reducerKey),
    (discounts) => discounts.length > 0,
  );
export const selectOrderHasPromo = (reducerKey) =>
  createSelector(
    selectOrderDiscounts(reducerKey),
    (discounts) =>
      !!R.find(R.propEq("discountType", PROMO_DISCOUNT))(discounts),
  );
export const selectOrderHasCAA = (reducerKey) =>
  createSelector(
    selectOrderDiscounts(reducerKey),
    (discounts) => !!R.find(R.propEq("discountType", CAA_DISCOUNT))(discounts),
  );
export const selectOrderPromoCode = (reducerKey) =>
  createSelector(selectOrderDiscounts(reducerKey), (discounts) => {
    const promoDiscount = R.find(R.propEq("discountType", PROMO_DISCOUNT))(
      discounts,
    );
    if (!promoDiscount) return "";
    return promoDiscount.promoCode.code;
  });
export const selectOrderPartnerDiscount = (reducerKey) =>
  createSelector(selectOrderDiscounts(reducerKey), (discounts) => {
    const discount = R.find(R.propEq("discountType", PARTNER_DISCOUNT))(
      discounts,
    );
    if (!discount) return "";
    return discount.partnerDiscount.partnerName;
  });

export const selectOrderHasCaaMembership = (reducerKey) =>
  createSelector(
    selectOrderDiscounts(reducerKey),
    (discounts) => !!R.find(R.propEq("discountType", CAA_DISCOUNT))(discounts),
  );

export const selectOrderHasReferral = (reducerKey) =>
  createSelector(
    selectOrderDiscounts(reducerKey),
    (discounts) =>
      !!R.find(R.propEq("discountType", REFERRAL_DISCOUNT))(discounts),
  );

export const selectOrderHasPartnerDiscount = (reducerKey) =>
  createSelector(
    selectOrderDiscounts(reducerKey),
    (discounts) =>
      !!R.find(R.propEq("discountType", PARTNER_DISCOUNT))(discounts),
  );

export const selectDiscountLabel = createSelector(
  selectOrderPartnerDiscount("cart"),
  selectOrderHasReferral("cart"),
  selectOrderHasCAA("cart"),
  selectOrderHasPromo("cart"),
  selectPartnerDiscountPartnerName,
  selectOrderPromoCode("cart"),
  selectTranslations(["checkout"]),
  (
    hasPartnerDiscount,
    hasReferral,
    hasCaa,
    hasPromo,
    partnerName,
    promoCode,
    checkoutTranslations,
  ) => {
    if (hasPartnerDiscount) return partnerName;
    if (hasReferral) return checkoutTranslations.referral;
    if (hasCaa) return checkoutTranslations.caaDiscountLabel;
    if (hasPromo) return promoCode;
    return "";
  },
);

export const selectOrderDiscountsData = (reducerKey) =>
  createSelector(
    selectOrderHasDiscount(reducerKey),
    selectOrderDiscounts(reducerKey),
    selectTranslations(["global", "checkout"]),
    selectLanguageCode,
    selectTranslatedReferralDiscountAmount,
    (
      hasDiscount,
      discounts,
      translations,
      languageCode,
      referralDiscountAmount,
    ) => {
      if (!hasDiscount) {
        return "";
      }

      // [{label: translationKey, amount: discountValue, type, discountType}]
      const discountsList = [];

      // Map over discounts and build list of discount objects
      discounts.map((discount) => {
        const discountAmount = formatCurrency(discount.value, languageCode, 2);

        if (discount.discountType === UPGRADE_DISCOUNT) {
          discountsList.push({
            label: translations.upgradeDiscountLabel,
            amount: `-\u00a0${discountAmount}`,
            type: UPGRADE_DISCOUNT,
          });
        }
        if (discount.discountType === CAA_DISCOUNT) {
          discountsList.push({
            label: translations.partnerLabel,
            amount: `-\u00a0${discountAmount}`,
            type: CAA_DISCOUNT,
          });
        }
        if (discount.discountType === PROMO_DISCOUNT) {
          discountsList.push({
            label: translations.promoCodeLabel,
            amount: `-\u00a0${discountAmount}`,
            type: PROMO_DISCOUNT,
          });
        }
        if (discount.discountType === REFERRAL_DISCOUNT) {
          discountsList.push({
            label: translations.referralLabel.replace(
              "{DISCOUNT}",
              referralDiscountAmount,
            ),
            amount: `-\u00a0${discountAmount}`,
            type: REFERRAL_DISCOUNT,
          });
        }
        if (discount.discountType === PARTNER_DISCOUNT) {
          const label =
            discount.partnerDiscount.partnershipType === "volume"
              ? translations.partnerVolumeDiscountLabel
              : replaceDiscountAmountByDiscountType(
                  discount.partnerDiscount.discountType,
                  discount.partnerDiscount.discountAmount,
                  translations.partnerRevshareDiscountLabel,
                );
          discountsList.push({
            label: label.replace(
              "PARTNER_NAME",
              discount.partnerDiscount.partnerName,
            ),
            amount: `-\u00a0${discountAmount}`,
            type: PARTNER_DISCOUNT,
          });
        }
        return discountsList;
      });

      return discountsList;
    },
  );

// Used for analytics
export const selectOrderDiscountLabelApplied = (reducerKey) =>
  createSelector(
    selectOrderHasCaaMembership(reducerKey),
    selectOrderHasReferral(reducerKey),
    selectOrderPromoCode(reducerKey),
    (hasCaaMembership, hasReferral, promoCode) => {
      let discoundLabel = promoCode;
      if (hasCaaMembership) {
        discoundLabel = "CAA DISCOUNT";
      }

      if (hasReferral) {
        discoundLabel = "REFERRAL DISCOUNT";
      }

      return discoundLabel;
    },
  );

export const replaceDiscountAmountByDiscountType = (
  discountType,
  discountAmount,
  text,
) => {
  const replacedString =
    discountType === "dollar" ? "_DOLLAR_SYMBOL_" : "_PERCENTAGE_SYMBOL_";

  const removedString =
    discountType === "dollar" ? "_PERCENTAGE_SYMBOL_" : "_DOLLAR_SYMBOL_";

  const symbol = discountType === "dollar" ? "$" : "%";

  return text
    .replace("DISCOUNT_AMOUNT", discountAmount)
    .replace(replacedString, symbol)
    .replace(removedString, "");
};
