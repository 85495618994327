import React from "react";

const FamilyPreviewImage = ({ isDesktop }) => {
  const maxWidth = isDesktop ? "566px" : "none";
  return (
    <img
      width="100%"
      style={{ maxWidth }}
      src="/images/doc_preview_family.png"
      alt="document preview"
    />
  );
};

export default FamilyPreviewImage;
