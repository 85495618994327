import { all, call, put, takeLatest } from "redux-saga/effects";
import { fetchApiData } from "./requests";
import {
  getWillFinalizationStepSuccessAction,
  GET_WILL_FINALIZATION_STEP,
  UPDATE_WILL_FINALIZATION_STEP,
  getWillFinalizationStepAction,
} from "../actions/will-finalization-step";
import {
  fetchWillFinalizationStep,
  updateWillFinalizationStep,
} from "../api/will-finalization-step";
import { submitFormRequest } from "./forms";

function* handleGetWillFinalizationStep() {
  const response = yield call(fetchApiData, {
    apiCall: fetchWillFinalizationStep,
  });
  yield put(getWillFinalizationStepSuccessAction(response));
}

function* handleUpdateWillFinalizationStep({ payload }) {
  const values = { ...payload };
  yield call(submitFormRequest, {
    apiCall: updateWillFinalizationStep,
    formId: "UPDATE_WILL_FINALIZATION_STEP",
    values,
  });
  yield put(getWillFinalizationStepAction());
}

function* watchGetWillFinalizationStep() {
  yield takeLatest(GET_WILL_FINALIZATION_STEP, handleGetWillFinalizationStep);
}

function* watchUpdateWillFinalizationStep() {
  yield takeLatest(
    UPDATE_WILL_FINALIZATION_STEP,
    handleUpdateWillFinalizationStep,
  );
}

function* watchWillFinalizationStepSaga() {
  yield all([
    watchGetWillFinalizationStep(),
    watchUpdateWillFinalizationStep(),
  ]);
}

export default watchWillFinalizationStepSaga;
