import React, { useCallback } from "react";
import Box from "@material-ui/core/Box";

import { useSelector, useDispatch } from "react-redux";
import CardIcon from "./CardIcon";
import { H4, SmallBody } from "../ui/Typography";
import { PrimaryButton, SecondaryButton } from "../ui/Button";
import LockIcon from "../ui/icons/LockIcon";
import { selectIsCompleteWillSections } from "../../selectors/section-completion";
import {
  selectActivePlanSymbol,
  selectHasUserPaid,
} from "../../selectors/plans";
import { COUPLES_PLAN, ESSENTIALS_PLAN } from "../../constants/plans";
import { USER_WILL } from "../../constants/documents";
import DigitalExecutionWrapper from "../addons/digital-execution/DigitalExecutionWrapper";
import { selectUserProvinceByCode } from "../../selectors/provinces";
import { featureExecutedDocSurveyEnabled } from "../../utilities/featureFlags";
import ExecutionTracker from "./ExecutionTracker";
import { MODAL_SIGN_AND_WITNESS } from "../../constants/modal";
import { displayModal } from "../../actions/modal";
import { doesUnderstandWillExecution } from "../../selectors/documents";
import FinalizeMyWillButton from "../ui/FinalizeMyWillButton";

const DocumentCard = ({
  documentName,
  documentInfo,
  upgradeInfo,
  documentLockCopy,
  onDownload,
  isUpgradable = false,
  isSpouse = false,
  documentKey,
  translations,
  upgradePlan,
  isUpgradeLoading,
  isUpgradableUser,
}) => {
  const isCompleteWillSections = useSelector(selectIsCompleteWillSections);
  const isUserWillOrCouplesDocuments =
    documentKey === "userWill" || documentKey.includes("spouse");
  const hasUserPaid = useSelector(selectHasUserPaid);
  const isDownloadWillAvailable =
    isCompleteWillSections && isUserWillOrCouplesDocuments && hasUserPaid;

  const plan = useSelector(selectActivePlanSymbol);
  // Essentials plan have no lock copy so the card will render a bit different
  const isEssentialsPlan = plan === ESSENTIALS_PLAN;
  const canUpgrade = isUpgradableUser === true || isUpgradable === true;
  const isEssentialsPlanOrCannotUpgrde = isEssentialsPlan || !canUpgrade;
  let buttonsMarginTop =
    isUserWillOrCouplesDocuments && isEssentialsPlanOrCannotUpgrde ? 1 : 0;
  if (plan === COUPLES_PLAN) {
    // Couples plans have a different copy on the cards
    buttonsMarginTop = 1;
  }

  const isWillCard = documentKey === USER_WILL;

  const provinceCode = encodeURIComponent(
    useSelector(selectUserProvinceByCode),
  );

  const showExecutionTracker =
    isDownloadWillAvailable &&
    isWillCard &&
    featureExecutedDocSurveyEnabled() &&
    provinceCode !== "QC";

  const dispatch = useDispatch();

  const doesUserUnderstandWillExecution = useSelector(
    doesUnderstandWillExecution,
  );

  const handleClick = useCallback(() => {
    if (!doesUserUnderstandWillExecution && documentKey === USER_WILL) {
      dispatch(displayModal(MODAL_SIGN_AND_WITNESS));
    } else {
      onDownload(documentKey, false, isSpouse);
    }
  }, [
    doesUserUnderstandWillExecution,
    dispatch,
    onDownload,
    documentKey,
    isSpouse,
  ]);

  const showFinalizeWillButton =
    isCompleteWillSections && hasUserPaid && isWillCard;

  return (
    <Box
      bgcolor={isUpgradable ? "gray.main" : "white"}
      borderRadius={8}
      border={1}
      borderColor="border.main"
      height="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        height="100%"
        p={1.5}
      >
        <CardIcon documentKey={documentKey} />
        <Box mb={0.5}>
          <H4 align="center">{documentName}</H4>
        </Box>
        <Box mb={2}>
          <SmallBody align="center">{documentInfo}</SmallBody>
        </Box>
        {isUpgradable && (
          <>
            <Box width={1} justifyContent="center" display="flex">
              <SecondaryButton
                onClick={() =>
                  upgradePlan({ quantity: 1, previousQuantity: 1 })
                }
                isLoading={isUpgradeLoading}
                loadingLabel={translations.button.loadingLabel}
                secondary
                fullWidth
                text={translations.button.upgradePremium}
              />
            </Box>
            <Box
              width={1}
              mt={1}
              p={1}
              justifyContent="center"
              border={1}
              borderRadius={4}
              display="flex"
              borderColor="border.main"
            >
              <Box pr={1} marginTop="2px">
                <LockIcon color="#4A4A4A" />
              </Box>
              <SmallBody>{upgradeInfo}</SmallBody>
            </Box>
          </>
        )}
        {typeof documentLockCopy !== "undefined" &&
          documentLockCopy &&
          !isDownloadWillAvailable && (
            <Box
              width={1}
              p={1.5}
              borderRadius={4}
              justifyContent="center"
              borderColor="border.main"
              border={1}
              display="flex"
              mt={3}
            >
              <Box pr={1} marginTop="2px">
                <LockIcon color="#4A4A4A" />
              </Box>
              <SmallBody>{documentLockCopy}</SmallBody>
            </Box>
          )}

        {(isDownloadWillAvailable ||
          (documentLockCopy === "" && !isUpgradable)) && (
          <Box mt={buttonsMarginTop} width={1}>
            {showFinalizeWillButton && (
              <FinalizeMyWillButton
                text={translations.button.finalizeMyWill}
                fullWidth
              />
            )}
            <Box justifyContent="center" display="flex">
              <SecondaryButton
                displayArrowRight
                text={translations.button.view}
                className={`qa-view-${documentKey}`}
                fullWidth
                onClick={() => handleClick()}
              />
            </Box>
          </Box>
        )}

        {isWillCard && <DigitalExecutionWrapper />}
        {showExecutionTracker && (
          <ExecutionTracker provinceCode={provinceCode} />
        )}
      </Box>
    </Box>
  );
};

export default DocumentCard;
