export const updateUser = (formId, values = null) => ({
  type: UPDATE_USER_TYPE,
  payload: { formId, values },
});

export const updateAgreedToTos = (continueLink) => ({
  type: USER_AGREED_TO_TOS_TYPE,
  payload: { continueLink },
});

export const understandsWillExecution = () => ({
  type: USER_UNDERSTANDS_WILL_EXECUTION_TYPE,
});

export const UPDATE_USER_TYPE = "UPDATE_USER";
export const USER_AGREED_TO_TOS_TYPE = "USER_AGREED_TO_TOS";
export const USER_UNDERSTANDS_WILL_EXECUTION_TYPE =
  "USER_UNDERSTANDS_WILL_EXECUTION";
