import React, { useCallback } from "react";
import FinalizeWillStepAccordion from "../ui/FinalizeWillStepAccordion";
import { Body } from "../ui/Typography";
import { Box } from "@material-ui/core";
import { SecondaryButton } from "../ui/Button";
import DigitalExecutionWrapper from "../addons/digital-execution/DigitalExecutionWrapper";
import { selectRailsUserId } from "../../selectors/auth";
import { analyticsFinalizeWillStepCtaClicked } from "../../actions/analytics";
import { useDispatch, useSelector } from "react-redux";

const Print = ({ translation, completedAt, onCheck }: PrintPropTypes) => {
  const { body, title, cta, ctaLink, bcText } = translation;
  const stepName = "print_document";
  const userId = useSelector(selectRailsUserId) as number;
  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(
      analyticsFinalizeWillStepCtaClicked({
        user_id: userId,
        step_name: stepName,
        key: "print",
      }),
    );
    window.open(ctaLink, "blank_");
  }, [ctaLink]);

  return (
    <FinalizeWillStepAccordion
      label={title}
      onCheck={onCheck}
      defaultChecked={!!completedAt}
      userId={userId}
      stepName={stepName}
    >
      <Body color="textSecondary">{body}</Body>
      <Box mt={1} mb={1}>
        <SecondaryButton
          text={cta}
          onClick={handleClick}
          fullWidth
          showExternalLinkIconRight
        />
      </Box>
      {bcText && bcText !== "" && (
        <Box mb={1}>
          <Body color="textSecondary">{bcText}</Body>
          <DigitalExecutionWrapper fullWidth />
        </Box>
      )}
    </FinalizeWillStepAccordion>
  );
};

export default Print;
