import React from "react";
import { withTheme } from "@material-ui/styles";
import classNames from "classnames";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

import { useSelector } from "react-redux";
import { Body, SmallBody } from "./Typography";
import PlusIcon from "./icons/PlusIcon";
import { colorPalette, media } from "../../config/Theme";
import { ExternalIcon } from "./icons/ExternalIcon";
import ArrowDownloadIcon from "./icons/ArrowDownloadIcon";
import { selectTranslations } from "../../selectors/translations";

// TODO: refactor duplicated styles to use this
export const arrowStyles = css`
  .arrow {
    transition: margin 240ms ease-out;
  }

  .default-margin {
    margin-left: 1px;
    margin-right: 1px;
  }

  .arrow.right {
    margin-left: 8px;
    margin-right: 8px;
  }

  .arrow.left {
    margin-left: 8px;
    margin-right: 8px;
  }

  &:hover {
    .arrow.right {
      margin-left: 16px;
      margin-right: 0px;
    }

    .arrow.left {
      margin-left: 0px;
      margin-right: 16px;
    }
  }
`;

const PlusIconBox = () => {
  return (
    <Box component="span" display="flex" alignItems="center" ml={0.5}>
      <PlusIcon />
    </Box>
  );
};

export const StyledPrimaryButton = styled.button`
  font-family: "TT Norms";
  min-width: ${(props) => (props.fullWidth ? "0" : "100px")};
  width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
  padding: 1rem 2rem;
  background-color: ${(props) => (props.destructive ? "#FF2222" : "#0064dc")};
  border-radius: 2px;
  cursor: pointer;
  border-color: transparent;
  transition: margin 240ms ease-out, padding 240ms ease-out,
    background-color 240ms linear;
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};

  .default-margin {
    margin-left: 1px;
    margin-right: 1px;
  }

  .arrow {
    transition: margin 240ms ease-out;
  }

  .arrow.right {
    margin-left: 8px;
    margin-right: 8px;
  }

  .arrow.left {
    margin-left: 8px;
    margin-right: 8px;
  }

  ${media.desktop} {
    min-width: ${(props) => (props.fullWidth ? "0" : "195px")};
  }

  &:hover {
    background-color: ${(props) => (props.destructive ? "#C80000" : "#004eb6")};

    .arrow.right {
      margin-left: 16px;
      margin-right: 0;
    }

    .arrow.left {
      margin-left: 0;
      margin-right: 16px;
    }
  }

  &:focus {
    outline: none;
    background-color: ${(props) => (props.destructive ? "#C80000" : "#004eb6")};
  }

  &.isLoading {
    background-color: ${(props) =>
      props.destructive ? "#FF7878;" : "#71adee"};
  }

  &.isDisabled {
    background-color: ${(props) =>
      props.destructive ? "#FF7878;" : "#71adee"};
  }
`;

export const PrimaryButtonComponent = ({
  text,
  type = "submit",
  loadingLabel,
  destructive = false,
  displayArrowLeft,
  displayArrowRight,
  isLoading,
  isDisabled = false,
  ...otherProps
}) => (
  <StyledPrimaryButton
    disabled={isLoading || isDisabled}
    className={classNames("qa-submit", { isLoading }, { isDisabled })}
    destructive={destructive}
    type={type}
    id={otherProps.id ? otherProps.id : `${type}Button`} // for testing purpose
    {...otherProps}
  >
    {displayArrowLeft && (
      <Body component="span" color={"yellow"} className="arrow left">
        ←
      </Body>
    )}
    <Body component="span" color={"white"} className="default-margin">
      {isLoading ? loadingLabel : text}
    </Body>
    {displayArrowRight && !isLoading && (
      <Body
        component="span"
        color={destructive ? "white" : "yellow"}
        className="arrow right"
      >
        →
      </Body>
    )}
  </StyledPrimaryButton>
);

export const PrimaryButton = withTheme(PrimaryButtonComponent);

// TODO: update button width default to auto
export const StyledSecondaryButton = styled.button`
  font-family: "TT Norms";
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  min-width: ${(props) => (props.fullWidth ? "0" : "100px")};
  padding: 1rem 2rem;
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #0064dc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  transition: background-color 240ms linear, box-shadow 240ms linear,
    padding 240ms ease-out;

  ${media.desktop} {
    min-width: inherit;
  }

  .default-margin {
    margin-left: 1px;
    margin-right: 1px;
  }

  .arrow {
    transition: margin 240ms ease-out;
  }

  .arrow.right {
    margin-left: 8px;
  }

  .arrow.left {
    margin-left: 8px;
    margin-right: 8px;
  }

  &:focus {
    outline: none;
    background-color: #e2f6ff;
    box-shadow: 0 0 0 1px #0064dc;
  }

  &:hover {
    transition: margin 240ms ease-out, background-color 240ms linear;
    background-color: #e2f6ff;

    .arrow.right {
      margin-left: 24px;
    }

    .arrow.left {
      margin-left: 0;
      margin-right: 16px;
    }
  }

  &.isDisabled {
    border: 1px solid ${colorPalette.willfulGrey2};
  }
`;

export const SecondaryButtonComponent = ({
  text,
  type = "submit",
  loadingLabel,
  isLoading,
  displayArrowLeft,
  displayArrowRight,
  displayPlusIcon,
  isDisabled = false,
  showDownloadIconLeft = false,
  showExternalLinkIconRight = false,
  ...otherProps
}) => (
  <StyledSecondaryButton
    disabled={isLoading || isDisabled}
    className={classNames("qa-secondary", { isLoading }, { isDisabled })}
    type={type}
    {...otherProps}
  >
    {displayArrowLeft && (
      <Body component="span" color="main" className="arrow left">
        ←
      </Body>
    )}
    {showDownloadIconLeft && (
      <Box
        ml={0.5}
        mr={0.5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ArrowDownloadIcon />
      </Box>
    )}
    <Body
      component="span"
      color={isDisabled ? "secondary" : "main"}
      align="center"
      className="default-margin"
    >
      {isLoading ? loadingLabel : text}
    </Body>
    {showExternalLinkIconRight && (
      <Box
        ml={0.5}
        mr={0.5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <ExternalIcon />
      </Box>
    )}
    {displayArrowRight && (
      <Body component="span" color="main" className="arrow right">
        →
      </Body>
    )}
    {displayPlusIcon && <PlusIconBox />}
  </StyledSecondaryButton>
);

export const SecondaryButton = withTheme(SecondaryButtonComponent);

// New generic button than can be colorized
export const StyledColoredButton = styled.button`
  font-family: "TT Norms";
  min-width: ${(props) => (props.fullWidth ? "0" : "100px")};
  width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
  padding: 1rem 2rem;
  background-color: ${(props) => props.colorBg || colorPalette.willfulYellow};
  border-radius: 2px;
  border: 1px solid transparent;
  border-color: ${(props) => props.colorBorder || "transparent"};
  cursor: pointer;
  transition: padding 240ms ease-out;
  color: ${(props) => props.colorText || colorPalette.willfulGrey};

  .default-margin {
    margin-left: 1px;
    margin-right: 1px;
  }

  .arrow {
    color: ${(props) => props.colorArrow || colorPalette.willfulBlue};
    transition: margin 240ms ease-out;
  }

  .arrow.right {
    margin-left: 8px;
    margin-right: 8px;
  }

  .arrow.left {
    margin-left: 8px;
    margin-right: 8px;
  }

  ${media.desktop} {
    min-width: ${(props) => (props.fullWidth ? "0" : "195px")};
  }

  &:hover {
    transition: margin 240ms ease-out, filter 240ms linear;
    filter: brightness(92%);

    .arrow.right {
      margin-left: 16px;
      margin-right: 0;
    }

    .arrow.left {
      margin-left: 0;
      margin-right: 16px;
    }
  }

  &:focus {
    outline: none;
    transition: filter 240ms linear;
    filter: brightness(92%);
  }

  &.isLoading {
    transition: filter 240ms linear;
    filter: brightness(92%);
  }

  &.isDisabled {
    transition: filter 240ms linear;
    filter: brightness(92%);
  }
`;

export const ColoredButtonComponent = ({
  text,
  type = "submit",
  loadingLabel,
  displayArrowLeft,
  displayArrowRight,
  isLoading,
  isDisabled = false,
  colorText = colorPalette.willfulGrey,
  colorArrow = colorPalette.willfulBlue,
  colorBackground = colorPalette.willfulYellow,
  colorBorder = "transparent",
  ...otherProps
}) => (
  <StyledColoredButton
    disabled={isLoading || isDisabled}
    className={classNames("qa-submit", { isLoading }, { isDisabled })}
    type={type}
    colorText={colorText}
    colorArrow={colorArrow}
    colorBg={colorBackground}
    colorBorder={colorBorder}
    {...otherProps}
  >
    {displayArrowLeft && (
      <Body component="span" className="arrow left">
        ←
      </Body>
    )}
    <Body component="span" className="default-margin">
      {isLoading ? loadingLabel : text}
    </Body>
    {displayArrowRight && !isLoading && (
      <Body component="span" className="arrow right">
        →
      </Body>
    )}
  </StyledColoredButton>
);

export const ColoredButton = withTheme(ColoredButtonComponent);

const StyledButtonLink = styled(Link)`
  font-family: "TT Norms";
  min-width: 100px;
  width: ${(props) => (props.$fullWidth ? "100%" : "fit-content")};
  display: block;
  padding: 1rem 2rem;
  border-radius: 2px;
  text-align: center;
  border: ${(props) =>
    props.$secondary
      ? `1px solid ${props.theme.palette.willfulBlue.main}`
      : "none"};
  background-color: ${(props) =>
    props.$secondary
      ? `${props.theme.palette.willfulWhite.main}`
      : `${props.theme.palette.willfulBlue.main}`};
  text-decoration: none;
  transition: padding 240ms ease-out;

  .default-margin {
    margin-left: 1px;
    margin-right: 1px;
  }

  .arrow-container > .arrow {
    transition: margin-left 240ms ease-out;
    margin-left: 0;
  }

  &:hover {
    .arrow-container > .arrow {
      transition: margin-left 240ms ease-out;
      margin-left: ${(props) => (props.displayArrowRight ? "16px" : "0px")};
    }

    padding: ${(props) =>
      props.displayArrowRight ? "1rem 1rem 1rem 2rem" : ""};
    background-color: ${(props) =>
      props.$secondary
        ? `${props.theme.palette.willfulxxxLightBlue.main}`
        : `${props.theme.palette.willfulBlueDark2.main}`};
  }
`;

const ButtonLinkBase = ({
  text,
  secondary,
  to,
  displayArrowRight,
  fullWidth,
  ...otherProps
}) => (
  <StyledButtonLink
    to={to}
    $secondary={secondary}
    $displayArrowRight={displayArrowRight}
    $fullWidth={fullWidth}
    id={otherProps.id ? otherProps.id : `${text}Button`}
    {...otherProps}
  >
    <Body
      component="span"
      color={secondary ? "main" : "white"}
      className="default-margin"
    >
      {text}
    </Body>
    <Box
      ml={0.5}
      className="arrow-container"
      component="span"
      display="inline-block"
    >
      {displayArrowRight && (
        <Body
          component="span"
          className="arrow"
          color={secondary ? "main" : "yellow"}
        >
          →
        </Body>
      )}
    </Box>
  </StyledButtonLink>
);

export const ButtonLink = withTheme(ButtonLinkBase);

const StyledPreviousLink = styled(Link)`
  font-family: "TT Norms";
  display: block;
  text-decoration: none;
  color: ${colorPalette.willfulGrey};
  transition: color 240ms linear;
  width: 10%;

  .default-margin {
    margin-left: 1px;
    margin-right: 1px;
  }

  .arrow {
    transition: margin 240ms ease-out;
  }

  .arrow.right {
    margin-left: 8px;
  }

  .arrow.left {
    margin-left: 8px;
    margin-right: 8px;
  }

  &:hover {
    transition: margin 240ms ease-out, color 240ms linear;

    .text {
      text-decoration: ${(props) => (props.underline ? "underline" : "none")};
    }

    .arrow.right {
      margin-left: 24px;
    }

    .arrow.left {
      margin-left: 0;
      margin-right: 16px;
    }
  }
`;

export const PreviousLink = ({
  text = "",
  to,
  underline = true,
  displayArrowLeft = false,
  displayArrowRight = false,
  ...otherProps
}) => {
  const translation = useSelector(selectTranslations(["global"]));
  const previousText = text !== "" ? text : translation.button.previous;

  return (
    <StyledPreviousLink underline={underline} to={to} {...otherProps}>
      {displayArrowLeft && (
        <Body component="span" className="arrow left">
          ←
        </Body>
      )}
      <Body component="span" className="text default-margin">
        {previousText}
      </Body>
      {displayArrowRight && (
        <Body component="span" className="arrow right">
          →
        </Body>
      )}
    </StyledPreviousLink>
  );
};

const StyledTextButton = styled.button`
  padding: 0;
  border: 0;
  margin: ${(props) => props.margin || 0};
  background: none;
  cursor: pointer;
  ${(props) => props.color && `color: ${props.color};`}

  ${arrowStyles}
  &:hover {
    transition: margin 240ms ease-out, color 240ms linear;
    color: #004eb6;

    .innerText {
      text-decoration: ${(props) => (props.underline ? "underline" : "none")};
    }
  }
`;

export const TextButton = ({
  text,
  type = "button",
  underline = true,
  isLoading = false,
  loadingLabel = "",
  isDisabled = false,
  color = "main",
  loadingColor = "willfulxLightBlue",
  displayArrowLeft = false,
  displayArrowRight = false,
  ...otherProps
}) => (
  <StyledTextButton
    disabled={isLoading || isDisabled}
    type={type}
    underline={underline}
    color={color}
    {...otherProps}
  >
    {displayArrowLeft && (
      <Body
        component="span"
        color={isLoading || isDisabled ? loadingColor : color}
        className="arrow left"
      >
        ←
      </Body>
    )}

    <Body
      component="span"
      className="innerText default-margin"
      color={isLoading || isDisabled ? loadingColor : color}
    >
      {isLoading ? loadingLabel : text}
    </Body>
    {displayArrowRight && (
      <Body
        component="span"
        color={isLoading || isDisabled ? loadingColor : color}
        className="arrow right"
      >
        →
      </Body>
    )}
  </StyledTextButton>
);

export const SmallTextButton = ({
  text,
  type = "button",
  isLoading = false,
  disabled = false,
  display = "flex",
  displayArrowRight = false,
  arrowSize = "1rem",
  includeHorizontalSpacing = false,
  ...otherProps
}) => (
  <Box display={display} mr={includeHorizontalSpacing && 1.5}>
    {isLoading && (
      <Box mr={0.5} display="flex" alignItems="center">
        <CircularProgress style={{ color: "#71ADEE" }} size={16} />
      </Box>
    )}
    <StyledTextButton
      disabled={disabled || isLoading}
      text={text}
      type={type}
      isLoading={isLoading}
      {...otherProps}
    >
      <SmallBody
        component="span"
        className="innerText"
        color={isLoading ? "willfulxLightBlue" : "main"}
        bold
      >
        {text}
        {displayArrowRight && (
          <Body
            component="span"
            color="main"
            className="arrow right"
            style={{ fontSize: arrowSize }}
          >
            →
          </Body>
        )}
      </SmallBody>
    </StyledTextButton>
  </Box>
);

const StyledButtonExternalLink = styled.a`
  width: ${(props) => (props.fullWidth ? "100%" : "fit-content")};
  display: block;
  padding: 1rem 2rem;
  text-align: ${(props) => props.align};
  background-color: ${(props) => (props.secondary ? "#fff" : "#0064dc")};
  cursor: pointer;
  color: ${(props) => (props.secondary ? "#0064dc" : "#fff")};
  text-decoration: none;
  border: 1px solid ${(props) => (props.secondary ? "#0064dc" : "transparent")};
  border-radius: 2px;
  font-weight: 700;
  font-size: 20px;

  &:focus {
    box-shadow: 0 0 0 1px #0064dc;
  }

  &:visited {
    color: white;
  }

  ${arrowStyles}
  &:hover {
    transition: margin 240ms ease-out, background-color 240ms linear;
    background-color: ${(props) => (props.destructive ? "#C80000" : "#004eb6")};
  }

  &:hover {
    .arrow-container > .arrow {
      transition: margin-left 240ms ease-out;
      margin-left: 16px;
    }

    transition: margin 240ms ease-out, background-color 240ms linear;
    background-color: ${(props) => {
      if (props.secondary) {
        return props.theme.palette.willfulxxxLightBlue.main;
      }

      if (props.destructive) {
        return "#C80000";
      }

      return "#004eb6";
    }};
  }
`;

// TODO: not used and does not have responsive typography
// Consider deleting or consolidating into a core link/button component
export const ExternalButtonLinkBase = ({
  text,
  url,
  align = "left",
  displayArrowRight = false,
  secondary,
  ...otherProps
}) => {
  return (
    <StyledButtonExternalLink
      target="_blank"
      align={align}
      href={url}
      secondary={secondary}
      {...otherProps}
    >
      {text}
      {displayArrowRight && (
        <Body
          component="span"
          color={secondary ? "main" : "yellow"}
          className="arrow right"
        >
          →
        </Body>
      )}
    </StyledButtonExternalLink>
  );
};

export const ExternalButtonLink = withTheme(ExternalButtonLinkBase);

export const BaseButton = styled.button`
  border: 0;
  padding: 0;
  background: none;
  outline: none;
  cursor: pointer;
`;
