import { createStructuredSelector, createSelector } from "reselect";
import R from "ramda";

import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectTranslations } from "./translations";
import { selectProvinceCode } from "./provinces";
import {
  CHECKOUT_FORM_ID,
  PROMO_CODE_FORM_ID,
  CAA_FORM_ID,
  REFERRAL_FORM_ID,
  STRIPE_CLIENT_SECRET_FIELD_ID,
} from "../constants/forms";

import {
  selectOrderSubtotal,
  selectOrderTax,
  selectOrderTaxType,
  selectOrderTaxFormatted,
  selectOrderPlanSymbol,
  selectOrderPlanQuantity,
  selectOrderTotalFormatted,
  selectOrderValueFormatted,
  selectOrderPlanLineItemFormatted,
} from "./utils/order";
import {
  selectOrderHasDiscount,
  selectOrderDiscountsData,
  selectOrderPromoCode,
  selectOrderHasReferral,
  selectOrderHasPromo,
  selectOrderDiscountLabelApplied,
  selectOrderHasCaaMembership,
  selectOrderHasPartnerDiscount,
  replaceDiscountAmountByDiscountType,
} from "./utils/discounts";
import { capitalize } from "../utilities/name";
import {
  selectHasRevsharePartnerDiscount,
  selectHasVolumePartnerDiscount,
  selectIsUnlimitedUpdatesPartnerCampaignId,
  selectPartnerDiscountAmount,
  selectPartnerDiscountPartnerName,
  selectPartnerDiscountType,
} from "./partner-discount";
import { selectPlanSummaryProps } from "./dashboard";
import { selectCartPlanCardProps } from "./utils/plan-cards";
import { selectIsFrenchSelected } from "./language";
import { selectIsBNPLOZEnabled } from "./features";
import { selectIsActivePlanBundle } from "./plans";
import { NOTARIAL_PLAN, PREMIUM_PLAN } from "../constants/plans";
import { selectTranslatedReferralDiscountAmount } from "./referral-program";

const selectCartPlanSymbol = selectOrderPlanSymbol("cart");
const selectCartPlanQuantity = selectOrderPlanQuantity("cart");
const selectCartSubtotal = selectOrderSubtotal("cart");
const selectCartTax = selectOrderTax("cart");
const selectCartTaxType = selectOrderTaxType("cart");
const selectCartHasReferral = selectOrderHasReferral("cart");
const selectCartValueFormatted = selectOrderValueFormatted("cart");
const selectCartTaxFormatted = selectOrderTaxFormatted("cart");
const selectCartTotalFormatted = selectOrderTotalFormatted("cart");
const selectCartPlanLineItemFormatted = selectOrderPlanLineItemFormatted(
  "cart",
);
const selectCartDiscountsData = selectOrderDiscountsData("cart");
const selectCartHasDiscount = selectOrderHasDiscount("cart");
const selectCartHasPartnerDiscount = selectOrderHasPartnerDiscount("cart");
const selectCartPromoCode = selectOrderPromoCode("cart");
const selectCartHasPromo = selectOrderHasPromo("cart");
const selectCartDiscountLabelApplied = selectOrderDiscountLabelApplied("cart");
const selectCartHasCaaMembership = selectOrderHasCaaMembership("cart");
const selectShowCaaField = R.pathOr(false, ["cart", "showCaaField"]);
const selectStripeClientSecret = R.pathOr("", [
  "checkout",
  STRIPE_CLIENT_SECRET_FIELD_ID,
]);

const selectShouldDisplayCaaField = createSelector(
  selectHasVolumePartnerDiscount,
  selectShowCaaField,
  (hasVolumePartnerDiscount, showCaaField) => {
    return !hasVolumePartnerDiscount && showCaaField;
  },
);

const selectShouldDisplayPromoCodeField = createSelector(
  selectHasVolumePartnerDiscount,
  (hasVolumePartnerDiscount) => {
    return !hasVolumePartnerDiscount;
  },
);

const selectShouldDisplayCheckoutForm = createSelector(
  selectHasVolumePartnerDiscount,
  selectHasRevsharePartnerDiscount,
  selectOrderSubtotal("cart"),
  (hasVolumePartnerDiscount, hasRevsharePartnerDiscount, subtotal) => {
    return !(
      (hasVolumePartnerDiscount || hasRevsharePartnerDiscount) &&
      subtotal === 0
    );
  },
);

const selectShouldDisplayPartnerDiscountConfirmCheckoutButton = createSelector(
  selectHasVolumePartnerDiscount,
  selectHasRevsharePartnerDiscount,
  selectOrderSubtotal("cart"),
  (hasVolumePartnerDiscount, hasRevsharePartnerDiscount, subtotal) => {
    return (
      (hasVolumePartnerDiscount || hasRevsharePartnerDiscount) && subtotal === 0
    );
  },
);

// subtotal / quantity
// this number is NOT rounded, it is only used to be sent to GA which will handle rounding
// TODO: THIS SHOULD BE TESTED IN GA
const selectPricePerPlanAfterDiscount = createSelector(
  selectCartSubtotal,
  selectCartPlanQuantity,
  (subtotal, planQuantity) => subtotal / planQuantity,
);

const selectCheckoutTranslations = createSelector(
  selectCartTotalFormatted,
  selectCartPlanLineItemFormatted,
  selectTranslations(["global", "plans", "checkout"]),
  selectTranslatedReferralDiscountAmount,
  (total, planName, translations, referralDiscountAmount) => {
    const newButtonTranslations = { ...translations.button };
    newButtonTranslations.submit = newButtonTranslations.submit.replace(
      "{PRICE}",
      total,
    );
    const newReferralInfoBoxText = translations.referralInfoBoxText.replace(
      "{DISCOUNT}",
      referralDiscountAmount,
    );
    return {
      ...translations,
      button: newButtonTranslations,
      referralInfoBoxText: newReferralInfoBoxText,
      planName,
    };
  },
);

export const selectAnalyticsCheckoutData = createSelector(
  selectCartPlanSymbol,
  selectCartSubtotal, // should not be formatted
  selectPricePerPlanAfterDiscount,
  selectCartTax,
  selectProvinceCode,
  selectCartPlanQuantity,
  selectCartDiscountLabelApplied,
  (
    planSymbol,
    subtotal,
    pricePerPlan, // after discount
    tax,
    provinceCode,
    quantity,
    discountLabelApplied,
  ) => ({
    planName: capitalize(planSymbol), // GA expects Product names capitalized
    planPrice: pricePerPlan,
    quantity,
    revenue: subtotal,
    coupon: discountLabelApplied,
    totalTax: tax,
    provinceCode,
  }),
);

const selectVolumePartnerDiscountTranslations = createSelector(
  selectTranslations(["checkout"]),
  selectPartnerDiscountAmount,
  selectPartnerDiscountPartnerName,
  (translations, discountAmount, partnerName) => {
    const partnerTranslations = R.propOr(
      {},
      "volumePartnerDiscountBox",
      translations,
    );
    const body = partnerTranslations.body
      .replace("PARTNER_NAME", partnerName)
      .replace("DISCOUNT_AMOUNT", discountAmount);

    return {
      ...partnerTranslations,
      body,
    };
  },
);

const selectRevsharePartnerDiscountTranslations = createSelector(
  selectTranslations(["checkout"]),
  selectPartnerDiscountAmount,
  selectPartnerDiscountPartnerName,
  selectPartnerDiscountType,
  selectIsUnlimitedUpdatesPartnerCampaignId,
  (
    translations,
    discountAmount,
    partnerName,
    discountType,
    isUnlimitedUpdatesPartnerCampaignId,
  ) => {
    const partnerTranslations = R.propOr(
      {},
      "revsharePartnerDiscountBox",
      translations,
    );

    // if the partner campaign is in a special list of unlimited updates partners,
    // the discount box will have an extra copy referencing the unlimited updates
    const body = replaceDiscountAmountByDiscountType(
      discountType,
      discountAmount,
      isUnlimitedUpdatesPartnerCampaignId
        ? partnerTranslations.unlimitedUpdatesBody
        : partnerTranslations.body,
    ).replace("PARTNER_NAME", partnerName);

    return {
      ...partnerTranslations,
      body,
    };
  },
);

export const selectBuyNowPayLaterInterestOptions = createSelector(
  selectTranslations(["checkout"]),
  (translations) => {
    const { buyNowPayLaterInterestOptions } = translations;
    return { optionList: buyNowPayLaterInterestOptions.optionList };
  },
);

const selectPlansDetails = createSelector(
  selectTranslations(["checkout"]),
  selectCartPlanSymbol,
  (translations, planSymbol) => {
    if (!translations.plansDetails) {
      return [];
    }
    switch (planSymbol) {
      case PREMIUM_PLAN:
        return translations.plansDetails.premium;
      case NOTARIAL_PLAN:
        return translations.plansDetails.notarial;
      default:
        return translations.plansDetails.essentials;
    }
  },
);

export const checkoutPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(CHECKOUT_FORM_ID),
  shouldDisplayCaaField: selectShouldDisplayCaaField,
  shouldDisplayPromoCodeField: selectShouldDisplayPromoCodeField,
  shouldDisplayCheckoutForm: selectShouldDisplayCheckoutForm,
  shouldDisplayPartnerDiscountConfirmCheckoutButton: selectShouldDisplayPartnerDiscountConfirmCheckoutButton,
  translations: selectTranslations(["global", "checkout", "trustIcon"]),
  planSummaryProps: selectPlanSummaryProps,
  plansCardProps: selectCartPlanCardProps,
  buyNowPayLaterCardTranslations: selectBuyNowPayLaterInterestOptions,
  isBNPLEnabled: selectIsBNPLOZEnabled,
  isFrenchSelected: selectIsFrenchSelected,
  isBundlePlanSelected: selectIsActivePlanBundle,
  checkoutInfo: createStructuredSelector({
    isLoading: selectIsLoadingById(REFERRAL_FORM_ID),
    orderValue: selectCartValueFormatted,
    tax: selectCartTaxFormatted,
    taxType: selectCartTaxType,
    hasReferral: selectCartHasReferral,
    total: selectCartTotalFormatted,
    hasDiscount: selectCartHasDiscount,
    discountsData: selectCartDiscountsData,
    promoCode: selectCartPromoCode,
    translations: selectCheckoutTranslations,
    hasVolumePartnerDiscount: selectHasVolumePartnerDiscount,
    hasRevsharePartnerDiscount: selectHasRevsharePartnerDiscount,
    volumePartnerDiscountTranslations: selectVolumePartnerDiscountTranslations,
    revsharePartnerDiscountTranslations: selectRevsharePartnerDiscountTranslations,
    isPartnerDiscountAppliedToCart: selectCartHasPartnerDiscount,
    plansDetails: selectPlansDetails,
  }),
  checkoutForm: createStructuredSelector({
    planName: selectCartPlanLineItemFormatted,
    isLoading: selectIsLoadingById(CHECKOUT_FORM_ID),
    translations: selectCheckoutTranslations,
  }),
  promoForm: createStructuredSelector({
    hasPromo: selectCartHasPromo,
    isLoading: selectIsLoadingById(PROMO_CODE_FORM_ID),
    translations: selectCheckoutTranslations,
  }),
  caaForm: createStructuredSelector({
    hasCaaMembership: selectCartHasCaaMembership,
    isLoading: selectIsLoadingById(CAA_FORM_ID),
    translations: selectCheckoutTranslations,
  }),
  stripeClientSecret: selectStripeClientSecret,
});
