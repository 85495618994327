import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import styled from "styled-components";
import Box from "@material-ui/core/Box";

import ModalContext from "../../../contexts/ModalContext";

import { animationSpeed, colorPalette, media } from "../../../config/Theme";
import Lightbox from "./Lightbox";
import { PrimaryButton, ButtonLink, ExternalButtonLink } from "../Button";
import { H2, H3, H4, Body } from "../Typography";
import CloseGreyIcon from "../icons/CloseGreyIcon";
import { ArrowBlueUpIcon } from "../icons/ArrowIcons";
import { useBackgroundLock, useKeyPress } from "../../../utilities/hooks";

const StyledModal = styled(Box)`
  z-index: 11;
  position: fixed;
  padding: 32px;
  overflow-y: auto; // allow modal to scroll when max height is hit
  border-radius: 5px;
  border: 1px solid ${colorPalette.willfulGrey2};
  width: 500px;
  background-color: ${colorPalette.white};
  animation-name: animation;
  animation-duration: ${animationSpeed.fast}ms;
  animation-timing-function: ease-out;

  @keyframes animation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  ${media.mobile} {
    top: 72px;
    max-height: calc(100% - 88px);
    width: calc(100% - 32px);
    padding: 16px;
    max-width: 500px;

    @keyframes animation {
      0% {
        top: 104px;
      }
    }
  }

  ${media.tablet} {
    top: 72px;
    max-height: calc(100% - 144px);

    @keyframes animation {
      0% {
        top: 104px;
      }
    }
  }

  ${media.desktop} {
    top: 120px;
    max-height: calc(100% - 240px);

    @keyframes animation {
      0% {
        top: 152px;
      }
    }
  }
`;

const StyledDrawerModal = styled(Box)`
  z-index: 11;
  position: fixed;
  padding: 24px;
  overflow-y: auto;
  width: 100%;
  background-color: ${colorPalette.white};
  animation-duration: ${animationSpeed.fast}ms;
  animation-timing-function: ease-out;

  // Do not remove
  .modal-close--desktop {
    display: none;

    ${media.desktop} {
      display: block;
    }
  }

  // Do not remove
  .modal-close--mobile {
    display: block;

    ${media.desktop} {
      display: none;
    }
  }

  @keyframes swipeUp {
    0% {
      bottom: -100%;
    }
    100% {
      bottom: 0;
    }
  }

  @keyframes swipeDown {
    0% {
      bottom: 0;
    }
    100% {
      bottom: -100%;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      top: 152px;
    }
    100% {
      opacity: 1;
      top: 120px;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      top: 120px;
    }
    100% {
      opacity: 0;
      top: 152px;
    }
  }

  ${media.mobile} {
    bottom: 0;
    width: 100%;
    max-height: calc(100% - 48px);
    border-radius: 16px 16px 0 0;
    border: 0;

    &.animation {
      animation-name: ${(props) => (props.closing ? "swipeDown" : "swipeUp")};
    }
  }

  ${media.tablet} {
    bottom: 0;
    width: 100%;
    max-height: calc(100% - 144px);
    border-radius: 16px 16px 0 0;
    border: 0;

    &.animation {
      animation-name: ${(props) => (props.closing ? "swipeDown" : "swipeUp")};
    }
  }

  ${media.desktop} {
    top: 120px;
    bottom: auto;
    padding: 32px;
    width: 500px;
    max-height: calc(100% - 240px);
    border-radius: 5px;
    border: 1px solid ${colorPalette.willfulGrey2};

    &.animation {
      animation-name: ${(props) => (props.closing ? "fadeOut" : "fadeIn")};
    }
  }
`;

const StyledTooltipModal = styled(StyledModal)`
  width: 640px;
  ${media.mobile} {
    width: calc(100% - 20px);
    padding: 24px;
  }
`;

const StyledEmailPreviewModal = styled(StyledModal)`
  ${media.tablet} {
    width: 664px;
  }
`;

const CloseBox = styled(Box)`
  cursor: pointer;
  height: 24px;
`;

const TitleContainer = styled(Box)`
  max-width: 375px;
`;

const CloseButton = ({ onClick, closeRef, mobileDrawer = false }) => {
  let icon = <CloseGreyIcon />;

  if (mobileDrawer) {
    icon = (
      <>
        <span className="modal-close--mobile">
          <ArrowBlueUpIcon />
        </span>
        <span className="modal-close--desktop">
          <CloseGreyIcon />
        </span>
      </>
    );
  }

  return (
    <CloseBox
      onClick={onClick}
      role="button"
      aria-label="Close"
      tabIndex="0"
      ref={closeRef}
    >
      {icon}
    </CloseBox>
  );
};

export const BaseModal = React.forwardRef(
  ({
    children,
    closeOnLightboxClick = false,
    onClose = () => {},
    InnerModalComponent = StyledModal,
    closing = false,
  }) => {
    const lightboxEl = useRef(null);
    const innerModalRef = useRef(null);
    const [isFocus, setFocus] = useState(false);

    if (useKeyPress("Tab") && !isFocus) {
      setFocus(true);
      innerModalRef.current.focus();
    }

    const handleLightboxClick = (e) => {
      if (!closeOnLightboxClick) {
        return;
      }

      // Only close the lightbox if the target of the click was the lightbox itself, and not one of its children
      if (lightboxEl.current === e.target) {
        onClose();
      }
    };

    return (
      <Lightbox onClick={handleLightboxClick} ref={lightboxEl}>
        <InnerModalComponent
          ref={innerModalRef}
          role="dialog"
          aria-label="Dialog"
          tabIndex="0"
          className="modal-content animation"
          closing={closing}
        >
          {children}
        </InnerModalComponent>
      </Lightbox>
    );
  },
);

export const GenericModal = ({
  children,
  redirectPath,
  externalLink,
  translations,
  onClose,
  onSubmit,
  isPrompt = false,
  shouldCloseOnLightboxClick = false,
  shouldHideSubmitButton = false,
  shouldHideCloseButton = false,
}) => {
  const [closing, setClosing] = useState(false);
  const { mobileDrawer } = useContext(ModalContext);
  const { title, body, secondBody = false } = translations;

  const modalEl = useRef(null);
  useBackgroundLock(modalEl);

  const closeRef = useRef(null);
  const pressedEnter = useKeyPress("Enter");
  const pressedEscape = useKeyPress("Escape");

  const handleOnClose = useCallback(() => {
    if (mobileDrawer) {
      setClosing(true);
      // Wait for animation to finish
      setTimeout(() => {
        onClose();
      }, animationSpeed.xfast);
      return;
    }
    onClose();
  }, [mobileDrawer, onClose]);

  useEffect(() => {
    if (!shouldHideCloseButton) {
      if (pressedEnter && document.activeElement === closeRef.current) {
        handleOnClose();
      }

      if (pressedEscape) {
        handleOnClose();
      }
    }
  }, [pressedEnter, pressedEscape, shouldHideCloseButton, handleOnClose]);

  const renderSubmitButton = () => {
    if (externalLink) {
      return (
        <ExternalButtonLink
          onClick={handleOnClose}
          url={externalLink}
          text={translations.button.submit}
          align="center"
          fullWidth
        />
      );
    }

    if (redirectPath) {
      return (
        <ButtonLink
          onClick={handleOnClose}
          to={redirectPath}
          displayArrowRight
          text={translations.button.submit}
          fullWidth
        />
      );
    }

    if (!shouldHideSubmitButton) {
      return (
        <PrimaryButton
          onClick={isPrompt ? onSubmit : handleOnClose}
          displayArrowRight={isPrompt}
          text={translations.button.submit}
          fullWidth
        />
      );
    }

    return null;
  };

  const innerComponent = mobileDrawer ? StyledDrawerModal : StyledModal;

  return (
    <BaseModal
      ref={modalEl}
      closeOnLightboxClick={shouldCloseOnLightboxClick}
      onClose={onClose}
      closing={closing}
      InnerModalComponent={innerComponent}
    >
      <Box display="flex" justifyContent="space-between">
        <TitleContainer>
          <H2 align="left">{title}</H2>
        </TitleContainer>
        {!shouldHideCloseButton && (
          <CloseButton
            onClick={handleOnClose}
            closeRef={closeRef}
            mobileDrawer={mobileDrawer}
          />
        )}
      </Box>
      {children}
      {!children && (
        <>
          <Box pt={1}>
            <Body>{body}</Body>
          </Box>
          {secondBody && (
            <Box>
              <Body>{secondBody}</Body>
            </Box>
          )}
          <Box pt={shouldHideSubmitButton ? 0 : 1}>{renderSubmitButton()}</Box>
        </>
      )}
    </BaseModal>
  );
};

export const GenericTooltipModal = ({
  children,
  title,
  largeTitle,
  onClose,
}) => {
  const modalEl = useRef(null);
  useBackgroundLock(modalEl);

  const handleOnClose = () => {
    onClose();
  };

  if (useKeyPress("Escape")) {
    handleOnClose();
  }

  const closeRef = useRef(null);

  if (useKeyPress("Enter") && document.activeElement === closeRef.current) {
    handleOnClose();
  }

  return (
    <BaseModal
      ref={modalEl}
      closeOnLightboxClick
      onClose={onClose}
      InnerModalComponent={StyledTooltipModal}
    >
      <Box display="flex" justifyContent="space-between">
        <Box mb={1} width="90%">
          {largeTitle ? (
            <H2 align="left" component="h2">
              {title}
            </H2>
          ) : (
            <H4 align="left" component="h2">
              {title}
            </H4>
          )}
        </Box>
        <CloseButton onClick={handleOnClose} closeRef={closeRef} />
      </Box>
      <Box>{children}</Box>
    </BaseModal>
  );
};

export const GenericEmailPreviewModal = ({ children, title, onClose }) => {
  const modalEl = useRef(null);
  useBackgroundLock(modalEl);

  if (useKeyPress("Escape")) {
    onClose();
  }

  return (
    <BaseModal
      ref={modalEl}
      closeOnLightboxClick
      onClose={onClose}
      InnerModalComponent={StyledEmailPreviewModal}
    >
      <Box display="flex" justifyContent="space-between">
        <TitleContainer>
          <H3 align="left">{title}</H3>
        </TitleContainer>
        <CloseButton onClick={onClose} />
      </Box>
      <Box>{children}</Box>
    </BaseModal>
  );
};
