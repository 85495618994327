import { all, call, spawn } from "redux-saga/effects";

import {
  watchFetchSpouseBasicInfo,
  watchOpenModalSpouse,
  watchRemoveSpouse,
  watchSpouseNextPage,
  watchUpdateSpouse,
} from "./spouse";
import {
  watchChildrenNextPage,
  watchHandleOpenModalAddChildren,
  watchHandleRemoveChildren,
  watchUpdateChildren,
} from "./children";
import { watchGiftSaga } from "./gifts";
import {
  watchOpenModalPet,
  watchPetsNextPage,
  watchRemovePet,
  watchUpdatePets,
} from "./pets";
import { watchFetchDonations, watchUpdateDonations } from "./donations";
import {
  watchExecutorsNextPage,
  watchFetchExecutors,
  watchOpenModalAddExecutors,
  watchOpenModalEditExecutors,
  watchRemoveExecutors,
  watchSubmitSpouseExecutorsForm,
} from "./executors";
import {
  watchChildrenGuardiansnNextPage,
  watchHandleRemoveChildrenGuardian,
  watchOpenModalChildrenGuardians,
} from "./children-guardians";
import {
  watchHandleOpenPetGuardianModal,
  watchHandleRemovePetGuardian,
  watchPetGuardiansNextPage,
  watchUpdatePetGuardians,
} from "./pet-guardians";
import {
  watchFetchPersonalAttorneys,
  watchOpenModalPersonalAttorney,
  watchPersonalAttorneyNextPage,
  watchRemovePersonalAttorney,
} from "./personal-attorneys";
import {
  watchFetchPropertyAttorneys,
  watchOpenModalPropertyAttorney,
  watchPropertylAttorneyNextPage,
  watchRemovePropertyAttorney,
} from "./property-attorneys";
import {
  watchApplyPartnerDiscount,
  watchCloseModalBNPLExperiment,
  watchFetchCheckout,
  watchFetchStripeClientSecret,
  watchGetStripeSessionStatus,
  watchOpenModalBNPLExperiment,
  watchRemoveReferral,
  watchResetBNPLExperimentForm,
  watchSubmitCheckout,
  watchSubmitPartnerDiscountCheckout,
  watchUpdatePromoCode,
  watchValidateCaaMembership,
} from "./checkout";
import {
  watchFetchCart,
  watchFetchCartAddon,
  watchRemoveCartAddon,
} from "./cart";
import { watchAddReceipt, watchFetchReceipt } from "./receipt";
import { watchFetchDashboard } from "./dashboard";
import { watchDownloadDocuments, watchFetchDocuments } from "./documents";
import {
  watchFetchLifeProlong,
  watchFetchPainManage,
  watchUpdateLifeProlong,
  watchUpdatePainManage,
} from "./healthcare";
import {
  watchUpdateWishesAshes,
  watchUpdateWishesCeremony,
  watchUpdateWishesResting,
} from "./wishes";
import {
  watchAddInheritanceAgeMilestone,
  watchAllocationChange,
  watchBackupsNextPage,
  watchDistributeAllocationsEvenly,
  watchFetchAllocations,
  watchFetchBackup,
  watchFetchInheritance,
  watchHandleOpenModalCustomCharity,
  watchHandleRemoveBackupOnProgress,
  watchRemoveAllocation,
  watchRemoveBackup,
  watchRemoveCharityBackupOnProgress,
  watchRemoveInheritanceAgeMilestone,
  watchUpdateAllocationCard,
  watchUpdateAllocations,
  watchUpdateBackup,
  watchUpdateCharityBackupOnProgress,
  watchupdateInheritance,
  watchUpdatePieChartData,
  watchUpdatePredecease,
  watchUpdatePrimaryBeneficiary,
} from "./allocations";
import {
  watchDisplayAllocationHelper,
  watchUpdateRangePosition,
  watchUpdateSliderValue,
} from "./allocations-slider";
import {
  watchAddBackupBeneficiary,
  watchAddBeneficiary,
  watchCancelAdding,
} from "./beneficiary";
import { watchFetchPlans, watchUpdatePlans, watchUpgradePlan } from "./plans";
import { watchSkipSection } from "./navigation";
import {
  watchCheckoutPurchaseAnalytics,
  watchClearAppStateAnalytics,
  watchHotjarIdentifyAnalytics,
  watchLatestLocationChangeAnalytics,
  watchLockedProvinceNotifyAnalytics,
  watchLoginAnalytics,
  watchSelectedPlanAnalytics,
  watchSignupAnalytics,
  watchViewCheckoutAnalytics,
  watchViewPlansAnalytics,
  watchViewSignupAnalytics,
} from "./analytics";
import { watchInitializeApp } from "./app-lifecycle";
import { watchSubmitLockedProvinceLead } from "./locked-province";
import {
  watchCheckEmailAvailability,
  watchExpirationTime,
  watchFetchLoginForm,
  watchFetchSignUpForm,
  watchForgotSsoPassword,
  watchSubmitForgotPassword,
  watchSubmitGoogleAuthDelete,
  watchSubmitGoogleAuthLogin,
  watchSubmitLogin,
  watchSubmitLogout,
  watchSubmitResetPassword,
  watchSubmitSignup,
  watchSubmitUpdatePassword,
  watchValidateToken,
} from "./auth";
import {
  watchFetchDOB,
  watchFetchResidence,
  watchLanguageChange,
  watchProvinceChange,
  watchSubmitAboutYou,
  watchSubmitPeopleQuantityIntent,
} from "./about-you";
import watchUserSagas from "./user";
import { watchGetFormData, watchGetSectionData } from "./forms";
import {
  watchClosedModal,
  watchOpenedModal,
  watchSecondarySubmitModal,
  watchSubmitModal,
} from "./modal";
import { watchDisplayChat } from "./customer-service";
import { watchFetchYourAccount } from "./your-account";
import {
  watchInvitedUserGetStarted,
  watchResendInviteRequest,
  watchRevokeInviteRequest,
  watchValidateInviteToken,
} from "./invite";
import { watchInitalizeLanguage } from "./language";
import {
  watchInitializeExperiments,
  watchUpdateStatusInExperiments,
} from "./experiments";
import { watchNotifyAppointee } from "./appointees";
import { watchEditAsset, watchFetchAssetList } from "./asset-list";
import { watchFetchWelcomeBack } from "./welcome-back";
import { watchUpdateReasons } from "./welcome";
import { watchHandleExternalLogin } from "./external-login";
import { watchUpdateUserForAda } from "./ada";
import {
  watchNotifyContact,
  watchOpenModalContact,
  watchRemoveSecondaryContact,
  watchSelectContactFromDropdown,
  watchUpdateStatusInContacts,
} from "./contacts";
import watchDigitalExecutionSagas from "./addons/digital-execution";
import watchBannersSaga from "./banners";
import watchWillFinalizationStepSaga from "./will-finalization-step";

const allSagas = [
  watchInitializeApp,
  watchUpdateSpouse,
  watchFetchSpouseBasicInfo,
  watchUpdateChildren,
  watchGiftSaga,
  watchUpdatePets,
  watchFetchDonations,
  watchUpdateDonations,
  watchFetchExecutors,
  watchOpenModalAddExecutors,
  watchOpenModalEditExecutors,
  watchSubmitSpouseExecutorsForm,
  watchRemoveExecutors,
  watchFetchPersonalAttorneys,
  watchFetchPropertyAttorneys,
  watchPropertylAttorneyNextPage,
  watchUpdatePetGuardians,
  watchFetchCart,
  watchFetchCartAddon,
  watchRemoveCartAddon,
  watchFetchCheckout,
  watchSubmitCheckout,
  watchSubmitPartnerDiscountCheckout,
  watchApplyPartnerDiscount,
  watchUpdatePromoCode,
  watchValidateCaaMembership,
  watchFetchReceipt,
  watchAddReceipt,
  watchFetchDashboard,
  watchFetchDocuments,
  watchDownloadDocuments,
  watchFetchLifeProlong,
  watchUpdateLifeProlong,
  watchFetchPainManage,
  watchUpdatePainManage,
  watchUpdateWishesResting,
  watchUpdateWishesAshes,
  watchUpdateWishesCeremony,
  watchFetchAllocations,
  watchAllocationChange,
  watchFetchBackup,
  watchUpdateAllocations,
  watchUpdatePrimaryBeneficiary,
  watchUpdatePredecease,
  watchupdateInheritance,
  watchFetchInheritance,
  watchUpdateBackup,
  watchUpdateRangePosition,
  watchUpdateSliderValue,
  watchDistributeAllocationsEvenly,
  watchRemoveAllocation,
  watchRemoveBackup,
  watchRemoveInheritanceAgeMilestone,
  watchAddInheritanceAgeMilestone,
  watchAddBackupBeneficiary,
  watchAddBeneficiary,
  watchCancelAdding,
  watchDisplayAllocationHelper,
  watchDisplayChat,
  watchFetchPlans,
  watchUpdatePlans,
  watchUpgradePlan,
  watchSkipSection,
  watchSelectedPlanAnalytics,
  watchCheckoutPurchaseAnalytics,
  watchViewCheckoutAnalytics,
  watchViewPlansAnalytics,
  watchValidateToken,
  watchSubmitSignup,
  watchSubmitLogin,
  watchSubmitGoogleAuthLogin,
  watchSubmitLogout,
  watchSubmitLockedProvinceLead,
  watchSubmitForgotPassword,
  watchSubmitResetPassword,
  watchSubmitUpdatePassword,
  watchLockedProvinceNotifyAnalytics,
  watchViewSignupAnalytics,
  watchSignupAnalytics,
  watchLatestLocationChangeAnalytics,
  watchHotjarIdentifyAnalytics,
  watchClearAppStateAnalytics,
  watchSubmitAboutYou,
  watchFetchResidence,
  watchProvinceChange,
  watchLanguageChange,
  watchSubmitPeopleQuantityIntent,
  watchGetFormData,
  watchGetSectionData,
  watchRemoveReferral,
  watchSubmitModal,
  watchSecondarySubmitModal,
  watchFetchYourAccount,
  watchRevokeInviteRequest,
  watchValidateInviteToken,
  watchInvitedUserGetStarted,
  watchResendInviteRequest,
  watchInitalizeLanguage,
  watchInitializeExperiments,
  watchNotifyAppointee,
  watchFetchAssetList,
  watchEditAsset,
  watchFetchWelcomeBack,
  watchUpdateAllocationCard,
  watchExpirationTime,
  watchFetchSignUpForm,
  watchFetchLoginForm,
  watchOpenModalBNPLExperiment,
  watchResetBNPLExperimentForm,
  watchCloseModalBNPLExperiment,
  watchUpdateReasons,
  watchFetchDOB,
  watchOpenedModal,
  watchClosedModal,
  watchUpdateStatusInExperiments,
  watchUpdateStatusInContacts,
  watchLoginAnalytics,
  watchCheckEmailAvailability,
  watchGetStripeSessionStatus,
  watchFetchStripeClientSecret,
  watchHandleExternalLogin,
  watchForgotSsoPassword,
  watchSubmitGoogleAuthDelete,
  watchHandleOpenModalAddChildren,
  watchHandleRemoveChildren,
  watchOpenModalSpouse,
  watchRemoveSpouse,
  watchUpdateUserForAda,
  watchOpenModalPet,
  watchRemovePet,
  watchOpenModalChildrenGuardians,
  watchHandleOpenPetGuardianModal,
  watchHandleRemovePetGuardian,
  watchHandleRemoveChildrenGuardian,
  watchOpenModalPropertyAttorney,
  watchRemovePropertyAttorney,
  watchOpenModalPersonalAttorney,
  watchRemovePersonalAttorney,
  watchSpouseNextPage,
  watchChildrenNextPage,
  watchPetsNextPage,
  watchChildrenGuardiansnNextPage,
  watchPersonalAttorneyNextPage,
  watchPetGuardiansNextPage,
  watchExecutorsNextPage,
  watchOpenModalContact,
  watchNotifyContact,
  watchSelectContactFromDropdown,
  watchRemoveSecondaryContact,
  watchUpdatePieChartData,
  watchBackupsNextPage,
  watchHandleRemoveBackupOnProgress,
  watchUpdateCharityBackupOnProgress,
  watchRemoveCharityBackupOnProgress,
  watchHandleOpenModalCustomCharity,
  watchDigitalExecutionSagas,
  watchUserSagas,
  watchBannersSaga,
  watchWillFinalizationStepSaga,
];

export default function* rootSaga() {
  // takes all of the sagas and wraps them with error handling
  // https://redux-saga.js.org/docs/advanced/RootSaga.html#keeping-everything-alive

  yield all(
    allSagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            // TODO: debug why not firing
            const errorMessage = `An error occurred in the ${saga.name} saga.`;
            if (window.env.FE_ENV !== "production") {
              console.error(errorMessage, e, e.stack);
            }
            if (window.Rollbar) {
              yield call(window.Rollbar.error, errorMessage, e, e.stack);
            }
          }
        }
      }),
    ),
  );
}
