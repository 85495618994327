import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";
import { getFormValues } from "redux-form";
import { selectHasLoadedDataById, selectIsLoadingById } from "./requests";
import { selectTranslations } from "./translations";
import { selectIsFieldComplete } from "./utils/progress";
import { buildFullName } from "../utilities/name";
import { selectIsFrenchSelected } from "./language";
import { selectQuestions } from "./faq";
import { aboutYouForms } from "../constants/navigation";
import {
  selectAllProvinces,
  selectSelectedProvince,
  selectIsFrenchAvailableInSelectedProvince,
  selectIsEssentialsOnlyProvinceSelected,
  selectIsCurrentProvinceSelectedByCode,
} from "./provinces";
import { isAgeOfMajority } from "./utils/validate";
import {
  FIRST_NAME_FIELD_ID,
  MIDDLE_NAME_FIELD_ID,
  LAST_NAME_FIELD_ID,
  GENDER_FIELD_ID,
  DOB_FIELD_ID,
  DOB_FORM_ID,
  PROVINCE_FIELD_ID,
  RESIDENCE_FIELD_ID,
  RESIDENCE_FORM_ID,
  GENDER_FORM_ID,
  LANGUAGE_FORM_ID,
  FULL_NAME_FORM_ID,
  LANGUAGE_FIELD_ID,
  PEOPLE_QUANTITY_INTENT_FORM_ID,
  RECOMMENDATION_FORM_ID,
  PHONE_NUMBER_FIELD_ID,
} from "../constants/forms";
import {
  translateInput,
  selectIsSectionComplete,
  selectSectionCurrentPageNumber,
  selectSectionPercentComplete,
  selectSectionTranslations,
  selectSectionCurrentPath,
} from "./utils/section-summaries";
import {
  DASHBOARD_PATH,
  MARITAL_STATUS_PATH,
  GENDER_PATH,
  PLANS_PATH,
  INTENDED_USE_PATH,
  OVERVIEW_HOW_IT_WORKS_PATH,
  SPOUSE_PATH,
} from "../constants/routes";
import {
  selectHasUserPaid,
  selectPremiumPrice,
  selectPremiumStrikeThroughPrice,
  selectFamilyPriceForTwoAdults,
  selectFamilyStrikeThroughPriceForTwoAdults,
  selectAllBundlePrices,
  selectShowExclusiveOffer,
} from "./plans";
import { selectHasSpouse } from "./spouse";
import { replaceBundleSizeOptions } from "../utilities/helpers";
import { selectMarriedInReasons, selectReasons } from "./welcome";
import { MARRIED_OR_COMMON_LAW } from "../constants/reasons";
import { selectDisplayExperimentRepositionPlans } from "./experiments";

// TODO: eliminate circular dep
const selectIsLoggedIn = R.pathOr(false, ["auth", "loggedIn"]);
const selectUserEmail = R.pathOr("", ["auth", "uid"]);

export const selectAboutYouData = R.pathOr({}, ["aboutYou"]);

export const selectResidenceForm = createSelector(
  selectAboutYouData,
  (aboutYouData) => ({
    [PROVINCE_FIELD_ID]: aboutYouData[PROVINCE_FIELD_ID],
    [RESIDENCE_FIELD_ID]: aboutYouData[RESIDENCE_FIELD_ID],
    [PHONE_NUMBER_FIELD_ID]: aboutYouData[PHONE_NUMBER_FIELD_ID],
  }),
);
export const selectLanguageForm = createSelector(
  selectAboutYouData,
  (aboutYouData) => ({
    [LANGUAGE_FORM_ID]: aboutYouData[LANGUAGE_FORM_ID],
  }),
);

export const selectFullNameForm = createSelector(
  selectAboutYouData,
  (aboutYouData) => ({
    [FIRST_NAME_FIELD_ID]: aboutYouData[FIRST_NAME_FIELD_ID],
    [MIDDLE_NAME_FIELD_ID]: aboutYouData[MIDDLE_NAME_FIELD_ID],
    [LAST_NAME_FIELD_ID]: aboutYouData[LAST_NAME_FIELD_ID],
  }),
);
export const selectDobForm = createSelector(
  selectAboutYouData,
  (aboutYouData) => ({
    [DOB_FIELD_ID]: aboutYouData[DOB_FIELD_ID],
  }),
);
export const selectGenderForm = createSelector(
  selectAboutYouData,
  (aboutYouData) => {
    return {
      [GENDER_FORM_ID]: aboutYouData[GENDER_FORM_ID],
    };
  },
);

export const selectLanguageCode = R.pathOr("en", ["aboutYou", "lang"]);
export const selectFirstName = R.pathOr(undefined, [
  "aboutYou",
  FIRST_NAME_FIELD_ID,
]);
export const selectLastName = R.pathOr(undefined, [
  "aboutYou",
  LAST_NAME_FIELD_ID,
]);
export const selectDateOfBirth = R.pathOr("", ["aboutYou", "dateOfBirth"]);
export const selectFullName = createSelector(selectAboutYouData, (data) => {
  const fullName = R.props([
    FIRST_NAME_FIELD_ID,
    MIDDLE_NAME_FIELD_ID,
    LAST_NAME_FIELD_ID,
  ])(data);

  const [firstName, middleName, lastName] = fullName;
  return buildFullName(firstName, middleName, lastName);
});

const selectFormSelectedProvince = createSelector(
  getFormValues(RESIDENCE_FORM_ID),
  selectAllProvinces,
  (formValues, allProvinces) => {
    const provinceId = R.propOr("", PROVINCE_FIELD_ID, formValues);
    const selectedProvince = R.find(R.propEq("id", provinceId), allProvinces);
    return selectedProvince;
  },
);

export const selectHasReasonsOrResidence = createSelector(
  selectReasons,
  selectSelectedProvince,
  (reasons, selectedProvince) => {
    // we have to check if the selectedProvince has any keys because when the user logs in the
    // selectedProvince selector returns an empty object the first time it's called but the
    // other ones it returns null
    return (
      reasons.length > 0 ||
      (selectedProvince != null && Object.keys(selectedProvince).length > 0)
    );
  },
);

export const selectIsCurrentProvinceFormSelectedByCode = (code) =>
  createSelector(
    selectFormSelectedProvince,
    (formProvince) => code === R.propOr(false, "code", formProvince),
  );

// When user is unauthenticated, need to use form state to determine selected province
// won't have access to BE selected province value until after sign up
export const selectFormIsFrenchAvailableInSelectedProvince = createSelector(
  selectFormSelectedProvince,
  (selectedProvince) => {
    if (!selectedProvince) return false;
    return R.propOr(false, "frenchAvailable", selectedProvince);
  },
);

// Once user is authenticated, check their selected province from the BE
// instead of using their redux-form selected province value
// redux-form selected value won't always be accessible for authenticated users
// eg: section summary selectors on dashboard - redux-form won't be initialized with data
const selectIsLanguageAvailable = createSelector(
  selectIsLoggedIn,
  selectIsFrenchAvailableInSelectedProvince,
  selectFormIsFrenchAvailableInSelectedProvince,
  (isAuthenticated, isFrenchAvailable, isFormFrenchAvailable) => {
    if (isAuthenticated) {
      return isFrenchAvailable;
    }
    return isFormFrenchAvailable;
  },
);

export const selectIsFullNameComplete = createSelector(
  selectIsFieldComplete(selectAboutYouData, FIRST_NAME_FIELD_ID),
  selectIsFieldComplete(selectAboutYouData, LAST_NAME_FIELD_ID),
  (...allValues) => allValues.every((val) => val === true),
);

export const selectIsResidenceComplete = createSelector(
  selectIsFieldComplete(selectAboutYouData, PROVINCE_FIELD_ID),
  selectIsFieldComplete(selectAboutYouData, RESIDENCE_FIELD_ID),
  (...allValues) => allValues.every((val) => val === true),
);

export const selectAboutYouFieldsComplete = createSelector(
  selectIsLanguageAvailable,
  selectIsResidenceComplete,
  selectIsFieldComplete(selectAboutYouData, LANGUAGE_FIELD_ID),
  selectIsFullNameComplete,
  selectIsFieldComplete(selectAboutYouData, DOB_FIELD_ID),
  selectIsFieldComplete(selectAboutYouData, GENDER_FIELD_ID),
  (
    isLanguageAvailable,
    isResidenceComplete,
    isLanguageComplete,
    isFullNameComplete,
    isDateOfBirthComplete,
    isGenderComplete,
  ) => {
    return [
      isResidenceComplete,
      isFullNameComplete,
      isDateOfBirthComplete,
      isGenderComplete,
    ];
  },
);

const selectAboutYouIsCompleteMap = createSelector(
  selectIsResidenceComplete,
  selectIsFullNameComplete,
  selectIsFieldComplete(selectAboutYouData, DOB_FIELD_ID),
  selectIsFieldComplete(selectAboutYouData, GENDER_FIELD_ID),
  (
    isResidenceComplete,
    isFullNameComplete,
    isDateOfBirthComplete,
    isGenderComplete,
  ) => ({
    [RESIDENCE_FORM_ID]: isResidenceComplete,
    [FULL_NAME_FORM_ID]: isFullNameComplete,
    [DOB_FORM_ID]: isDateOfBirthComplete,
    [GENDER_FORM_ID]: isGenderComplete,
  }),
);

export const selectIsAboutYouComplete = selectIsSectionComplete(
  selectAboutYouFieldsComplete,
);

export const selectAboutYouCurrentPageNumber = selectSectionCurrentPageNumber(
  selectAboutYouFieldsComplete,
);

export const selectAboutYouTotalPages = () => 4;

export const selectAboutYouPercentComplete = selectSectionPercentComplete(
  selectAboutYouCurrentPageNumber,
  selectAboutYouTotalPages,
);

export const selectAboutYouNextForm = R.path([
  "navigation",
  "nextForm",
  "aboutYou",
]);

export const selectAboutYouCurrentPath = selectSectionCurrentPath(
  selectAboutYouNextForm,
);

const selectProvinceName = createSelector(
  selectIsLoggedIn,
  selectFormSelectedProvince,
  selectSelectedProvince,
  selectTranslations(["global", "provinces"]),
  (isLoggedIn, formSelectedProvince, selectedProvince, translations) => {
    if (isLoggedIn && selectedProvince)
      return translations[selectedProvince.code];
    if (!formSelectedProvince) return "";
    return translations[formSelectedProvince.code];
  },
);

const selectAboutYouSectionSummaryTranslations = createSelector(
  selectProvinceName,
  selectTranslations(["global", "navigation", "aboutYou"]),
  (selectedProvinceName, translations) => ({
    ...translations,
    selectedProvinceName,
  }),
);

const selectAboutYouFormSummaries = createSelector(
  selectAboutYouData,
  selectAboutYouIsCompleteMap,
  selectIsLanguageAvailable,
  selectAboutYouSectionSummaryTranslations,
  (aboutYouData, isCompleteMap, isLanguageAvailable, translations) => {
    const formSummaries = aboutYouForms.reduce((summaryList, currentForm) => {
      // exclude Language form from summary unless French available
      if (currentForm.id === LANGUAGE_FORM_ID && !isLanguageAvailable) {
        return summaryList;
      }

      const fields = currentForm.fields.map(({ key }) => ({
        label: translations.summaryLabel[key],
        value: translateInput(key, aboutYouData, translations),
      }));

      const formSummary = {
        id: currentForm.id,
        isComplete: isCompleteMap[currentForm.id],
        path: currentForm.path,
        fields,
      };
      return [...summaryList, formSummary];
    }, []);

    // hides incomplete form summaries (legacy users could sign in and see form summary on dashboard)
    return R.pipe(R.filter((form) => form.isComplete))(formSummaries);
  },
);

const selectAboutYouTranslations = selectSectionTranslations(
  selectIsAboutYouComplete,
  selectAboutYouCurrentPageNumber,
  ["aboutYou", "residence"],
  selectAboutYouTotalPages,
  () => false,
);

const selectFormDateOfBirth = createSelector(
  getFormValues(DOB_FORM_ID),
  (formValues) => R.propOr("", DOB_FIELD_ID, formValues),
);

const selectIsProvinceLocked = createSelector(
  selectFormSelectedProvince,
  (selectedProvince) => {
    // When form loads without input we do not want to display the infoBox
    if (!selectedProvince) return false;
    return selectedProvince.status === "locked";
  },
);

// Required for Residence Page for QC content?
// TODO: potentially remove for release
const selectFormIsQuebecSelected = createSelector(
  selectFormSelectedProvince,
  (selectedProvince) => {
    if (!selectedProvince) return false;
    return selectedProvince.code === "QC";
  },
);

const selectFormIsNewBrunswickSelected = createSelector(
  selectFormSelectedProvince,
  (selectedProvince) => {
    if (!selectedProvince) return false;
    return selectedProvince.code === "NB";
  },
);

const selectResidenceTranslations = createSelector(
  selectIsProvinceLocked,
  selectTranslations(["global", RESIDENCE_FORM_ID]),
  (isProvinceLocked, translations) => {
    return {
      ...translations,
      button: {
        ...translations.button,
        submit: isProvinceLocked
          ? translations.button.lockedProvince
          : translations.button.continue,
      },
    };
  },
);

const selectIntendedUseTranslations = createSelector(
  selectDisplayExperimentRepositionPlans,
  selectTranslations(["global", `${PEOPLE_QUANTITY_INTENT_FORM_ID}Deprecated`]),
  selectTranslations(["global", PEOPLE_QUANTITY_INTENT_FORM_ID]),
  selectTranslations(["global", `${PEOPLE_QUANTITY_INTENT_FORM_ID}Married`]),
  selectMarriedInReasons,
  (
    displayExperimentRepositionPlans,
    intendedUseDeprecatedTranslations,
    intendedUseTranslations,
    intendedUseMarriedTranslations,
    marriedInReasons,
  ) => {
    if (displayExperimentRepositionPlans) {
      return marriedInReasons
        ? intendedUseMarriedTranslations
        : intendedUseTranslations;
    }
    return intendedUseDeprecatedTranslations;
  },
);

export const selectProvincialAgeOfMajority = createSelector(
  selectSelectedProvince,
  selectFormDateOfBirth,
  selectFormSelectedProvince,
  (selectedProvince, dateOfBirth) => {
    const province = selectedProvince;
    if (!province) {
      return null;
    }
    const ageOfMajority = R.propOr(19, "ageOfMajority", province);
    return isAgeOfMajority(dateOfBirth, ageOfMajority);
  },
);

export const selectPeopleQuantityIntent = R.pathOr(undefined, [
  "aboutYou",
  "peopleQuantityIntent",
]);

export const selectAboutYouNextPath = createSelector(
  selectHasUserPaid,
  selectPeopleQuantityIntent,
  selectIsEssentialsOnlyProvinceSelected,
  (hasUserPaid, peopleQuantityIntent, isEssentialsOnlyProvinceSelected) => {
    if (hasUserPaid || peopleQuantityIntent) {
      return DASHBOARD_PATH;
    }

    return isEssentialsOnlyProvinceSelected ? PLANS_PATH : INTENDED_USE_PATH;
  },
);

const selectAboutYouContinuePath = createSelector(
  selectHasReasonsOrResidence,
  selectAboutYouCurrentPath,
  (hasReasonsOrResidence, aboutYouCurrentPath) =>
    hasReasonsOrResidence ? aboutYouCurrentPath : OVERVIEW_HOW_IT_WORKS_PATH,
);

const selectFamilyRecommendationQuestions = selectQuestions([
  "familyPlanDetails",
  "planDifferences",
  "planSharing",
  "poaBenefits",
  "jointWill",
]);

export const familyRecommendationPageTranslations = selectTranslations([
  "global",
  "plansCards",
  "familyRecommendation",
  "plansAndRecommendationsPages",
]);

const selectFamilyRecommendationTranslations = createSelector(
  selectFamilyRecommendationQuestions,
  familyRecommendationPageTranslations,
  selectAllBundlePrices,
  selectShowExclusiveOffer,
  selectReasons,
  (
    paramsQuestions,
    paramTranslations,
    bundlePrices,
    showExclusiveOffer,
    reasons,
  ) => {
    const bundleSizeOptions = replaceBundleSizeOptions(
      paramTranslations,
      bundlePrices,
      selectLanguageCode,
      showExclusiveOffer,
    );
    const marriedSelected = reasons.some(
      (reason) => reason === MARRIED_OR_COMMON_LAW,
    );
    const pageTitle = marriedSelected
      ? paramTranslations.pageTitleMarried
      : paramTranslations.pageTitle;
    return {
      ...paramTranslations,
      pageTitle,
      bundleSizeOptions,
      questions: paramsQuestions,
    };
  },
);

const selectPremiumRecommendationQuestions = createSelector(() => {
  return selectQuestions([
    "planDifferences",
    "planSharing",
    "poaBenefits",
    "jointWill",
  ]);
});

const premiumRecommendationPageTranslations = selectTranslations([
  "global",
  "plansCards",
  "premiumRecommendation",
  "plansAndRecommendationsPages",
]);

const selectPremiumRecommendationTranslations = createSelector(
  selectPremiumRecommendationQuestions(),
  premiumRecommendationPageTranslations,
  (paramsQuestions, paramTranslations) => {
    return {
      ...paramTranslations,
      questions: paramsQuestions,
    };
  },
);

export const selectAboutYouStatus = createStructuredSelector({
  sectionKey: () => "aboutYou",
  isComplete: selectIsAboutYouComplete,
  progress: selectAboutYouPercentComplete,
  continuePath: selectAboutYouContinuePath,
  formSummaries: selectAboutYouFormSummaries,
  translations: selectAboutYouTranslations,
  sectionLastPage: () => GENDER_PATH,
  sectionNextPath: selectAboutYouNextPath,
});

export const selectIntendedUsePreviousPath = createSelector(
  selectHasSpouse,
  (hasSpouse) => (hasSpouse ? SPOUSE_PATH : MARITAL_STATUS_PATH),
);

export const fullNamePageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(FULL_NAME_FORM_ID),
  isLoading: selectIsLoadingById(FULL_NAME_FORM_ID),
  translations: selectTranslations(["global", FULL_NAME_FORM_ID]),
});
export const dateOfBirthPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(DOB_FORM_ID),
  isLoading: selectIsLoadingById(DOB_FORM_ID),
  translations: selectTranslations(["global", DOB_FORM_ID]),
});
export const residencePageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(RESIDENCE_FORM_ID),
  isLoading: selectIsLoadingById(RESIDENCE_FORM_ID),
  provinces: selectAllProvinces,
  userEmail: selectUserEmail,
  isProvinceLocked: selectIsProvinceLocked,
  translations: selectResidenceTranslations,
  isNewBrunswickSelected: selectFormIsNewBrunswickSelected,
  isQuebecSelected: selectFormIsQuebecSelected,
  isFrenchSelected: selectIsFrenchSelected,
});
export const genderPageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(GENDER_FORM_ID),
  isLoading: selectIsLoadingById(GENDER_FORM_ID),
  translations: selectTranslations(["global", GENDER_FORM_ID]),
});

// TODO: Once new Intended use page is permanent, we can remove this prop
export const intendedUsePageProps = createStructuredSelector({
  hasLoaded: selectHasLoadedDataById(PEOPLE_QUANTITY_INTENT_FORM_ID),
  isLoading: selectIsLoadingById(PEOPLE_QUANTITY_INTENT_FORM_ID),
  previousPath: selectIntendedUsePreviousPath,
  translations: selectIntendedUseTranslations,
});

export const familyRecommendationPageProps = createStructuredSelector({
  translations: selectFamilyRecommendationTranslations,
  isQuebecSelected: selectIsCurrentProvinceSelectedByCode("QC"),
  hasLoaded: selectHasLoadedDataById(RECOMMENDATION_FORM_ID),
  isLoading: selectIsLoadingById(RECOMMENDATION_FORM_ID),
  showExclusiveOffer: selectShowExclusiveOffer,
  bundlePrices: selectAllBundlePrices,
  price: selectFamilyPriceForTwoAdults,
  strikeThroughPrice: selectFamilyStrikeThroughPriceForTwoAdults,
});

export const premiumRecommendationPageProps = createStructuredSelector({
  translations: selectPremiumRecommendationTranslations,
  isQuebecSelected: selectIsCurrentProvinceSelectedByCode("QC"),
  hasLoaded: selectHasLoadedDataById(RECOMMENDATION_FORM_ID),
  isLoading: selectIsLoadingById(RECOMMENDATION_FORM_ID),
  showExclusiveOffer: selectShowExclusiveOffer,
  price: selectPremiumPrice,
  strikeThroughPrice: selectPremiumStrikeThroughPrice,
});
