import React, { useCallback } from "react";
import FinalizeWillStepAccordion from "../ui/FinalizeWillStepAccordion";
import { Body } from "../ui/Typography";
import { Box } from "@material-ui/core";
import { SecondaryButton } from "../ui/Button";
import { useDispatch, useSelector } from "react-redux";
import { selectRailsUserId, selectUserEmail } from "../../selectors/auth";
import { encodedExecutionURI } from "../../utilities/helpers";
import { selectTranslations } from "../../selectors/translations";
import { selectLanguageCode } from "../../selectors/language";
import { analyticsFinalizeWillStepCtaClicked } from "../../actions/analytics";

const SignAndWitness = ({
  translation,
  provinceCode,
  completedAt,
  onCheck,
}: SignAndWitnessPropTypes) => {
  const { body, title, cta } = translation;
  const stepName = "sign_document";
  const email: string = useSelector(selectUserEmail);
  const userId = useSelector(selectRailsUserId) as number;
  const languageCode = useSelector(selectLanguageCode) as string;

  const provinceCodesTranslations = useSelector(
    selectTranslations(["provinces"]),
  );
  const province = provinceCodesTranslations[provinceCode];

  const ctaLink = encodedExecutionURI(
    true,
    email,
    userId,
    province,
    languageCode,
  );

  const dispatch = useDispatch();

  const handleClick = useCallback(() => {
    dispatch(
      analyticsFinalizeWillStepCtaClicked({
        user_id: userId,
        step_name: stepName,
        key: "register",
      }),
    );
    window.open(ctaLink, "_blank");
  }, [ctaLink]);

  return (
    <FinalizeWillStepAccordion
      label={title}
      onCheck={onCheck}
      noBottomDivider
      defaultChecked={!!completedAt}
      userId={userId}
      stepName={stepName}
    >
      <Body color="textSecondary" dangerouslySetInnerHTML={{ __html: body }} />
      <Box mt={1} mb={1}>
        <SecondaryButton
          text={cta}
          onClick={handleClick}
          fullWidth
          showExternalLinkIconRight
        />
      </Box>
    </FinalizeWillStepAccordion>
  );
};

export default SignAndWitness;
