import React, { useEffect } from "react";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

import { plansPageProps } from "../../selectors/plans";
import {
  fetchPlans as fetchPlansAction,
  updatePlans as updatePlansAction,
  setLoadingPlan as setLoadingPlanAction,
  expandCollapseTableRow as expandCollapseTableRowAction,
} from "../../actions/plans";
import { analyticsClickExternalLink as analyticsClickExternalLinkAction } from "../../actions/analytics";
import { displayTooltipModal as displayTooltipModalAction } from "../../actions/tooltip-modal";

import { ESSENTIALS_PLAN_GUIDANCE_PATH } from "../../constants/routes";
import { PRODUCTS_FORM_ID } from "../../constants/forms";

import PlansForm from "../../components/forms/PlansForm";
import LoadingSpinner from "../../components/ui/LoadingSpinner";

const PlansPage = ({
  fetchPlans,
  hasLoaded,
  updatePlans,
  analyticsClickExternalLink,
  setLoadingPlan,
  displayTooltipModal,
  expandCollapseTableRow,
  ...restOfProps
}) => {
  useEffect(() => {
    fetchPlans(PRODUCTS_FORM_ID);
  }, [fetchPlans]);

  if (!hasLoaded) return <LoadingSpinner />;
  return (
    <PlansForm
      updatePlans={updatePlans}
      continueLink={ESSENTIALS_PLAN_GUIDANCE_PATH}
      analyticsClickExternalLink={analyticsClickExternalLink}
      setLoadingPlan={setLoadingPlan}
      displayTooltipModal={displayTooltipModal}
      expandCollapseTableRow={expandCollapseTableRow}
      {...restOfProps}
    />
  );
};

export default connect(plansPageProps, {
  updatePlans: updatePlansAction,
  fetchPlans: fetchPlansAction,
  analyticsClickExternalLink: analyticsClickExternalLinkAction,
  setLoadingPlan: setLoadingPlanAction,
  displayTooltipModal: displayTooltipModalAction,
  goBack,
  expandCollapseTableRow: expandCollapseTableRowAction,
})(PlansPage);
