import R from "ramda";
import { createSelector } from "reselect";
import {
  selectIsCurrentProvinceSelectedByCode,
  selectIsEssentialsOnlyProvinceSelected,
} from "./provinces";
import { selectHasUserPaid } from "./plans";
import { featureRepositionPlansEnabled } from "../utilities/featureFlags";
import { selectHasPartnerDiscount } from "./partner-discount";

const selectUserId = R.pathOr("", ["auth", "id"]);
const selectInviteToken = R.pathOr("", ["auth", "inviteToken"]);
const selectIsInvitedUser = createSelector(
  selectInviteToken,
  (token) => !!token,
);

const LOCAL_STORED_EXPERIMENTS = [];

export const selectAllExperiments = R.propOr({}, "experiments");

export const selectLocalStorageExperimentsValues = () => {
  const localStorageExperimentsValues = [];
  LOCAL_STORED_EXPERIMENTS.forEach((experimentName) => {
    const experimentId = R.pathOr(null, ["env", experimentName])(window);
    const variantValue = localStorage.getItem(experimentName);
    if (experimentId && variantValue) {
      localStorageExperimentsValues.push({
        [experimentId]: variantValue,
      });
    }
  });
  return localStorageExperimentsValues;
};

export const selectDisplayExperiment = (experimentName = "") =>
  createSelector(selectUserId, (userId) => {
    if (!experimentName) return false;

    const upperCaseName = experimentName.toString().toUpperCase();
    const envVariableValue = window.env[upperCaseName] || 0;
    const percentage = parseInt(envVariableValue, 10);

    if (Number.isNaN(percentage) || percentage <= 0) return false;
    if (percentage >= 100) return true;

    // Generate a unique hash-based value (1-10)
    const experimentValue = experimentName
      .split("")
      .reduce((acc, char, index) => acc + char.charCodeAt(0) * (index + 1), 0);

    const hashedValue = ((experimentValue + userId) % 10) + 1;
    const shouldDisplay = hashedValue <= percentage / 10;

    if (window.env.FE_ENV !== "production") {
      const controlOrVariant = shouldDisplay ? "Variant" : "Control";
      console.info(
        `Experiment '${experimentName}' - Hash (${hashedValue}) - Display?: ${shouldDisplay} (${controlOrVariant})`,
      );
    }

    return shouldDisplay;
  });

// ----------- Hard Paywall Experiment ------------
const getHardPaywallExperimentId = () =>
  R.pathOr(48, ["env", "EXPERIMENT_HARD_PAYWALL_ID"])(window);

const getHardPaywallExperimentControlVariation = () =>
  R.pathOr("1", ["env", "EXPERIMENT_HARD_PAYWALL_DEFAULT_VARIATION"])(window);

const selectHardPaywallExperimentVariation = createSelector(
  selectAllExperiments,
  (experiments) => {
    const experimentId = getHardPaywallExperimentId();
    const controlVariation = getHardPaywallExperimentControlVariation();
    const variation = experiments[experimentId];
    return variation || controlVariation;
  },
);

export const selectShouldDisplayHardPaywallExperiment = createSelector(
  selectHardPaywallExperimentVariation,
  (variation) => {
    if (window.env.FEATURE_ESS_PREM_DOC_PAGE === "enabled") {
      return variation === "2";
    }
    return false;
  },
);

// ----------- Reposition Plan Experiment ------------
const selectIsUserOutOfExperimentRepositionPlans = createSelector(
  selectHasUserPaid,
  selectIsInvitedUser,
  selectIsEssentialsOnlyProvinceSelected,
  selectIsCurrentProvinceSelectedByCode("QC"),
  selectHasPartnerDiscount,
  (
    hasPaid,
    isInvitedUser,
    isEssentialsOnly,
    isQuebecSelected,
    hasPartnerDiscount,
  ) => {
    // Users who meet the following criteria are out of the experiment
    // and will not count to the experiment's conversion rate analytics
    if (
      !featureRepositionPlansEnabled() ||
      hasPaid ||
      isInvitedUser ||
      isEssentialsOnly ||
      isQuebecSelected ||
      hasPartnerDiscount
    ) {
      return true;
    }
    return false;
  },
);

export const selectDisplayExperimentRepositionPlans = createSelector(
  selectIsUserOutOfExperimentRepositionPlans,
  selectDisplayExperiment("AB_REPOSITION_PLANS"),
  (isUserOutOfExperimentRepositionPlans, shouldDisplayExperiment) => {
    if (isUserOutOfExperimentRepositionPlans) {
      return false;
    }
    return shouldDisplayExperiment;
  },
);
