import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { goBack as goBackRouter } from "connected-react-router";

import { Box, Grid, Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import { TextButton } from "../../components/ui/Button";
import {
  Body,
  H1,
  SmallTextLink,
  TextLink,
} from "../../components/ui/Typography";
import { fetchPlans as fetchPlansAction } from "../../actions/plans";

import LoadingSpinner from "../../components/ui/LoadingSpinner";
import { PLANS_PATH } from "../../constants/routes";
import { RECOMMENDATION_FORM_ID } from "../../constants/forms";
import { essentialsPlanGuidanceProps } from "../../selectors/essentials-plan-guidance";
import { LegalEssentialsPlanCard } from "../../components/plans/PlanCard";
import EssentialsPreviewImage from "../../components/ui/images/EssentialsPreviewImage";
import { colorPalette } from "../../config/Theme";
import TrustIcons from "../../components/ui/icons/TrustIcons";
import UnlimitedUpdatesInfoBox from "../../components/ui/UnlimitedUpdatesInfoBox";
import { familyRecommendationPageTranslations } from "../../selectors/about-you";
import { BaseAccordion } from "../../components/ui/Accordion";
import { selectPlansGuidanceQuestionsTranslations } from "../../selectors/plans";
import { PageWrapper } from "../Layout";

const EssentialsPlanGuidancePage = ({
  fetchPlans,
  goBack,
  hasLoaded,
  translations,
  price,
  strikeThroughPrice,
  nextSectionPath,
  showExclusiveOffer,
  isPremiumPlanAvailable,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const { unlimitedUpdatesInfoBox } = useSelector(
    familyRecommendationPageTranslations,
  );
  const plansGuidanceQuestionsTranslations = useSelector(
    selectPlansGuidanceQuestionsTranslations,
  );

  useEffect(() => {
    fetchPlans(RECOMMENDATION_FORM_ID);
  }, [fetchPlans]);

  if (!hasLoaded) {
    return <LoadingSpinner />;
  }

  return (
    <PageWrapper flexGrow={1}>
      <Box
        mx={isDesktop && 2}
        mt={isDesktop ? 4.5 : 1.5}
        mb={isDesktop ? 10 : 3.5}
      >
        <TextButton
          text={translations.button.previous}
          onClick={goBack}
          displayArrowLeft
          color={colorPalette.willfulGrey}
        />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          mt={!isDesktop && 1.5}
        >
          <Grid item xl={8}>
            <H1>{translations.pageTitle}</H1>
            <Body align="center">{translations.description}</Body>
            {isPremiumPlanAvailable && (
              <Box align="center">
                <TextLink text={translations.upgradeLinkText} to={PLANS_PATH} />
              </Box>
            )}
          </Grid>
        </Box>

        <Box
          display="flex"
          flexDirection={isDesktop ? "row" : "column"}
          justifyContent="center"
          mt={isDesktop ? 3 : 1.5}
        >
          {/* Left Column */}
          <Grid item lg={8} md={12}>
            <Box mt={isDesktop && 1.5} textAlign="center">
              <EssentialsPreviewImage isDesktop={isDesktop} />
            </Box>
          </Grid>
          {/* End Left Column */}
          {/* Right Column */}
          <Grid item lg={4} md={12}>
            <Box mt={isDesktop ? 0 : 3}>
              <LegalEssentialsPlanCard
                price={price}
                strikeThroughPrice={strikeThroughPrice}
                noMaxWidth={!isDesktop}
                showExclusiveOffer={showExclusiveOffer}
                noCta
                showCheckoutButton
              />
              <Box mt={1.5} textAlign="center">
                <Hidden mdDown>
                  {/* Desktop */}
                  <SmallTextLink
                    className="qa-continue"
                    to={nextSectionPath}
                    text={translations.skipButton}
                  />
                </Hidden>
                <Hidden lgUp>
                  {/* Tablet */}
                  <TextLink
                    className="qa-continue"
                    to={nextSectionPath}
                    text={translations.skipButton}
                  />
                </Hidden>
                <TrustIcons
                  translations={translations}
                  shouldIncludeThirtyDay
                  shouldIncludeDragonsDen
                  centerLogos
                />
              </Box>
            </Box>
          </Grid>
          {/* End Right Column */}
        </Box>

        <Box mt={isDesktop && 4} display="flex" justifyContent="center">
          <UnlimitedUpdatesInfoBox
            title={unlimitedUpdatesInfoBox.title}
            paragraph={unlimitedUpdatesInfoBox.paragraph}
            isDesktop={isDesktop}
            fullWidth
          />
        </Box>
        <Box mt={isDesktop ? 4.5 : 2} display="flex" justifyContent="center">
          <Grid>
            <BaseAccordion translations={plansGuidanceQuestionsTranslations} />
          </Grid>
        </Box>
      </Box>
    </PageWrapper>
  );
};

export default connect(essentialsPlanGuidanceProps, {
  fetchPlans: fetchPlansAction,
  goBack: goBackRouter,
})(EssentialsPlanGuidancePage);
