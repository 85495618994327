import { createSelector, createStructuredSelector } from "reselect";

import { selectTranslations } from "./translations";
import { selectHasLoadedDataById } from "./requests";
import { selectNextPathAfterPlansGuidancePages } from "./navigation";
import {
  selectEssentialsPrice,
  selectEssentialsStrikeThroughPrice,
  selectShowExclusiveOffer,
} from "./plans";
import { RECOMMENDATION_FORM_ID } from "../constants/forms";
import { selectIsFrenchSelected } from "./language";

const willPlanGuidancePageTranslations = selectTranslations([
  "global",
  "plansCards",
  "willPlanGuidancePage",
]);

export const selectWillPlanGuidancePageTranslations = createSelector(
  willPlanGuidancePageTranslations,
  (baseTranslations) => {
    return {
      ...baseTranslations,
    };
  },
);

export const willPlanGuidancePageProps = createStructuredSelector({
  translations: selectWillPlanGuidancePageTranslations,
  hasLoaded: selectHasLoadedDataById(RECOMMENDATION_FORM_ID),
  price: selectEssentialsPrice,
  strikeThroughPrice: selectEssentialsStrikeThroughPrice,
  nextSectionPath: selectNextPathAfterPlansGuidancePages,
  isFrenchSelected: selectIsFrenchSelected,
  showExclusiveOffer: selectShowExclusiveOffer,
});
