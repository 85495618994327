import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { history } from "../../config/configureStore";

import { promotionalBannerCheckoutAction } from "../../actions/banners";
import {
  analyticsElementClickedAction,
  analyticsElementHoveredAction,
} from "../../actions/analytics";
import {
  selectShowPromotionalBanner,
  selectPromotionalBannerTranslations,
} from "../../selectors/banners";

import Banner from "../ui/Banner";

export default function PromotionalBanner() {
  const dispatch = useDispatch();

  const shouldDisplayPromotionalBanner = useSelector(
    selectShowPromotionalBanner,
  );
  const promoBannerTranslations = useSelector(
    selectPromotionalBannerTranslations,
  );

  const onButtonClick = () => {
    dispatch(
      analyticsElementClickedAction({
        type: "Promotional Banner Checkout Clicked",
      }),
    );
    dispatch(promotionalBannerCheckoutAction("GETITDONE25"));
    history.push("/payment");
  };

  const onBannerMouseOver = () => {
    dispatch(
      analyticsElementHoveredAction({
        type: "Promotional Banner Hovered",
      }),
    );
  };

  if (!shouldDisplayPromotionalBanner) return null;

  return (
    <Banner
      translations={promoBannerTranslations}
      onButtonClick={onButtonClick}
      onMouseOver={onBannerMouseOver}
    />
  );
}
