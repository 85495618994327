import R from "ramda";
import { createStructuredSelector, createSelector } from "reselect";

import { selectTranslations } from "./translations";
import {
  LOGIN_PATH,
  PLANS_PATH,
  EMAIL_US_CHANGE_TO_ESSENTIALS_ONLY_PROVINCE_EN,
  EMAIL_US_CHANGE_TO_ESSENTIALS_ONLY_PROVINCE_FR,
  EMAIL_US_CHANGE_TO_NEW_PROVINCE_FR,
  EMAIL_US_CHANGE_TO_NEW_PROVINCE_EN,
  YOUR_ACCOUNT_PATH,
} from "../constants/routes";
import { selectRequestsLoading } from "./requests";
import {
  DELETE_ACCOUNT_FORM_ID,
  DELETE_GOOGLE_SSO_ACCOUNT_FORM_ID,
  FINANCIAL_ASSETS_FORM_ID,
  INSURANCE_ASSETS_FORM_ID,
  INVITE_USER_FORM_ID,
  PHYSICAL_ASSETS_FORM_ID,
} from "../constants/forms";
import {
  MODAL_DELETE_ACCOUNT,
  MODAL_INVITE_USER,
  MODAL_ADD_PHYSICAL_ASSET,
  MODAL_ADD_FINANCIAL_ASSET,
  MODAL_ADD_INSURANCE_ASSET,
  MODAL_ADD_BENEFICIARY_CHARITY,
  MODAL_EDIT_BENEFICIARY_CHARITY,
  MODAL_ADD_BENEFICIARY_PERSON,
  MODAL_REMOVE_BENEFICIARY,
  MODAL_EDIT_EXECUTORS,
  MODAL_ADD_EXECUTORS,
  MODAL_KLARNA_PROCESSING_PAYMENT,
  MODAl_KLARNA_PAYMENT_FAILED,
  MODAL_DELETE_ACCOUNT_GOOGLE_SSO,
  MODAL_CHANGE_SSO_PASSWORD,
  MODAL_REFER_A_FRIEND,
  MODAL_DIGITAL_EXECUTION,
  MODAL_SIGN_AND_WITNESS,
} from "../constants/modal";
import { selectLanguageCode } from "./language";
import {
  selectFinancialAssetProps,
  SelectInsuranceAssetProps,
  selectIsEditingAsset,
  selectPhysicalAssetProps,
} from "./asset-list";
import {
  selectAddBeneficiaryFormProps,
  selectRemoveBeneficiaryProps,
} from "./allocations";
import { selectExecutorsModalExtraProps } from "./executors";

export const selectIsModalVisible = R.path(["modal", "visible"]);
export const selectModalKey = R.path(["modal", "key"]);
export const selectIsModalPrompt = R.path(["modal", "prompt"]);
const selectGiftContactModalState = (state) => state.modal;

const selectAssetTranslations = createSelector(
  selectTranslations(["global", "notice"]),
  selectModalKey,
  selectIsEditingAsset,
  (translations, key, isEditing) => {
    switch (key) {
      case MODAL_ADD_PHYSICAL_ASSET:
      case MODAL_ADD_FINANCIAL_ASSET:
      case MODAL_ADD_INSURANCE_ASSET: {
        const title = isEditing
          ? translations[key].editTitle
          : translations[key].title;
        return {
          ...translations[key],
          title,
        };
      }
      default:
        return {};
    }
  },
);

const selectSpecificModalTranslations = createSelector(
  selectAssetTranslations,
  (assetTranslations) => {
    return {
      ...assetTranslations,
    };
  },
);

const selectModalTranslations = createSelector(
  selectTranslations(["global", "notice"]),
  selectModalKey,
  selectSpecificModalTranslations,
  (translations, key, specificTranslations) => {
    const modalTranslations = R.propOr({}, key, translations);

    return {
      ...translations,
      ...translations.default,
      ...modalTranslations,
      ...specificTranslations,
    };
  },
);

const selectModalRedirectPath = createSelector(selectModalKey, (key) => {
  switch (key) {
    case "auth.userNotAuthenticated":
      return LOGIN_PATH;
    case "plans.changedPlan":
      return PLANS_PATH;
    case "user.unpaidProductNotAvailableInProvince":
    case "user.featureCoExecutorsNotAvailableInProvince":
      return PLANS_PATH;
    case "auth.userMissingFeature":
      return YOUR_ACCOUNT_PATH;
    default:
      return null;
  }
});

const selectModalSpecificProps = createSelector(
  selectModalKey,
  selectPhysicalAssetProps,
  selectFinancialAssetProps,
  SelectInsuranceAssetProps,
  selectAddBeneficiaryFormProps,
  selectRemoveBeneficiaryProps,
  selectExecutorsModalExtraProps,
  (
    key,
    physicalAssetProps,
    financialAssetProps,
    insuranceAssetProps,
    addBeneficiaryFormProp,
    removeBeneficiaryProps,
    executorsModalExtraProps,
  ) => {
    switch (key) {
      case MODAL_ADD_PHYSICAL_ASSET:
        return physicalAssetProps;
      case MODAL_ADD_FINANCIAL_ASSET:
        return financialAssetProps;
      case MODAL_ADD_INSURANCE_ASSET:
        return insuranceAssetProps;
      case MODAL_ADD_BENEFICIARY_CHARITY:
      case MODAL_EDIT_BENEFICIARY_CHARITY:
        return addBeneficiaryFormProp;
      case MODAL_REMOVE_BENEFICIARY:
        return removeBeneficiaryProps;
      case MODAL_ADD_EXECUTORS:
      case MODAL_EDIT_EXECUTORS:
        return executorsModalExtraProps;
      default:
        return null;
    }
  },
);

const selectModalExternalLink = createSelector(
  selectModalKey,
  selectLanguageCode,
  (key, languageCode) => {
    if (languageCode === "fr") {
      switch (key) {
        case "province.paidSwitchingToNB":
          return EMAIL_US_CHANGE_TO_ESSENTIALS_ONLY_PROVINCE_FR;
        case "user.paidProductNotAvailableInProvince":
          return EMAIL_US_CHANGE_TO_NEW_PROVINCE_FR;
        default:
          return null;
      }
    } else {
      switch (key) {
        case "province.paidSwitchingToNB":
          return EMAIL_US_CHANGE_TO_ESSENTIALS_ONLY_PROVINCE_EN;
        case "user.paidProductNotAvailableInProvince":
          return EMAIL_US_CHANGE_TO_NEW_PROVINCE_EN;
        default:
          return null;
      }
    }
  },
);

// For modals that house forms that interact with requests
// need way of mapping modal keys to form ids
const modalFormMap = {
  [MODAL_DELETE_ACCOUNT]: DELETE_ACCOUNT_FORM_ID,
  [MODAL_DELETE_ACCOUNT_GOOGLE_SSO]: DELETE_GOOGLE_SSO_ACCOUNT_FORM_ID,
  [MODAL_INVITE_USER]: INVITE_USER_FORM_ID,
  [MODAL_ADD_PHYSICAL_ASSET]: PHYSICAL_ASSETS_FORM_ID,
  [MODAL_ADD_FINANCIAL_ASSET]: FINANCIAL_ASSETS_FORM_ID,
  [MODAL_ADD_INSURANCE_ASSET]: INSURANCE_ASSETS_FORM_ID,
};

const selectIsModalLoading = createSelector(
  selectModalKey,
  selectRequestsLoading,
  (modalKey, requestsLoading) => {
    const formId = R.propOr(null, modalKey)(modalFormMap);
    return R.propOr(false, formId)(requestsLoading);
  },
);

const modalLightBoxCloseMap = [
  MODAL_ADD_BENEFICIARY_PERSON,
  MODAL_ADD_BENEFICIARY_CHARITY,
];
const selectShouldCloseOnLightBoxClick = createSelector(
  selectModalKey,
  (modalKey) => {
    return R.includes(modalKey, modalLightBoxCloseMap);
  },
);

const modalHideSubmitButtonMap = [
  MODAl_KLARNA_PAYMENT_FAILED,
  MODAL_KLARNA_PROCESSING_PAYMENT,
  MODAL_DELETE_ACCOUNT_GOOGLE_SSO,
  MODAL_CHANGE_SSO_PASSWORD,
  MODAL_REFER_A_FRIEND,
  MODAL_DIGITAL_EXECUTION,
  MODAL_SIGN_AND_WITNESS,
];
const selectShouldHideSubmitButton = createSelector(
  selectModalKey,
  (modalKey) => {
    return R.includes(modalKey, modalHideSubmitButtonMap);
  },
);

const modalHideCloseButtonMap = [MODAL_KLARNA_PROCESSING_PAYMENT];
const selectShouldHideCloseButton = createSelector(
  selectModalKey,
  (modalKey) => {
    return R.includes(modalKey, modalHideCloseButtonMap);
  },
);

export const selectGiftContactModalClosed = createSelector(
  selectGiftContactModalState,
  (state) => {
    return state.giftContactModalClosed;
  },
);

export const modalProps = createStructuredSelector({
  isVisible: selectIsModalVisible,
  shouldCloseOnLightboxClick: selectShouldCloseOnLightBoxClick,
  shouldHideSubmitButton: selectShouldHideSubmitButton,
  shouldHideCloseButton: selectShouldHideCloseButton,
  translations: selectModalTranslations,
  redirectPath: selectModalRedirectPath,
  externalLink: selectModalExternalLink,
  modalKey: selectModalKey,
  isPrompt: selectIsModalPrompt,
  isLoading: selectIsModalLoading,
  extraProps: selectModalSpecificProps,
});
