export const formatCurrency = (
  amount: number,
  lang: string = "en",
  decimalPlaces: number | null = null,
): string => {
  const locale = lang === "fr" ? "fr-CA" : "en-US";
  const decimalPlacesHardCoded = decimalPlaces !== null;

  const amountHasDecimalPlace = amount - Math.floor(amount) !== 0;
  const decimalPlacesDynamicallyChosen = amountHasDecimalPlace ? 2 : 0;
  const minimumFractionDigits = decimalPlacesHardCoded
    ? decimalPlaces
    : decimalPlacesDynamicallyChosen;

  const price = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "CAD",
    minimumFractionDigits,
  }).format(amount);
  // remove "CA" from formatted price
  // currencyDisplay: "narrowSymbol" not support on safari
  // https://stackoverflow.com/questions/64144596/currencydisplay-must-be-either-code-symbol-or-name-safari-issue
  return price.replace("CA", "");
};

export const formatPercentage = (
  amount: number,
  decimalPlaces: number = 0,
  lang: string = "en",
): string => {
  const locale = lang === "fr" ? "fr-CA" : "en-US";
  const percentage = new Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: decimalPlaces,
  }).format(amount);

  return percentage;
};

export const isHotjarReady = (): boolean => {
  if (typeof window.hj === "undefined") {
    console.warn("Hotjar is not ready, window.hj is undefined");
    return false;
  }
  return true;
};

export const isTikTokReady = (): boolean => {
  if (typeof window.ttq === "undefined") {
    return false;
  }
  return true;
};

export const replaceBundleSizeOptions = (
  translations: {
    bundleSizeOptions: BundleSizeOption[];
  },
  bundlePrices: BundlePrice[],
  selectLanguageCode: string,
  showExclusiveOffer: boolean,
) => {
  if (bundlePrices === undefined) {
    return [];
  }
  return translations.bundleSizeOptions.map(
    (bundle: BundleSizeOption, index: number) => {
      const label = bundle.label.replace(
        "DISCOUNTED_PRICE",
        formatCurrency(
          showExclusiveOffer
            ? bundlePrices[index].discounted_price
            : bundlePrices[index].price,
          selectLanguageCode,
        ),
      );
      const labelStrikeThrough = bundle.labelStrikeThrough.replace(
        "MARKETING_PRICE",
        formatCurrency(bundlePrices[index].marketing_price, selectLanguageCode),
      );
      const srOnlyCopy = bundle.srOnlyCopy.replace(
        "MARKETING_PRICE",
        formatCurrency(bundlePrices[index].marketing_price, selectLanguageCode),
      );

      return {
        ...bundle,
        label,
        labelStrikeThrough,
        srOnlyCopy,
      };
    },
  );
};

export const getTranspiledMessage = (
  target: string,
  replacement: string,
  originalText: string | string[],
): string | string[] => {
  if (Array.isArray(originalText)) {
    return originalText.map((text) => text.replace(target, replacement));
  }
  return originalText.replace(target, replacement);
};

export const transformKebabSnakeCaseToCamelCase = (target: string): string => {
  return target
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", ""),
    );
};

export const deepTransformKeysToCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => deepTransformKeysToCamelCase(item));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      const camelCaseKey = transformKebabSnakeCaseToCamelCase(key);
      result[camelCaseKey] = deepTransformKeysToCamelCase(obj[key]);
      return result;
    }, {} as any);
  }
  return obj;
};

export const encodedExecutionURI = (
  flag: boolean,
  email: string,
  userId: number,
  province: string,
  languageCode: string,
) => {
  const undefinedValue = "xxxxx"; // this is the fallback value for the parameter
  const typeformEnglishToken = "vXk94HiY";
  const typeformFrenchToken = "aATBPDDq";
  const token =
    languageCode === "fr" ? typeformFrenchToken : typeformEnglishToken;
  const typeformUrl = `https://willfulwills.typeform.com/to/${token}?typeform-medium=app`;
  const answersUrlCode =
    "answers-f0c333c3-6eb8-4e0b-aa54-034584ba80f3=f0c333c3-6eb8-4e0b-aa54-034584ba80f3";

  const params = new URLSearchParams({
    email: email ? (email as string) : undefinedValue,
    user_id: userId ? userId.toString() : undefinedValue,
    province: province ? province : undefinedValue,
  });
  const answersQueryParam = `${answersUrlCode}-${flag ? "yes" : "no"}`;

  return `${typeformUrl}#${params.toString()}&${answersQueryParam}`;
};
