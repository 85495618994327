import React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import styled from "styled-components";
import Hidden from "@material-ui/core/Hidden";
import Box from "@material-ui/core/Box";

import { PageWrapper } from "../../containers/Layout";
import { animationSpeed, colorPalette, media } from "../../config/Theme";
import WillfulLogo from "../ui/icons/WillfulLogo";
import DesktopNav from "./DesktopNav";
import MobileNav from "./MobileNav";
import { WEBSITE_URL } from "../../constants/routes";
import { H4 } from "../ui/Typography";
import SunlifeLogo from "../ui/icons/SunlifeLogo";
import CIBCLogo from "../ui/icons/CIBCLogo";
import ArborLogo from "../ui/icons/ArborLogo";
import AllstateLogo from "../ui/icons/AllstateLogo";
import KraftHeinzLogo from "../ui/icons/KraftHeinzLogo";
import ScotiabankLogo from "../ui/icons/ScotiabankLogo";
import NextdoorLogo from "../ui/icons/NextdoorLogo";
import ParentLifeNetworkLogo from "../ui/icons/ParentLifeNetworkLogo";
import InnovationCreditUnionLogo from "../ui/icons/InnovationCreditUnionLogo";
import AmazonBenefitsLogo from "../ui/icons/AmazonBenefitsLogo";
import EmbarkLogo from "../ui/icons/EmbarkLogo";
import LoccitaneLogo from "../ui/icons/LoccitaneLogo";
import AdmiralInsuranceLogo from "../ui/icons/AdmiralInsuranceLogo";
import VentionIncLogo from "../ui/icons/VentionIncLogo";
import MercerVelanLogo from "../ui/icons/MercerVelanLogo";

const SkipToContentContainer = styled.div`
  background-color: ${colorPalette.willfulGrey};
  display: block;
  height: 0;
  min-height: 0;
  overflow: hidden;
  transition-property: height;
  transition-duration: ${animationSpeed.fast}ms;
  transition-timing-function: ease-out;

  &:focus-within {
    border: 2px solid ${colorPalette.willfulBlue};
    transition-property: height;
    transition-duration: ${animationSpeed.fast}ms;
    transition-timing-function: ease-in;
    ${media.mobile} {
      height: 56px;
    }
    ${media.tablet} {
      height: 56px;
    }
    ${media.desktop} {
      height: 62px;
    }
  }
`;

const SkipToContent = styled.a`
  color: ${colorPalette.white};
  display: block;
  padding: 16px;
  position: relative;
  text-align: center;
  top: 0;
  width: 100%;
  &:hover {
    color: ${colorPalette.willfulxxxLightBlue};
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${colorPalette.willfulBlue};

  ${media.mobile} {
    height: 65px;
  }

  ${media.tablet} {
    height: 65px;
  }

  ${media.desktop} {
    height: 102px;
  }
`;

const WillfulLogoContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding-top: 0;

  ${media.mobile} {
    width: 90px;
  }

  ${media.tablet} {
    width: 90px;
  }

  ${media.desktop} {
    margin-right: 24px;
    width: 150px;
  }
`;

const PartnerLogoContainer = styled.div`
  padding-left: 12px;
  border-left: 1px solid ${colorPalette.willfulGrey2};
  margin-left: 12px;
  height: 26px;
  ${media.desktop} {
    align-items: center;
    display: flex;
    height: 37px;
    padding-left: 24px;
    margin-left: 0;
  }

  svg {
    max-height: 100%;
  }
`;

const logoStyles = `
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  &:focus-visible {
    outline: ${colorPalette.white} solid 1px;
  }
`;

const StyledLogoLink = styled(Link)`
  ${logoStyles}
`;

const StyledLogoExternalLink = styled.a`
  ${logoStyles}
`;

const LogoLink = ({ children, isAuthenticated, logoLink }) => {
  if (isAuthenticated) {
    return (
      <StyledLogoLink to={logoLink} aria-label="Willful logo" role="link">
        {children}
      </StyledLogoLink>
    );
  }
  return (
    <StyledLogoExternalLink
      href={WEBSITE_URL}
      aria-label="Willful logo"
      role="link"
    >
      {children}
    </StyledLogoExternalLink>
  );
};

const Header = ({
  isAuthenticated,
  hasSelectedPlan,
  isOnDocuments,
  isMobileNavOpen,
  fetchCart,
  cartProps,
  productSwitcherMenuProps,
  yourAccountMenuProps,
  setIsMobileNavOpen,
  logout,
  isFrenchAvailable,
  translations,
  logoLink,
  isFrenchSelected,
  isSunlifeReferral,
  isKraftHeinzReferral,
  isCIBCEmpReferral,
  isCIBCClientsReferral,
  isArborReferral,
  isAllstateEmpReferral,
  isAllstateClientsReferral,
  isArborMemorialReferral,
  isScotiaBankReferral,
  isNextdoorReferral,
  isParentLifeNetworkReferral,
  isInnovationCreditUnionReferral,
  isInnovationCreditUnionClientsReferral,
  isAmazonBenefitsReferral,
  isEmbarkReferral,
  analyticsOpenedCartWidget,
  isMercerLoccitaneCanadaReferral,
  isMercerAdmiralInsuranceCanadaReferral,
  isVentionIncReferral,
  isMercerVelanReferral,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <>
      <SkipToContentContainer>
        <H4>
          <SkipToContent href="#content">
            {translations.skipToContent}
          </SkipToContent>
        </H4>
      </SkipToContentContainer>
      <HeaderContainer>
        <PageWrapper display="flex" alignItems="center">
          <Box display="flex" width="100%" alignItems="center">
            <WillfulLogoContainer>
              <LogoLink
                isAuthenticated={isAuthenticated}
                className="qa-dashboard"
                logoLink={logoLink}
              >
                <Box component="span" width="100%">
                  <WillfulLogo />
                </Box>
              </LogoLink>
            </WillfulLogoContainer>
            {isSunlifeReferral && (
              <PartnerLogoContainer>
                <SunlifeLogo isFrench={isFrenchSelected} />
              </PartnerLogoContainer>
            )}
            {(isCIBCEmpReferral || isCIBCClientsReferral) && (
              <PartnerLogoContainer>
                <CIBCLogo />
              </PartnerLogoContainer>
            )}
            {isKraftHeinzReferral && (
              <PartnerLogoContainer>
                <KraftHeinzLogo />
              </PartnerLogoContainer>
            )}
            {isScotiaBankReferral && (
              <PartnerLogoContainer>
                <ScotiabankLogo height={isDesktop ? 30 : 22} />
              </PartnerLogoContainer>
            )}
            {isArborReferral ||
              (isArborMemorialReferral && (
                <PartnerLogoContainer>
                  <ArborLogo />
                </PartnerLogoContainer>
              ))}
            {(isAllstateEmpReferral || isAllstateClientsReferral) && (
              <PartnerLogoContainer>
                <AllstateLogo isFrench={isFrenchSelected} />
              </PartnerLogoContainer>
            )}
            {isNextdoorReferral && (
              <PartnerLogoContainer>
                <NextdoorLogo />
              </PartnerLogoContainer>
            )}
            {isParentLifeNetworkReferral && (
              <PartnerLogoContainer>
                <ParentLifeNetworkLogo />
              </PartnerLogoContainer>
            )}
            {(isInnovationCreditUnionReferral ||
              isInnovationCreditUnionClientsReferral) && (
              <PartnerLogoContainer>
                <InnovationCreditUnionLogo />
              </PartnerLogoContainer>
            )}
            {isAmazonBenefitsReferral && (
              <PartnerLogoContainer>
                <AmazonBenefitsLogo />
              </PartnerLogoContainer>
            )}
            {isEmbarkReferral && (
              <PartnerLogoContainer>
                <EmbarkLogo isDesktop={isDesktop} />
              </PartnerLogoContainer>
            )}
            {isMercerLoccitaneCanadaReferral && (
              <PartnerLogoContainer>
                <LoccitaneLogo isDesktop={isDesktop} />
              </PartnerLogoContainer>
            )}
            {isMercerAdmiralInsuranceCanadaReferral && (
              <PartnerLogoContainer>
                <AdmiralInsuranceLogo isDesktop={isDesktop} />
              </PartnerLogoContainer>
            )}
            {isVentionIncReferral && (
              <PartnerLogoContainer>
                <VentionIncLogo />
              </PartnerLogoContainer>
            )}
            {isMercerVelanReferral && (
              <PartnerLogoContainer>
                <MercerVelanLogo />
              </PartnerLogoContainer>
            )}
            {/* Mobile Nav */}
            <Hidden mdUp>
              <MobileNav
                isMobileNavOpen={isMobileNavOpen}
                setIsMobileNavOpen={setIsMobileNavOpen}
                cartProps={cartProps}
                isAuthenticated={isAuthenticated}
                translations={translations}
                analyticsOpenedCartWidget={analyticsOpenedCartWidget}
              />
            </Hidden>
            {/* Tablet/Desktop Nav */}
            <Hidden smDown>
              <DesktopNav
                translations={translations}
                productSwitcherMenuProps={productSwitcherMenuProps}
                fetchCart={fetchCart}
                hasSelectedPlan={hasSelectedPlan}
                cartProps={cartProps}
                onLogout={logout}
                isOnDocuments={isOnDocuments}
                isFrenchAvailable={isFrenchAvailable}
                isAuthenticated={isAuthenticated}
                yourAccountMenuProps={yourAccountMenuProps}
                analyticsOpenedCartWidget={analyticsOpenedCartWidget}
              />
            </Hidden>
          </Box>
        </PageWrapper>
      </HeaderContainer>
    </>
  );
};

export default Header;
